@font-face {
    font-family: 'Metropolis-ExtraLight';
    src: url('../fonts/Metropolis-ExtraLight.eot');
    src: url('../fonts/Metropolis-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Metropolis-ExtraLight.woff2') format('woff2'),
        url('../fonts/Metropolis-ExtraLight.woff') format('woff'),
        url('../fonts/Metropolis-ExtraLight.ttf') format('truetype'),
        url('../fonts/Metropolis-ExtraLight.svg#Metropolis-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis-Black';
    src: url('../fonts/Metropolis-Black.eot');
    src: url('../fonts/Metropolis-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Metropolis-Black.woff2') format('woff2'),
        url('../fonts/Metropolis-Black.woff') format('woff'),
        url('../fonts/Metropolis-Black.ttf') format('truetype'),
        url('../fonts/Metropolis-Black.svg#Metropolis-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis-Bold';
    src: url('../fonts/Metropolis-Bold.eot');
    src: url('../fonts/Metropolis-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Metropolis-Bold.woff2') format('woff2'),
        url('../fonts/Metropolis-Bold.woff') format('woff'),
        url('../fonts/Metropolis-Bold.ttf') format('truetype'),
        url('../fonts/Metropolis-Bold.svg#Metropolis-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis-SemiBold';
    src: url('../fonts/Metropolis-SemiBold.eot');
    src: url('../fonts/Metropolis-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Metropolis-SemiBold.woff2') format('woff2'),
        url('../fonts/Metropolis-SemiBold.woff') format('woff'),
        url('../fonts/Metropolis-SemiBold.ttf') format('truetype'),
        url('../fonts/Metropolis-SemiBold.svg#Metropolis-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis-Medium';
    src: url('../fonts/Metropolis-Medium.eot');
    src: url('../fonts/Metropolis-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Metropolis-Medium.woff2') format('woff2'),
        url('../fonts/Metropolis-Medium.woff') format('woff'),
        url('../fonts/Metropolis-Medium.ttf') format('truetype'),
        url('../fonts/Metropolis-Medium.svg#Metropolis-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis-Regular';
    src: url('../fonts/Metropolis-Regular.eot');
    src: url('../fonts/Metropolis-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Metropolis-Regular.woff2') format('woff2'),
        url('../fonts/Metropolis-Regular.woff') format('woff'),
        url('../fonts/Metropolis-Regular.ttf') format('truetype'),
        url('../fonts/Metropolis-Regular.svg#Metropolis-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis-ExtraBold';
    src: url('../fonts/Metropolis-ExtraBold.eot');
    src: url('../fonts/Metropolis-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Metropolis-ExtraBold.woff2') format('woff2'),
        url('../fonts/Metropolis-ExtraBold.woff') format('woff'),
        url('../fonts/Metropolis-ExtraBold.ttf') format('truetype'),
        url('../fonts/Metropolis-ExtraBold.svg#Metropolis-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Gotham-Bold';
    src: url('../fonts/Gotham-Bold.eot');
    src: url('../fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gotham-Bold.woff2') format('woff2'),
        url('../fonts/Gotham-Bold.woff') format('woff'),
        url('../fonts/Gotham-Bold.ttf') format('truetype'),
        url('../fonts/Gotham-Bold.svg#Gotham-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GothamMedium';
    src: url('../fonts/GothamMedium.eot');
    src: url('../fonts/GothamMedium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GothamMedium.woff2') format('woff2'),
        url('../fonts/GothamMedium.woff') format('woff'),
        url('../fonts/GothamMedium.ttf') format('truetype'),
        url('../fonts/GothamMedium.svg#GothamMedium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'GothamBlack';
    src: url('../fonts/GothamBlack.eot');
    src: url('../fonts/GothamBlack.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GothamBlack.woff2') format('woff2'),
        url('../fonts/GothamBlack.woff') format('woff'),
        url('../fonts/GothamBlack.ttf') format('truetype'),
        url('../fonts/GothamBlack.svg#GothamBlack') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FivoSansModern-Heavy';
    src: url('../fonts/FivoSansModern-Heavy.eot');
    src: url('../fonts/FivoSansModern-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FivoSansModern-Heavy.woff2') format('woff2'),
        url('../fonts/FivoSansModern-Heavy.woff') format('woff'),
        url('../fonts/FivoSansModern-Heavy.ttf') format('truetype'),
        url('../fonts/FivoSansModern-Heavy.svg#FivoSansModern-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'FivoSansModern-Medium';
    src: url('../fonts/FivoSansModern-Medium.eot');
    src: url('../fonts/FivoSansModern-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FivoSansModern-Medium.woff2') format('woff2'),
        url('../fonts/FivoSansModern-Medium.woff') format('woff'),
        url('../fonts/FivoSansModern-Medium.ttf') format('truetype'),
        url('../fonts/FivoSansModern-Medium.svg#FivoSansModern-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FivoSansModern-Regular';
    src: url('../fonts/FivoSansModern-Regular.eot');
    src: url('../fonts/FivoSansModern-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FivoSansModern-Regular.woff2') format('woff2'),
        url('../fonts/FivoSansModern-Regular.woff') format('woff'),
        url('../fonts/FivoSansModern-Regular.ttf') format('truetype'),
        url('../fonts/FivoSansModern-Regular.svg#FivoSansModern-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FivoSansModern-Bold';
    src: url('../fonts/FivoSansModern-Bold.eot');
    src: url('../fonts/FivoSansModern-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FivoSansModern-Bold.woff2') format('woff2'),
        url('../fonts/FivoSansModern-Bold.woff') format('woff'),
        url('../fonts/FivoSansModern-Bold.ttf') format('truetype'),
        url('../fonts/FivoSansModern-Bold.svg#FivoSansModern-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}





