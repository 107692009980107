


@media screen and (min-width: 320px) and (max-width:418px) {
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
float: left !important;width: 100% !important;padding-right: 0 !important;padding-left: 0 !important;}
.main_menu {margin: 0;display: none;}
.mobile_menu{display: block;}
.banner {padding: 30px 0 0 0;}
.banner h2 {font-size: 24px;letter-spacing: -2px;}
.banner h2 span.star {position: absolute;top: -3px;width: 20px;margin: 0 0 0 -5px;}
.banner h2 img.growing {margin: 0 0 0 10px;width: 30px;}
.banner h1 {font-size: 24px;letter-spacing: -2px;margin: -5px 0 0 0;}
.banner h1 span img.box {width: 26px;}
.banner h1 img.menap {width: 28px;}
.publish {padding: 30px 0 30px 0;background-position: 0 83%;background-size: 100%;}
	
.banner .employees {margin: 30px 4% 0 0;width: 140px;min-height: 140px;height: auto;padding: 30px 20px 20px 20px;border-radius: 30px;}
.banner .employees h3{font-size: 36px;line-height: 46px;}
.banner .employees p {font-size: 17px;}
.banner .advertising_partners {margin: 30px 0 0 0;width: 140px;min-height: 140px;height: auto;padding: 30px 20px 20px 20px;border-radius: 30px;}
.banner .advertising_partners h3{font-size: 36px;line-height: 46px;}
.banner .advertising_partners p {font-size: 17px;}

.banner .games_published{margin: 30px 4% 0 0;width: 140px;min-height: 140px;height: auto;padding: 30px 20px 20px 20px;border-radius: 30px;}
.banner .games_published h3{font-size: 36px;line-height: 46px;}
.banner .games_published p {font-size: 17px;}

.banner .dau {margin: 30px 0 0 0;width: 140px;min-height: 140px;height: auto;padding: 30px 20px 20px 20px;border-radius: 30px;}
.banner .dau h3{font-size: 36px;line-height: 46px;}
.banner .dau p {font-size: 17px;}

.banner .downloads {margin: 0 auto;float: none;top: 44px;min-height: 540px;}
.banner .downloads .beating {top: -20px;right: 200px;left: auto;}
.our_games {padding: 50px 0 50px 0;}

.content_creators {padding: 0 0 0px 0;background-position: right 30px;background-size: 130px;}
.love_to_work {margin: -60px 2% 0 2%;padding: 20px 20px 70px 20px;width: 96%;}
.love_to_work p {font-size: 17px;width: 100%;}
.love_to_work h2 {font-size: 24px;letter-spacing: 0px;line-height: 28px;width: 100%;}
.work_services .icons img {width: 60px;}
.icon-content {left: 25%;top: 47px;width: 20px;}
	.work_with {padding: 0 1%;}
.work_services h3 {font-size: 24px;line-height: 24px;}
.work_services p {font-size: 16px;}
img.content_creators_icon {margin: -60px 0 30px 0;}
.work_services_top {margin: -20px 0 0 0;}
.work_services {margin: 0 2% 30px 2%;width: 96%;padding: 30px 20px;}
.work_services:hover {-webkit-transform: scale(1,1);transform: scale(1,1);-webkit-transition: all 0.3s ease-in-out;}
.content_growth_sand_box {padding: 0 5%;}
.fuel_growth {border: none;padding: 20px 0 0 0px;}
.fuel_growth ul.games_type li {padding: 6px 10px;font-size: 10px;}
.tools h3 {font-size: 24px;line-height: 30px;padding: 0;}
.tools img {padding: 0 0;}
.pixls_inner {padding: 200px 0 45px 34px;}
.gd_created h2 {font-size: 27px;padding: 0 5%;}
	.mission {border-radius: 20px;}
.mission_inner {padding: 20px 20px 20px 20px;}
	.mission_icom span {font-size: 17px;}
.mission .mission_icom img {width: 60px;}
.mission h2 {font-size: 31px;margin: 40px 0 0 0;}
.mission h2 span {font-size: 31px;}
.mission p {font-size: 17px;line-height: 24px;width: 100%;}
.mission_line_full {top: -200px;}

.vision {margin: 30px 0 0 0;}
.vision_inner {padding: 20px;}
.vision .vision_icom img{width: 60px;}
.pixls_inner {padding: 20px 0 20px 20px;}
.vision h2 {font-size: 31px;margin: 40px 0 0 0;}
.vision h2 span {font-size: 31px;}
.vision p{font-size: 17px;line-height: 24px;width: 100%;}
  .gd_created {padding: 40px 0;}
.ceo_pic {padding: 10px 0 0 0;background-size: 100%;width: 80%;margin: 0 10%;}
.message h2 {font-size: 42px;}
.message p {font-size: 17px;margin: 0 0 20px 0;width: 100%;}
.blog_area {margin: 40px 0 0 1%;padding: 40px 0 0 0;}
.blog_area h2 img.icon_left {width: 48px;}
.blog_area h2 span {font-size: 42px;line-height: 64px;}
.blog_area h2 img.icon_right {margin: 0 0 0 5px;
width: 60px;}
.blog_area p {padding: 0 5%;}
.blog_area ul.post_type li a {padding: 6px 8px;font-size: 10px;border-radius: 20px;}
.meet_the_studios {padding: 0px 0;}
.meet_the_studios h2 {font-size: 31px;line-height: 32px;}
.meet_the_studios h2 img {width: 60px;}
.meet_the_studios p {padding: 0 5%;}
.logo_studios .studios_logo {margin: 20px 0 20px 0;width: 50%;}
.logo_studios .studios_logo img {height: 90px;}
.game_changers h2 img {width: 60px;}
.game_changers h2 {font-size: 24px;line-height: 64px;}
.game_changers p {padding: 0 5%;font-size: 17px;}

.home_destop{float: left; margin: 0; padding: 0; width: 100%; display: block;}
.home_mobile{float: left; margin: 0; padding: 0; width: 100%; display: block;}	
.banner .downloads2 {width: 100%;}
.banner .downloads2 .beating {left: auto;
    right: auto;
    float: none;
    margin: 0 auto;
    background-position: center top;
    position: relative;}
.banner .downloads2 img.downloads_dots {left: 36%;}
.left_area .games_icon_row img {margin: 0 6px 6px 0;height: 35px;}
.right_area .games_icon_row_right img {margin: 0 0 6px 6px;height: 35px;}
.our_games .left_area {width: 50%;}
.our_games .right_area {margin: 0px 0 0 0;width: 50%;}
.content_growth_sand_box img {width: 100px;}
.content_growth_sand_box h3 {font-size: 31px;line-height: 31px;margin: 0 0 0 0;}
.fuel_growth p {font-size: 17px;line-height: 22px;margin: 0px 0 15px 0;padding: 0 5%;}
.fuel_growth ul.games_type {margin: 0 0 20px 5%;width: 90%;}
	
	
	
	
.our_games .center_area h1{font-size: 48px;line-height: 48px;}
.our_games .center_area h2{font-size: 48px;line-height: 18px;}
.our_games .center_area h3{font-size: 48px;line-height: 48px;}
.our_games .center_area h3 img{width: 48px;}
.our_games .icons a img{border: none;width: 38px; }
.our_games .center_area p{font-size: 16px;padding: 0 10%;line-height: 22px;}
.our_games .center_area ul.games_type li a {font-size: 12px;}	

.growth_sand_box {padding: 30px 0 30px 0;}	
.growthsandbox-icon img {width: 60px;margin: 0 15px 0 0;}	
.tools {margin: 0 2% 0 2%;width: 96%;border-radius: 30px;}	
.tools p {font-size: 17px;line-height: 24px;margin: 15px 0 30px 0;padding: 0 4%;}
.pixls {margin: 30px 2% 0 2%;width: 96%;border-radius: 30px;}
.gd_hub_content img{width: 180px;}
.pixls h3 {font-size: 31px;line-height: 31px;}
.gd_hub .map img.locations_img {margin: 0;}
.mission_line_full_top {margin: 0 2%;width: 96%;}
.message h2 img {width: 60px;margin: 0 0px 0 0;}
.message {margin: 0 5%;padding: 40px 0 0 0;width: 90%;}
.blog_area .home_blog_post {margin: 0 2%;width: 96%;}

.slider_img_content .item {margin: 0 2% 15px 2%;width: 96%;}
.slider_img_content .active {width: 96%;margin: 0 2%;}
	.slider_img_content .info {width: 100% !important;height: auto;position: relative;margin: 30px 0 0 0;}

	.slider_img_content .item img {max-width: 100%;margin: 0;}
	.slider_img_content .item img.pic_lead {margin: 0;}
	.slider_img_content .item:hover img.pic_lead_active {margin-left: 0;}
	.slider_img_content .info p {font-size: 17px;width: 100%;line-height: 22px;}
	.growthsandbox {padding: 0 1%;}
	
	.footer_area {padding: 30px 30px 30px 30px;}
	.footer_area .copy_right {margin: 30px 0 30px 0;}
	.footer_area .phone {margin: 50px 0 15px 0;}
	.contact_us {padding: 30px 3%;}
	.contact_us h1 {font-size: 42px;margin: 0 0 10px 0;}
img.boltmobile{display: block;float: right;width: 110px;position: relative;margin: -60px 0 0 0}
	.contact_us .sign_up {margin: 30px 0 0 0;}
	.bolt {display: none;}
		.destop_view{display: none !important;}
.mobile_view{display: block !important;}
	.publish_employees{float: none;margin: 0 auto;padding: 0;width: 100%;max-width: 294px;}
	.vision {border-radius: 20px;}
	.mission_vision_area {padding: 0px 0 20px 0;}
	.contact_us p {width: 60%;}
	.gd_hub {margin: 20px 1% 0 1%;width: 98%;}
	
	/* ABout Page */
	.banner_about h2 {font-size: 24px;letter-spacing: -1px;margin: 0 0 5px 0;}
	.banner_about h2 img.growing {margin: 0 0 0 5px;width: 30px;}
	.banner_about h2 span.star {top: -7px;margin-left: -7px;}
	.banner_about h2 span.star img {border: none;max-width: 36px;}
	.banner_about h1 {font-size: 24px;letter-spacing: -2px;line-height: 28px;}
	.banner_about h1 span.star {position: absolute;top: -13px;margin-left: 10px;}
	.banner_about h1 span.star img{width: 30px;}
	.banner_about h1 span.icon_about_title {position: absolute;top: 16px;margin-left: -10px;}
	.banner_about h1 span.icon_about_title img{width: 24px;}
	.about_banner img {max-width: 90%;}
	.banner_about {padding: 30px 0 0px 0;}
	.about_us {padding: 0 1%;}
	.about_us_col {padding: 20px 0 20px 0;}
	.about_us_col h2 img.about_us_icon_right {right: 5px;width: 60px;}
	.about_us_col h2 img.about_us_title_icon_left {position: relative;left: 3%;width: 60px;top: -9px;}
	.about_us_col h2 span {font-family: 'FivoSansModern-Heavy';font-size: 31px;}
	.about_us_col h2 img.about_us_title_icon_right {position: relative;left: -15px;top: -20px;width: 75px;}
	.about_us_col h2 {font-size: 31px;height: 140px;}
	.about_us_col p {font-size: 17px;margin: 0px 0 30px 0;padding: 0 5%;}
	.about_us_col h3 {font-size: 31px;margin: 0 0 30px 0;}
	.company_philosphy .box {margin: 10px 1%;padding: 20px;width: 100%;border-radius: 30px;min-height: auto;max-width: 98%;}
	.company_philosphy .box .icon {margin: 0 0 20px 0;}
.company_philosphy .box .icon img {max-width: 60px;}
.company_philosphy .box h4 {font-size: 24px;}
.company_philosphy .box p {font-size: 17px;}
	.empowering_area {padding: 30px 0 0 0;}
	.empowering {padding: 0 1%;}
	.empowering_area h2 {font-size: 24px;margin: 0 0 30px 0;}
	.mission_line_full2 {top: -190px;}
	.empowering_area p {font-size: 17px;margin: 0px 0 20px 0;padding: 0 5%;}
	.empowering_area .gap {height: 0;}
	.empowering_area h2.our_values {font-size: 42px;line-height: 42px;margin: 0 0 20px 0;padding: 30px 44% 0 5%;}
	.our_values_box {margin: 0 1% 25px 1%;padding: 20px 20px 15px 20px;width: 98%;border-radius: 30px;min-height: auto;}
	.our_values_box .icon_number img {width: 60px;}
	.our_values_box h3 {font-size: 24px;margin: 0 0 10px 0;}
	.our_values_box p {font-size: 17px;padding: 0 !important;}
	.our_values_box:hover {-webkit-transform: scale(1,1);transform: scale(1,1);-webkit-transition: all 0.3s ease-in-out;}
	.senior_leadership {margin: 30px 1% 0 1%;padding: 20px;border-radius: 20px 20px;}
	.senior_leadership h2 img {right: 0px;width: 60px;}
	.senior_leadership h2 {font-size: 31px;line-height: 36px;padding: 25px 0 0 0;}
	.senior_leadership p {font-size: 17px;margin: 15px 0 20px 0;width: 100%;line-height: 22px;padding: 0;}
	.col-19 {width: 50%; padding: 0 5px;}
	.col-20 {width: 100%; padding: 0;}
	.leadership_info_1st {margin: 0 0 0 0;}
	.leadership_info_1st .leadership_info_1st_back p {font-size: 20px !important;}
	.leadership_info_2nd {margin: 0;}
	.leadership_info_2nd_back p {font-size: 20px !important;}
	.our_journey h2 {font-size: 31px;line-height: 34px;margin: 0 0 0px 0;padding: 20px 0 20px 0;}
	.game_changers {padding: 30px 0;}
	
	
	/* Games Page */
	.banner_games h2 {font-size: 31px;letter-spacing: -3px;}
	.banner_games h2 img.games_icon_love {margin: 5px 0 0 5px;width: 32px;}
	.banner_games h3 {margin: -5px 0 0 0;font-size: 31px;letter-spacing: -3px;padding: 0 0 20px 0;}
	.banner_games h3 img.games_icon_title_left {margin: -13px 0 0 -30px;position: absolute; width: 50px;}
	.banner_games h3 img.games_icon_title_right {margin: 0px 0 0 -12px;width: 50px;}
	.slide {width: 60px;height: 68px;margin: 0 2px;}
	.logos_games {margin: 0 0 0 0;}
	.our_favorite_games {padding: 30px 0 30px 0;}
	.our_favorite_games img.icon_our_favorite_games {margin: 0 10px 0 0;width: 60px;}
	
	.our_favorite_games h1 {font-size: 42px;margin: -50px 0 0 5%;line-height: 42px;}
	.our_favorite_games h1 a img {width: 26px;}
	.our_favorite_games h1 img.favoritegame-title-icon {width: 33px;}
	.our_favorite_games ul.games_type li a {padding: 6px 15px;}
	.our_favorite_games .content_area {width: 90%;}
	.our_favorite_games .content_area p {font-size: 17px;margin: 0px 0 20px 0;}
	
	.games_info {max-height: 100%;margin: 120px 0 0 0;}
	.games_info .games_icon_left {margin: 0px 1%;padding: 0 0 0 0px;width: 98%;text-align: center;background-color: #BEF3FF;border-radius: 30px 30px 0 0;}
	.games_info .games_icon_left img {width: 120px;margin: -70px 0 0 0;}
	.games_info .about_games {margin: 0 1%;padding: 20px;width: 98%;border-radius: 0 0 30px 30px;}
	.about_games .game_title_icon .icon_game {width: 70px;margin: 0 10px 0 0;}
	.about_games .game_title_icon h5 {font-size: 18px;}
	.about_games .game_title_icon h3 {font-size: 24px;line-height: 24px;}
	.about_games ul.games_type1 li a {padding: 5px 11px;font-size: 11px;}
	.about_games p {font-size: 16px;width: 100%;}
	.about_games .store_links a {margin: 0 7px 0 0;padding: 10px 15px;font-size: 12px;}
	.about_games .store_links a img {margin: 3px 5px 0 0;width: 16px;}
	
	
	.games_info .games_icon_right {margin: 0px 1%;padding: 0 0 0 0px;width: 98%;text-align: center;background-color: #BEF3FF;border-radius: 30px 30px 0 0;position: absolute;top: 0;}
	.games_info .games_icon_right img {width: 120px;margin: -70px 0 0 0;}
	.games_info .about_games_right {margin: 240px 1% 0 1%;padding: 20px;width: 98%;border-radius: 0 0 30px 30px;}
	.about_games_right .game_title_icon .icon_game {width: 70px;margin: 0 10px 0 0;}
	.about_games_right .game_title_icon h5 {font-size: 18px;}
	.about_games_right .game_title_icon h3 {font-size: 24px;line-height: 24px;}
	.about_games_right ul.games_type1 li a {padding: 5px 11px;font-size: 11px;}
	.about_games_right p {font-size: 16px;width: 100%;}
	.about_games_right .store_links a {margin: 0 7px 0 0;padding: 10px 15px;font-size: 12px;}
	.about_games_right .store_links a img {margin: 3px 5px 0 0;width: 16px;}
	
	.our_awesome_studios {padding: 30px 0 30px 0;}
	.our_awesome_studios img.icon_our_awesome_studios {margin: 0 10px 0 0;width: 60px;}
	.our_awesome_studios h1 {font-size: 42px;margin: -25px 0 0 5%;line-height: 42px;}
	.our_awesome_studios h1 img.awesome_studios_title-icon {margin: -18px 0 0 5px;width: 60px;}
	.our_awesome_studios .content_area {width: 90%;}
	.our_awesome_studios .content_area p {font-size: 17px; margin: 0px 0 20px 0;}
	
	.our_awesome_studios img.studios_icon_center {width: 100px;height: 100px;left: 32%;top: 96px;}
	.sections .our_studios_logo {margin: 10px;padding: 20px 0 0 0;width: 130px;height: 130px;}
	.sections img {max-width: 80%;}
	.flex-center {height: 150px;}
	ul.accordion-list {margin: 30px 0 0 0;}
	ul.accordion-list li {padding:40px 15px 40px 25px;border-radius: 30px 30px 0 0;}
	ul.accordion-list li.active h3:after {left: -20px;}
	ul.accordion-list li h3:after {left: -20px;}
	.answer .studios_info p {font-size: 17px;}
	.answer .studios_info .store_links a {margin: 0 18px 10px 18px;}
	.games_icons_row img {margin: 0 0 8px 8px;width: 52px;}
	
	/* gdhub Page */
	
	.banner_gd_hub h2 {font-size: 31px;letter-spacing: -2px;}
	.banner_gd_hub h2 img.icon_gd_hub_left {margin: 0 0 0 0;width: 32px;}
	.banner_gd_hub h1 {font-size: 31px;letter-spacing: -2px;line-height: 36px;}
	.banner_gd_hub h2 span.star {top: -10px;margin-left: 0;}
	.banner_gd_hub h2 span.star img{ width: 36px;}
	.banner_gd_hub h1 span.icon_about_title {top: 20px;margin-left: -21px;}
	.banner_gd_hub h1 span.icon_about_title img {width: 30px;}
	.gd_hub_empowering_studios {padding: 0 2%;}
	.gd_hub_empowering_studios_area {padding: 30px 0 30px 0;}
	.gd_hub_empowering_studios_info {margin: 0 0 0 0; text-align: center;}
	
	.gd_hub_empowering_studios_info img{border: none;max-width: 100%;width: 250px;}
	.gd_hub_empowering_studios_info h3 {font-size: 42px;margin: 30px 0 0px 10%;letter-spacing: -3px;padding: 0 90px 0 0;}
	.gd_hub_empowering_studios_details {margin: 20px 0 0 0;padding: 0 5%;}
	.gd_hub_info_area {margin: 20px 1% 20px 1%;padding: 30px 0 30px 0;}
	.gd_hub_info_area h2 {font-size: 42px;line-height: 42px;float: left;margin: 0 0 20px 0;letter-spacing: -1px;padding: 0 0;}
	.gd_hub_info_area p {font-size: 17px;padding: 0 5%;}
	.gd_hub_info_area p.inner {font-size: 17px;padding: 0 5%;}
	.gd_hub_values_box:hover {-webkit-transform: scale(1.0,1.0);transform: scale(1.0,1.0);-webkit-transition: all 0.3s ease-in-out;}
	.gd_hub_values_box {margin: 0 2% 25px 2%;padding: 20px 20px 20px 20px;width: 96%;border-radius: 30px;min-height: auto;}
	.gd_hub_values_box .icon_number img {width: 60px;}
	.gd_hub_values_box h3 {font-size: 24px;margin: 0 0 15px 0;padding: 0 50px 0 0;}
	.gd_hub_info_area .gd_hub_values_box p {font-size: 17px;padding: 0 0;}
	.gd_hub_values_box_area h2.our_values {font-size: 31px;line-height: 34px;letter-spacing: 0px;padding: 10px 5% 0 5%;}
	.games_are_played h2 {font-size: 36px;line-height: 40px;margin: 0 0 20px 0;letter-spacing: 0px;padding: 0 12% 0 5%;}
	.games_are_played {padding: 40px 0 40px 0;}
	.games_are_played h2 img.games_are_played_icon {right: 18%;top: 30px;width: 48px;}
	.games_are_played p {font-size: 17px;padding: 0 5%;}
	.games_are_played_info {padding: 30px 0 0 0;}
	
	
	/* IP Page */
	.ip_banner {background-position: left top;background-size: 100%;}
	.banner_ip h2 {font-size: 36px;}
	.banner_ip h2 img.star_ip {margin: -6px 0 0 -3px;width: 24px;}
	.banner_ip h3 {margin: 10px 0 30px 0;font-size: 31px;}
	.banner_ip {background-position: right 26px;background-size: 110px;padding: 50px 0 50px 0;}
	.banner_ip_top {background-position: 0px 30px;background-size: 100px;}
	.pixels_code {padding: 30px 0 30px 0;}
	.pixels_code h2 {font-size: 31px;margin: 0px 0 0 0;padding: 0 2%;line-height: 34px;}
	.content__img-inner {background-size: 100%;background-position: center center;background-repeat: no-repeat;}
	.craft_worlds {margin: 0 2%;width: 96%;}
	.craft_worlds h3 {font-size: 31px;letter-spacing: -2px;margin: 0px 0 10px 0;}
.craft_worlds p {font-size: 17px;line-height: 24px;}	
	
	.craft_worlds_img img.parallax-image {margin: 0 5% 0 0;}
	.craft_worlds_img {min-height: auto;}
.gameverse_monetize img.forge_your_gameverse_icon {width: 60px;position: absolute;right: 10px;}
	.gameverse_monetize {padding: 30px 0 30px 0;}
	.gameverse_monetize_img img.parallax-image {margin: 0px 10% 0 0;}
	
	.monetize_game_img img.parallax-image {margin: 0px 2% 0 0;}
	.monetize_game_img {min-height: auto;}
	.gameverse_monetize_img {min-height: auto;width: 96%;margin: 40px 2% 0 2%;}
	.gameverse_monetize h3 {font-size: 31px;letter-spacing: -1px;margin: 30px 0 10px 0;}
	.gameverse_monetize p {font-size: 17px;line-height: 24px;}
	.btn_dream_game a {padding: 16px 32px;}
	.gameverse_monetize img.level_up_icon {float: right;margin: 0 10px 0 0px;width: 60px;}
	.gameverse_monetize_content_left {margin: 0 2%;width: 96%;}
	.level_up_game_img img.parallax-image {margin: 0px 2% 0 0;}
	.level_up_game_img {min-height: auto;}
	.roadmap {padding: 30px 0 30px 0;}
	.roadmap img.roadmap_icon {width: 60px;position: absolute;right: 10px;}
	
	.roadmap h2 {font-size: 36px;margin: 40px 0 0 0;padding: 0 0 0 20px;line-height: 40px;}
	.roadmap .roadmap_info {margin: 0 2%;padding: 20px 0 0 0;width: 96%;}
	.roadmap_info p {font-size: 17px;line-height: 24px;}
	.roadmap_services:hover {-webkit-transform: scale(1.0,1.0);transform: scale(1.0,1.0);}
	.roadmap_services {margin: 30px 2% 0 2%;padding: 20px;width: 96%;}
	.roadmap_services .icon_number {font-size: 24px;width: 60px;height: 60px;line-height: 60px;}
	.roadmap_services h3 {margin: 15px 0 10px 0;font-size: 27px;}
	.roadmap_services h3 span {font-size: 17px;}
	.roadmap_services p {font-size: 17px;}
	.roadmap_services ul li {font-size: 17px;margin: 30px 0 0px 15px;}
	
	.trusted_partner {padding: 40px 0 0 0;}
	.trusted_partner p {font-size: 17px;padding: 0 5%;line-height: 24px;}
	.ip_content {padding: 60px 0 0 10px;}
	.ip_content_image {min-height: auto;}
	.ip_content_image img.parallax-image {margin: 0px 8% 0 0;}
	
	/* Tools Page */
	.banner_tool h2 {font-size: 36px;letter-spacing: -3px;}
	.banner_tool h5 {margin: 0px 0 30px 0;font-size: 20px;letter-spacing: -1px;}
	.banner_tool h2 img.tool_growing {margin: -4px 0 0 47px;width: 30px;}
	.banner_tool h2 span.star {top: -13px;margin: 0 0 0 -6px;}
	.banner_tool h2 span.star img{width: 30px;}
	.gid_applifier_links a {float: left;margin: 0 10% 10px;width: 80%;font-size: 13px;padding: 20px 40px;}
	.gid_applifier_links a img {top: 0;}
	.tools_banner {margin: 0 0 40px 0;}
	
	.brandanimationcolor span {font-size: 17px;}
	.brandanimationcolor span img {margin: 0px 20px 0 20px;width: 20px;}
	.brandanimationcolor .marquee {height: 20px;}
	
	.brandanimation span {font-size: 17px;}
	.brandanimation span img{margin: 0px 40px 0 40px;width: 20px;}
	.brandanimation .marquee{height: 20px;}
.brandanimationcolor {margin: -32px 0 0 0;}
.brandanimation {transform: rotate(5deg);}
.insights_impact h2 {font-size: 48px;letter-spacing: -3px;}
.insights_impact h2 img {width: 50px;}

	.insights_impact h3 {font-size: 24px;letter-spacing: -2px;margin: 20px 0 10px 0;padding: 0 5%;}
	.insights_impact p {font-size: 17px;line-height: 24px;padding: 0 5%;}
	.applifier {padding: 40px 0 0 0;}
	.applifier h2 {font-size: 26px;letter-spacing: -1px;margin: 0 0 20px 0;}
	.applifier p {font-size: 17px;line-height: 24px;margin: 0px 0 30px 0;padding: 0 5%;}
	.applifier_info {width: 90%;margin: 0 0 50px 0;}
	.applifier_info h3 {font-size: 30px;}
	.applifier .applifier_info p { font-size: 17px;line-height: 24px;margin: 0;padding: 0;}
	
	.bidding_system:hover {-webkit-transform: scale(1.0,1.0);transform: scale(1.0,1.0);}
	.bidding_system {margin: 0 2% 20px 2%;padding: 30px 30px 30px 30px;width: 96%;}
	.bidding_system img {float: left;max-width: 100%;}
	.bidding_system h3 {font-size: 29px;width: 100%;}
	.applifier .bidding_system p{font-size: 17px;line-height: 24px;margin: 0;padding: 0;width: 100%;}
	
	.advanced_analytics:hover {-webkit-transform: scale(1.0,1.0);transform: scale(1.0,1.0);}
  .advanced_analytics {margin: 0 2%;padding: 30px 30px 30px 30px;width: 96%;}
	.advanced_analytics img {max-width: 100%;}
	.advanced_analytics h3 {font-size: 29px;width: 100%;}
	.applifier .advanced_analytics p{ font-size: 17px;line-height: 24px;margin: 0;padding: 0; width: 100%;}
	
	.applifier_system_info:hover{-webkit-transform: scale(1.0,1.0);transform: scale(1.0,1.0);}
	.applifier_system_info {margin: 20px 2%;width: 96%;min-height: auto;}
	.applifier_system_info .icon_system_info img {width: 60px;}
	.applifier_system_info h3 {font-size: 22px;width: 100%;}
	
	.applifier .applifier_system_info p{font-size: 17px;line-height: 22px;margin: 0px 0 0px 0;padding: 0 0%;}
	.applifier .go_beyond h2{font-size: 27px;letter-spacing: -1px;margin: 0;}
	.go_beyond h3 {font-size: 31px}
	.we_help {margin: 0 2% 20px 2%;padding: 30px 30px 30px 30px;width: 96%;}
	
	.we_help h3 {font-size: 31px;letter-spacing: -1px;}
	.we_help h4 {font-size: 48px;letter-spacing: -3px;margin: 10px 0 0px 0;line-height: 48px;}
	
	.gdi_area h2 {font-size: 31px;letter-spacing: -1px;line-height: 36px;}
	.gdi_area h4 {font-size: 36px;margin: 0px 0 10px 0;line-height: 42px;}
	.gdi_area p {font-size: 17px;line-height: 24px;margin: 0px 0 30px 0;padding: 0 10%;}
	
	.gdi_info {margin: 0 0 50px 0;padding: 0 0 0 5%;width: 90%;}
	.gdi_info h3 {font-size: 30px;}
	.gdi_area .gdi_info p { font-size: 17px;line-height: 24px;margin: 0;padding: 0;}
	
	.monetization_mastermind:hover {-webkit-transform: scale(1.0,1.0);transform: scale(1.0,1.0);}
	.monetization_mastermind {margin: 30px 2% 0 2%;padding: 30px;width: 96%;}
	.monetization_mastermind img {max-width: 100%;}
	.monetization_mastermind h3  {font-size: 29px;width: 100%;}
	.gdi_area .monetization_mastermind p{ font-size: 17px;line-height: 24px;margin: 0;padding: 0; width: 100%;}
	
	.benchmark:hover {-webkit-transform: scale(1.0,1.0);transform: scale(1.0,1.0);}
	.benchmark {margin: 30px 2% 0 2%;padding: 30px;width: 96%;}
	.benchmark h3  {font-size: 29px;width: 100%;}
	.gdi_area .benchmark p{ font-size: 17px;line-height: 24px;margin: 0;padding: 0; width: 100%;}
	.benchmark img {max-width: 100%;}
	
	.gdi_system_info:hover{-webkit-transform: scale(1.0,1.0);transform: scale(1.0,1.0);}
	.gdi_system_info {margin: 30px 2% 0 2%;padding: 20px 20px 30px 20px;width: 96%;min-height: auto;}
	.gdi_system_info .icon_system_info img {width: 60px;}
	.gdi_system_info h3{font-size: 22px;width: 100%;}
	.gdi_area .gdi_system_info p{font-size: 17px;line-height: 24px;margin: 0px 0 0px 0;padding: 0 0;width: 100%;}
	.gdi_area .go_beyond  h2{font-size: 31px;letter-spacing: -1px;line-height: 36px;padding: 0 5%;}
	
	.we_help_gdi {margin: 0 2% 20px 2%;padding: 30px 30px 30px 30px;width: 96%;}
	
	.we_help_gdi h3 {font-size: 31px;letter-spacing: -1px;}
	.we_help_gdi h4 {font-size: 48px;letter-spacing: -3px;margin: 10px 0 0px 0;line-height: 48px;}
	
	/* Pixls Page */
	
	.banner_programs .banner_programs_top {display: none;}
	.banner_programs img.banner_programs_top_img{width: 96%; margin: 0 2%; display: block;}
	.just_play2 {margin: 30px 0 30px 0;}
	.right_text {top: 100px;}
	.social_icons_banner {top: 100px;}
	.banner_programs_bottom img.banner_programs_bottom_img{width: 96%; margin: 0 2%; display: block;}
	.banner_programs_bottom .banner_programs_row, .banner_programs_bottom .banner_programs_row_center, .banner_programs_bottom .banner_programs_row_bottom  {display: none;}
	.banner_programs {padding: 50px 0 50px 0;}
	.pixls_program {padding: 0px 2%;width: 100%;}
	.pixls_program_content {padding: 0 0px 30px 0;}
	.pixls_program_animation2 {margin: 0;}
	.pixls_program_info p {font-size: 17px;line-height: 24px;}
	.pixls_program_animation2 .marquee--inner {width: 480%;}
	.pixls_program_animation2 span img {margin: 5px 50px 0 50px;}
	.pixls_program_content_icon .pixls_icon1 {width: 80%;}
	.pixls_program_content_icon .pixls_icon2 {top: -30px;left: 2%;}
	.program_vision_area {padding: 0 0px;}
	.program_vision_title {margin: 60px 2% 0 2%;padding: 0px 0px;width: 96%;}
	.program_vision_title h1 {font-size: 78px;line-height: 90px;}
	.program_vision_title h1 img {margin: -65px 0 0 179px;width: 36px;}
	.program_vision_title img.program_vision_map {margin: 0 auto;float: none;width: 160px;display: block;}
	.program_vision_info {margin: 20px 0 0 2%;padding: 0px 0px 0 20px;width: 96%;}
	.program_vision_info p {font-size: 21px;line-height: 27px;margin: 20px 0 0 0;}
	.program_vision_info p .program_vision_info_img_1 img {width: 100px;height: auto;top: 10px;}
	.program_vision_info p .program_vision_info_img_2 img {width: 148px;height: auto;top: 6px;}
	.program_vision_info p .program_vision_info_img_3 img {width: 165px;height: auto;top: 5px;}
	.program_vision_info p .program_vision_info_img_4 img {width: 190px;height: auto;top: -8px;left: 0;}
	.program_vision_info p .program_vision_info_img_6 img {width: 62px;height: auto;top: 4px;}
	.program_vision_info p .program_vision_info_img_5 img {width: 150px;height: auto;top: 6px;}
	.program_plan {padding: 30px 0 30px 0;margin: 30px 1% 0px 1%;}
	.program_plan_title {padding: 0px 2%;}
	.program_plan_title h1 {font-size: 88px;line-height: 90px;}
	.program_plan_title h1 img.program_plan_title_icon {width: 39px;top: -8px;left: -59px;}
	.program_plan_title h1 img.program_plan_title_icon2 {position: absolute;width: 80px;left: 63%;top: 30px;}
	.program_plan_title img.program_plan_img {margin: -28px 0 0 20px;max-width: 86%;}
	.program_plan_info {width: 100%;}
	.program_plan_info .program_plan_details {min-height: auto;}
	.our_partners {float: left;margin: 20px 1% 0 1%;padding: 30px 0px;}
	.our_partners h1 {font-size: 26px;margin: 0 0 20px 0;}
	.our_partners h1 img.our_partners_icon{width: 22px;}
.achievements {padding: 30px 0px 0 0;}
.achievements h2 {font-size: 66px;width: 96%;margin: 40px 2% 0px 2%;line-height: 68px;}
.achievements h2 span.margin_left {margin-left: 40px !important;}
.achievements h2 span.margin_left2 {margin-left: 91px !important;}
.achievements img.achievements_star {margin: -30px 0 30px 40px;width: 90px;}
.future_plans img.future_plans_img {width: 92%;margin: 0 4%;}
.future_plans {padding: 30px 0 30px 0;}
.future_plans p {font-size: 17px;margin: 20px 0 20px 0;padding: 0 5%;line-height: 26px;}
	.future_plans_info img.fadein_bottom_23{ width: 70%}
	.future_plans_info img.future_plans_icon_right {right: 10px;top: 0;width: 36px;}
	.future_plans_info img.future_plans_icon_left {left: 10px;width: 40px;}
	.future_plans .future_plans_info {padding: 40px 0 30px 0;}
	.achievements_img {margin: 40px 0 0 0;}
	
	.contact_us_programs {padding: 20px 0;}
	.contact_us_programs h1{font-size: 36px;letter-spacing: -1px;line-height: 36px;padding: 0 0% 0 0;width: 70%;}
	.flip span {font-size: 36px;letter-spacing: -1px;line-height: 36px;}
	.hardworking p {margin: 0 0 10px 0;padding: 0 0 0 5%;}
	.contact_us_programs h2 {font-size: 36px;letter-spacing: -1px;line-height: 36px;width: 70%;}
	.contact_us_programs h2 img {width: 30px;margin: 10px 0 0 0;}
	.flip {height: 40px;width: 70%;}
	.contact_us_programs h1 span.righttext {padding: 0px 0 10px 0;font-size: 10px;line-height: 10px;}
	.contact_us_programs h3 {font-size: 36px;letter-spacing: -1px;line-height: 36px;width: 70%;}
	.just_make_games {margin: 0 0 0 0;}
	.contact_us_programs img.contact_us_programs_bolt{display: block;width: 30%;float: right;}
.contactinfo {float: left;margin: 20px 0 0 0;padding: 0 5%;}
.contactus_programs_info .sign_up {float: left;margin: 40px 0 0 0;}
.footer_area_programs {padding: 30px 30px;}
	.footer_area_programs .email {font-size: 27px;margin: 0 0 0px 0;padding: 0 0 0 0;width: 100%;}
	.footer_area_programs .address {font-size: 17px;margin: 10px 0 20px 0;padding: 0 0;}
	.footer_area_programs .social_icons {padding: 0 0 0 0;}
	.footer_area_programs ul.footer_menu {padding: 0 0 0 0%;}
	
	/* Hackathon Page */
	
	.programs_hackathon_top_area {padding: 10px 0 0 0;background-size: 130%;}
	.together_towards_tomorrow {padding: 0px 0 40px 0;}
	.together_towards_tomorrow_top {padding: 0px 0 0 0;}
	.together_towards_tomorrow_top img.be_bold_make_history {width: 110px;}
	.together_towards_tomorrow_top img.together_towards_tomorrow_icon_right_top {margin: 10px 0 0 0;width: 40px;}
	.together_towards_tomorrow_top img.together_towards_tomorrow2 {width: 70%;margin: -20px 0 0 15%;}
	.together_towards_tomorrow_top img.together_towards_tomorrow_icon_right_bottom {margin: 0 0 0 0;width: 80px;}
	.just_play_together_towards_tomorrow {margin: 10px 0 0 0;}
	.together_towards_tomorrow h2 {font-size: 24px;margin: 40px 0 20px 0;padding: 0 0 0 3%;}
	.together_towards_tomorrow p {font-size: 17px;padding: 0 0 0 3%;width: 100%;}
	.together_towards_tomorrow h2 img {top: 5px;left: 5px;width: 60px;}

	.together_towards_tomorrow img.why_hackathon_right_icon_bg {margin: 60px 20px 0 0;width: 180px;}
	.together_towards_tomorrow img.why_hackathon_right_icon {right: 80px;top: 20px;width: 190px;}
	.together_towards_tomorrow_border_bottom .marquee {height: 24px;}
	.together_towards_tomorrow_border_bottom .marquee--inner {width: 600%;}
	.hackathon_program {padding: 30px 0 60px 0;}
	.hackathon_program h2 {font-size: 27px;}
	.hackathon_program h2 img {top: 0px;left: 5px;width: 120px;}
	.hackathon_program p {font-size: 17px;margin: 0 0 20px 0;}
	.phase_area {padding: 0 20px;}
	.phase_area h3 {font-size: 19px;margin: 20px 0 20px 0;padding: 15px 20px;border-radius: 30px;}
	.phase_area h3 span {font-size: 14px;padding: 2px 16px;line-height: 20px;}
	.phase_area_days {width: 96%;top: 100px;}
	.phase_area_days2 {margin: 130px 2% 0 2%;width: 96%;}
	.phase_area_days h3 {font-size: 15px;margin: 0px 0 10px 0;padding: 10px 20px;width: 100%;border-radius: 20px;}
	.phase_area_days2 h3 {font-size: 15px;margin: 0px 0 10px 0;padding: 10px 20px;width: 100%;border-radius: 20px;}
.together_towards_tomorrow_border_bottom img {max-width: 200%;margin: 0 0 0 -50%;}
.journey_of_game_district {padding: 30px 0 30px 0;}
	.journey_of_game_district h2 img{width: 80px}
.journey_of_game_district h2 span {font-size: 27px;margin: 0 0px;}
.journey_of_game_district p {font-size: 17px;margin: 20px 0 40px 0;padding: 0 7%;}
.Journey_arrow {padding: 15px 0;width: 100%;text-align: center;}
.Journey_1 {margin:0 9%;}	
	.Journey_1 h3 {font-size: 21px;}
	
	.Journey_2 {margin: 0 4%;}
	.Journey_2 h3 {font-size: 21px;padding: 28px 0 18px 0;}
	.Journey_3 {margin: 0 4%;}
	.Journey_3 h3 {font-size: 21px;padding: 22px 0 18px 0;}
	
	.Journey_4{margin: 40px 0 0 1% ;}
	.Journey_4 h3 {font-size: 21px;padding: 24px 0 18px 0;}
	.Journey_arrow2 {margin: 0px 0 0 0;padding: 20px 0;float: left;width: 100%;text-align: center;}
	.Journey_5 {margin: 0px 7%;}
.Journey_5 h3 {font-size: 21px;padding: 42px 0 36px 0;}
	.achievements_programs h2 {font-size: 36px;text-align: center;}
	.achievements_programs h2 img {width: 100%;}
	
	.game_scaled_globally:hover {-webkit-transform: scale(1,1);transform: scale(1,1);}
	.game_scaled_globally2 .eight_games:hover {-webkit-transform: scale(1,1);transform: scale(1,1);}
	.game_scaled_globally2 .eight_games_icon:hover {-webkit-transform: scale(1,1);transform: scale(1,1);}
	.retention_marked:hover {-webkit-transform: scale(1,1);transform: scale(1,1);}
	.million_game_downloads:hover {-webkit-transform: scale(1,1);transform: scale(1,1);}
	.prototypes:hover {-webkit-transform: scale(1,1);transform: scale(1,1);}
	.gap_img:hover {-webkit-transform: scale(1,1);transform: scale(1,1);}
	.hit_games:hover {-webkit-transform: scale(1,1);transform: scale(1,1);}
	.achievements_programs {padding: 30px 2% 30px 2%;border-radius: 0 0 30px 30px;}
	.million_game_downloads h3 {padding:40px 0 0 0; }
	.million_game_downloads {margin: 30px 0;}
	.successful_games {padding: 30px 0 30px 0;}
	.successful_games h2 span {font-size: 27px;margin: 0 10px;float: left;}
	.successful_games h2 img{width: 80px;float: left;margin: 14px 0 0 5px; }
	.successful_games p {font-size: 21px;margin: 10px 0 0 0;}
	.margin_top_games {margin-top: 0px !important;}
	.successful_games_info {margin: 15px 2% 15px 2% !important;;width: 96%;}

	.gallery_gd_area {width: 98%;}
	 .gallery_gd_row {width: 100%;margin: 0;max-width:100%;}
	.gallery_gd_row img.gallery_1 {margin: 0 0 15px 0;width: 100%;height: auto;}
	.gallery_gd_row img.gallery_2 {margin: 0 0 15px 0;width: 100%;height: auto}
	.gallery_gd_row img.gallery_3 {margin: 0 0 15px 0px;width: 100%;height: auto}
	.gallery_gd_row img.gallery_4 {margin: 0 0 15px 0;width: 100%;height: auto}
	
	.gallery_gd_row2 {width: 100%;max-width: 100%;margin: 0 0 15px 0;}
	 .gallery_gd_row2 img {height: auto;margin: 0;width: 100%; }
	.gallery_game_district {margin: 15px 1% 0 1%;padding: 30px 0 20px 0;}
	
	
	
	
	
	
	
	
	
	
	
	
	
	
}



@media screen and (min-width: 420px) and (max-width:480px) {
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
float: left !important;width: 100% !important;padding-right: 0 !important;padding-left: 0 !important;}
.main_menu {margin: 0;display: none;}
.mobile_menu{display: block;}
.banner {padding: 30px 0 0 0;}
.banner h2 {font-size: 32px;letter-spacing: -2px;}
.banner h2 span.star {position: absolute;top: -3px;width: 29px;margin: 0 0 0 -5px;}
.banner h2 img.growing {margin: 0 0 0 10px;width: 50px;}
.banner h1 {font-size: 32px;letter-spacing: -2px;}
.banner h1 span img.box {width: 36px;}
.banner h1 img.menap {width: 40px;}
.publish {padding: 30px 0 30px 0;background-position: 0 83%;background-size: 100%;}
	
.banner .employees {margin: 30px 4% 0 0;width: 160px;min-height: 160px;height: auto;padding: 50px 20px 20px 20px;border-radius: 30px;}
.banner .employees h3{font-size: 42px;line-height: 46px;}
.banner .employees p {font-size: 18px;}
.banner .advertising_partners {margin: 30px 0 0 0;width: 160px;min-height: 160px;height: auto;padding: 50px 20px 20px 20px;border-radius: 30px;}
.banner .advertising_partners h3{font-size: 42px;line-height: 46px;}
.banner .advertising_partners p {font-size: 18px;}

.banner .games_published{margin: 30px 4% 0 0;width: 160px;min-height: 160px;height: auto;padding: 50px 20px 20px 20px;border-radius: 30px;}
.banner .games_published h3{font-size: 42px;line-height: 46px;}
.banner .games_published p {font-size: 18px;}

.banner .dau {margin: 30px 0 0 0;width: 160px;min-height: 160px;height: auto;padding: 50px 20px 20px 20px;border-radius: 30px;}
.banner .dau h3{font-size: 42px;line-height: 46px;}
.banner .dau p {font-size: 18px;}

.banner .downloads {margin: 0 auto;float: none;top: 100px;min-height: 600px;}
.banner .downloads .beating {top: -20px;right: 200px;left: auto;}
.our_games {padding: 50px 0 50px 0;}

.content_creators {padding: 0 0 0px 0;background-position: right 30px;background-size: 130px;}
.love_to_work {margin: -60px 2% 0 2%;padding: 30px 30px 120px 30px;width: 96%;}
.love_to_work p {font-size: 17px;width: 100%;}
.love_to_work h2 {font-size: 32px;letter-spacing: -1px;line-height: 36px;width: 100%;}
.work_services .icons img {width: 60px;}
.icon-content {left: 10%;top: 63px;width: 29px;}
.work_services h3 {font-size: 36px;line-height: 36px;}
.work_services p {font-size: 17px;}
img.content_creators_icon {margin: -100px 0 0 0;}
.work_services_top {margin: -20px 0 0 0;}
.work_services {margin: 0 2% 30px 2%;width: 96%;}
.work_services:hover {-webkit-transform: scale(1,1);transform: scale(1,1);-webkit-transition: all 0.3s ease-in-out;}
.content_growth_sand_box {padding: 0 5%;}
.fuel_growth {border: none;padding: 20px 0 0 0px;}
.fuel_growth ul.games_type li {padding: 8px 20px;font-size: 12px;}
.tools h3 {font-size: 31px;line-height: 40px;}
.tools img {padding: 0 0;}
.pixls_inner {padding: 200px 0 45px 34px;}
.gd_created h2 {font-size: 35px;padding: 0 5%;}
.mission_inner {padding: 30px 30px 30px 30px;}
.mission .mission_icom img {width: 84px;}
.mission h2 {font-size: 66px;margin: 40px 0 0 0;}
.mission h2 span {font-size: 42px;}
.mission p {font-size: 18px;line-height: 25px;width: 100%;}
.mission_line_full {top: -200px;}

.vision {margin: 30px 0 0 0;}
.vision_inner {padding: 30px;}
.vision .vision_icom img{width: 84px;}
.pixls_inner {padding: 200px 0 45px 34px;}
.vision h2 {font-size: 42px;margin: 40px 0 0 0;}
.vision h2 span {font-size: 42px;}
.vision p{font-size: 18px;line-height: 25px;width: 100%;}
  
.ceo_pic {padding: 10px 0 0 0;background-size: 100%;width: 80%;margin: 0 10%;}
.message h2 {font-size: 42px;}
.message p {font-size: 17px;margin: 0 0 20px 0;width: 100%;}
.blog_area {margin: 40px 0 0 1%;padding: 40px 0 0 0;}
.blog_area h2 img.icon_left {width: 48px;}
.blog_area h2 span {font-size: 42px;line-height: 64px;}
.blog_area h2 img.icon_right {margin: 0 0 0 5px;
width: 60px;}
.blog_area p {padding: 0 5%;}
.blog_area ul.post_type li a {padding: 8px 14px;font-size: 12px;border-radius: 20px;}
.meet_the_studios {padding: 0px 0;}
.meet_the_studios h2 {font-size: 42px;line-height: 42px;}
.meet_the_studios h2 img {width: 60px;}
.meet_the_studios p {padding: 0 5%;}
.logo_studios .studios_logo {margin: 20px 0 20px 0;width: 50%;}
.logo_studios .studios_logo img {height: 90px;}
.game_changers h2 img {width: 60px;}
.game_changers h2 {font-size: 36px;line-height: 64px;}
.game_changers p {padding: 0 5%;font-size: 17px;}

.home_destop{float: left; margin: 0; padding: 0; width: 100%; display: block;}
.home_mobile{float: left; margin: 0; padding: 0; width: 100%; display: block;}	
.banner .downloads2 {width: 100%;}
.banner .downloads2 .beating {left: 20%;}
.banner .downloads2 img.downloads_dots {left: 36%;}
.left_area .games_icon_row img {margin: 0 8px 8px 0;height: 45px;}
.right_area .games_icon_row_right img {margin: 0 0 8px 8px;height: 45px;}
.our_games .left_area {width: 50%;}
.our_games .right_area {margin: 0px 0 0 0;width: 50%;}
.content_growth_sand_box img {width: 150px;}
.content_growth_sand_box h3 {font-size: 48px;line-height: 48px;margin: 0 0 0 0;}
.fuel_growth p {font-size: 17px;line-height: 22px;margin: 0px 0 15px 0;padding: 0 5%;}
.fuel_growth ul.games_type {margin: 0 0 20px 5%;width: 90%;}
	
	
	
	
.our_games .center_area h1{font-size: 48px;line-height: 48px;}
.our_games .center_area h2{font-size: 48px;line-height: 18px;}
.our_games .center_area h3{font-size: 48px;line-height: 48px;}
.our_games .center_area h3 img{width: 48px;}
.our_games .icons a img{border: none;width: 38px; }
.our_games .center_area p{font-size: 16px;padding: 0 10%;line-height: 22px;}
.our_games .center_area ul.games_type li a {font-size: 12px;}	

.growth_sand_box {padding: 30px 0 30px 0;}	
.growthsandbox-icon img {width: 60px;margin: 0 15px 0 0;}	
.tools {margin: 0 2% 0 2%;width: 96%;border-radius: 30px;}	
.tools p {font-size: 17px;line-height: 24px;margin: 15px 0 30px 0;padding: 0 4%;}
.pixls {margin: 30px 2% 0 2%;width: 96%;border-radius: 30px;}
.gd_hub_content img{width: 180px;}
.pixls h3 {font-size: 31px;line-height: 31px;}
.gd_hub .map img.locations_img {margin: 0;}
.mission_line_full_top {margin: 0 2%;width: 96%;}
.message h2 img {width: 60px;margin: 0 0px 0 0;}
.message {margin: 0 5%;padding: 40px 0 0 0;width: 90%;}
.blog_area .home_blog_post {margin: 0 2%;width: 96%;}

.slider_img_content .item {margin: 0 2% 15px 2%;width: 96%;}
.slider_img_content .active {width: 96%;margin: 0 2%;}
	.slider_img_content .info {width: 100% !important;height: auto;position: relative;margin: 30px 0 0 0;}

	.slider_img_content .item img {max-width: 100%;margin: 0;}
	.slider_img_content .item img.pic_lead {margin: 0;}
	.slider_img_content .item:hover img.pic_lead_active {margin-left: 0;}
	.slider_img_content .info p {font-size: 17px;width: 100%;line-height: 22px;}
	.footer_area {padding: 30px 30px 30px 30px;}
	.footer_area .copy_right {margin: 30px 0 30px 0;}
	.footer_area .phone {margin: 50px 0 15px 0;}
	.contact_us {padding: 30px 3%;}
	.contact_us h1 {font-size: 42px;margin: 0 0 10px 0;}
img.boltmobile{display: block;float: right;width: 110px;position: relative;margin: -60px 0 0 0}
	.contact_us .sign_up {margin: 30px 0 0 0;}
	.bolt {display: none;}
	
	.destop_view{display: none !important;}
.mobile_view{display: block !important;}
.publish_employees{float: none;margin: 0 auto;padding: 0;width: 100%;max-width: 336px;}
	.vision {border-radius: 20px;}
	.mission_vision_area {padding: 0px 0 20px 0;}
	.gd_hub {margin: 20px 1% 0 1%;width: 98%;}
	
	/* ABout Page */
	.banner_about h2 {font-size: 31px;letter-spacing: -1px;margin: 0 0 5px 0;}
	.banner_about h2 img.growing {margin: 0 0 0 5px;width: 30px;}
	.banner_about h2 span.star {top: -7px;margin-left: -7px;}
	.banner_about h2 span.star img {border: none;max-width: 36px;}
	.banner_about h1 {font-size: 31px;letter-spacing: -2px;line-height: 28px;}
	.banner_about h1 span.star {position: absolute;top: -13px;margin-left: 10px;}
	.banner_about h1 span.star img{width: 30px;}
	.banner_about h1 span.icon_about_title {position: absolute;top: 16px;margin-left: -10px;}
	.banner_about h1 span.icon_about_title img{width: 24px;}
	.about_banner img {max-width: 90%;}
	.banner_about {padding: 30px 0 0px 0;}
	.about_us {padding: 0 1%;}
	.about_us_col {padding: 20px 0 20px 0;}
	.about_us_col h2 img.about_us_icon_right {right: 5px;width: 60px;}
	.about_us_col h2 img.about_us_title_icon_left {position: relative;left: 3%;width: 60px;top: -9px;}
	.about_us_col h2 span {font-family: 'FivoSansModern-Heavy';font-size: 42px;}
	.about_us_col h2 img.about_us_title_icon_right {position: relative;left: -15px;top: -20px;width: 75px;}
	.about_us_col h2 {font-size: 31px;height: 140px;}
	.about_us_col p {font-size: 17px;margin: 0px 0 30px 0;padding: 0 5%;}
	.about_us_col h3 {font-size: 42px;margin: 0 0 30px 0;}
	.company_philosphy .box {margin: 10px 1%;padding: 20px;width: 100%;border-radius: 30px;min-height: auto;max-width: 98%;}
	.company_philosphy .box .icon {margin: 0 0 20px 0;}
.company_philosphy .box .icon img {max-width: 60px;}
.company_philosphy .box h4 {font-size: 24px;}
.company_philosphy .box p {font-size: 17px;}
	.empowering_area {padding: 30px 0 0 0;}
	.empowering {padding: 0 1%;}
	.empowering_area h2 {font-size: 32px;margin: 0 0 30px 0;}
	.mission_line_full2 {top: -190px;}
	.empowering_area p {font-size: 17px;margin: 0px 0 20px 0;padding: 0 5%;}
	.empowering_area .gap {height: 0;}
	.empowering_area h2.our_values {font-size: 48px;line-height: 48px;margin: 0 0 20px 0;padding: 30px 30% 0 5%;}
	.our_values_box {margin: 0 1% 25px 1%;padding: 20px 20px 15px 20px;width: 98%;border-radius: 30px;min-height: auto;}
	.our_values_box .icon_number img {width: 60px;}
	.our_values_box h3 {font-size: 24px;margin: 0 0 10px 0;}
	.our_values_box p {font-size: 17px;padding: 0 !important;}
	.our_values_box:hover {-webkit-transform: scale(1,1);transform: scale(1,1);-webkit-transition: all 0.3s ease-in-out;}
	.senior_leadership {margin: 30px 1% 0 1%;padding: 20px;border-radius: 20px 20px;}
	.senior_leadership h2 img {right: 0px;width: 60px;}
	.senior_leadership h2 {font-size: 42px;line-height: 42px;padding: 25px 0 0 0;}
	.senior_leadership p {font-size: 17px;margin: 15px 0 20px 0;width: 100%;line-height: 22px;padding: 0;}
	.col-19 {width: 50%; padding: 0 5px;}
	.col-20 {width: 100%; padding: 0;}
	.leadership_info_1st {margin: 0 0 0 0;}
	.leadership_info_1st .leadership_info_1st_back p {font-size: 20px !important;}
	.leadership_info_2nd {margin: 0;}
	.leadership_info_2nd_back p {font-size: 20px !important;}
	.our_journey h2 {font-size: 31px;line-height: 34px;margin: 0 0 0px 0;padding: 20px 0 20px 0;}
	.game_changers {padding: 30px 0;}	
	
	/* Games Page */
	.banner_games h2 {font-size: 42px;letter-spacing: -3px;}
	.banner_games h2 img.games_icon_love {margin: 5px 0 0 5px;width: 32px;}
	.banner_games h3 {margin: -5px 0 0 0;font-size: 41px;letter-spacing: -3px;padding: 0 0 20px 0;}
	.banner_games h3 img.games_icon_title_left {margin: -8px 0 0 -25px;position: absolute; width: 50px;}
	.banner_games h3 img.games_icon_title_right {margin: 10px 0 0 -12px;width: 50px;}
	.slide {width: 60px;height: 68px;margin: 0 2px;}
	.logos_games {margin: 0 0 0 0;}
	.our_favorite_games {padding: 30px 0 30px 0;}
	.our_favorite_games img.icon_our_favorite_games {margin: 0 10px 0 0;width: 60px;}
	
	.our_favorite_games h1 {font-size: 48px;margin: -50px 0 10px 5%;line-height: 48px}
	.our_favorite_games h1 a img {width: 30px;}
	.our_favorite_games h1 img.favoritegame-title-icon {width: 36px;}
	.our_favorite_games ul.games_type li a {padding: 6px 15px;}
	.our_favorite_games .content_area {width: 90%;}
	.our_favorite_games .content_area p {font-size: 17px;margin: 0px 0 20px 0;}
	
	.games_info {max-height: 100%;margin: 120px 0 0 0;}
	.games_info .games_icon_left {margin: 0px 1%;padding: 0 0 0 0px;width: 98%;text-align: center;background-color: #BEF3FF;border-radius: 30px 30px 0 0;}
	.games_info .games_icon_left img {width: 120px;margin: -70px 0 0 0;}
	.games_info .about_games {margin: 0 1%;padding: 20px;width: 98%;border-radius: 0 0 30px 30px;}
	.about_games .game_title_icon .icon_game {width: 70px;margin: 0 10px 0 0;}
	.about_games .game_title_icon h5 {font-size: 18px;}
	.about_games .game_title_icon h3 {font-size: 24px;line-height: 24px;}
	.about_games ul.games_type1 li a {padding: 5px 11px;font-size: 11px;}
	.about_games p {font-size: 16px;width: 100%;}
	.about_games .store_links a {margin: 0 7px 0 0;padding: 10px 15px;font-size: 12px;}
	.about_games .store_links a img {margin: 3px 5px 0 0;width: 16px;}
	
	
	.games_info .games_icon_right {margin: 0px 1%;padding: 0 0 0 0px;width: 98%;text-align: center;background-color: #BEF3FF;border-radius: 30px 30px 0 0;position: absolute;top: 0;}
	.games_info .games_icon_right img {width: 120px;margin: -70px 0 0 0;}
	.games_info .about_games_right {margin: 240px 1% 0 1%;padding: 20px;width: 98%;border-radius: 0 0 30px 30px;}
	.about_games_right .game_title_icon .icon_game {width: 70px;margin: 0 10px 0 0;}
	.about_games_right .game_title_icon h5 {font-size: 18px;}
	.about_games_right .game_title_icon h3 {font-size: 24px;line-height: 24px;}
	.about_games_right ul.games_type1 li a {padding: 5px 11px;font-size: 11px;}
	.about_games_right p {font-size: 16px;width: 100%;}
	.about_games_right .store_links a {margin: 0 7px 0 0;padding: 10px 15px;font-size: 12px;}
	.about_games_right .store_links a img {margin: 3px 5px 0 0;width: 16px;}
	
	.our_awesome_studios {padding: 30px 0 30px 0;}
	.our_awesome_studios img.icon_our_awesome_studios {margin: 0 10px 0 0;width: 60px;}
	.our_awesome_studios h1 {font-size: 48px;margin: -25px 0 0 5%;line-height: 48px;}
	.our_awesome_studios h1 img.awesome_studios_title-icon {margin: -18px 0 0 5px;width: 60px;}
	.our_awesome_studios .content_area {width: 90%;}
	.our_awesome_studios .content_area p {font-size: 17px; margin: 0px 0 20px 0;}
	
	.our_awesome_studios img.studios_icon_center {width: 120px;height: 120px;left: 36%;top: 140px;}
	.sections .our_studios_logo {margin: 10px;padding: 30px 0 0 0;width: 185px;height: 185px;}
	.sections img {max-width: 80%;}
	.flex-center {height: 200px;}
	ul.accordion-list {margin: 30px 0 0 0;}
	ul.accordion-list li {padding:40px 15px 40px 25px;border-radius: 30px 30px 0 0;}
	ul.accordion-list li.active h3:after {left: -20px;}
	ul.accordion-list li h3:after {left: -20px;}
	.answer .studios_info p {font-size: 17px;}
	.answer .studios_info .store_links a {margin: 0 8px 10px 8px;padding: 10px 20px;}
	.games_icons_row img {margin: 0 0 8px 8px;width: 76px;}
	
	
	/* gdhub Page */
	
	.banner_gd_hub h2 {font-size: 42px;letter-spacing: -2px;}
	.banner_gd_hub h2 img.icon_gd_hub_left {margin: 0 0 0 0;width: 42px;}
	.banner_gd_hub h1 {font-size: 42px;letter-spacing: -2px;line-height: 42px;}
	.banner_gd_hub h2 span.star {top: -10px;margin-left: 0;}
	.banner_gd_hub h2 span.star img{ width: 48px;}
	.banner_gd_hub h1 span.icon_about_title {top: 26px;margin-left: -32px;}
	.banner_gd_hub h1 span.icon_about_title img {width: 42px;}
	.gd_hub_empowering_studios {padding: 0 2%;}
	.gd_hub_empowering_studios_area {padding: 30px 0 30px 0;}
	.gd_hub_empowering_studios_info {margin: 0 0 0 0; text-align: center;}
	
	.gd_hub_empowering_studios_info img{border: none;max-width: 100%;width: 250px;}
	.gd_hub_empowering_studios_info h3 {font-size: 48px;margin: 30px 0 0px 5%;letter-spacing: -3px;padding: 0 90px 0 0;}
	.gd_hub_empowering_studios_details {margin: 20px 0 0 0;padding: 0 5%;}
	.gd_hub_info_area {margin: 20px 1% 20px 1%;padding: 30px 0 30px 0;}
	.gd_hub_info_area h2 {font-size: 48px;line-height: 48px;float: left;margin: 0 0 20px 0;letter-spacing: -1px;padding: 0 0;}
	.gd_hub_info_area p {font-size: 17px;padding: 0 5%;}
	.gd_hub_info_area p.inner {font-size: 17px;padding: 0 5%;}
	.gd_hub_values_box:hover {-webkit-transform: scale(1.0,1.0);transform: scale(1.0,1.0);-webkit-transition: all 0.3s ease-in-out;}
	.gd_hub_values_box {margin: 0 2% 25px 2%;padding: 20px 20px 20px 20px;width: 96%;border-radius: 30px;min-height: auto;}
	.gd_hub_values_box .icon_number img {width: 60px;}
	.gd_hub_values_box h3 {font-size: 24px;margin: 0 0 15px 0;padding: 0 50px 0 0;}
	.gd_hub_info_area .gd_hub_values_box p {font-size: 17px;padding: 0 0;}
	.gd_hub_values_box_area h2.our_values {font-size: 42px;line-height: 42px;letter-spacing: 0px;padding: 10px 5% 0 5%;}
	.games_are_played h2 {font-size: 48px;line-height: 48px;margin: 0 0 20px 0;letter-spacing: 0px;padding: 0 12% 0 5%;}
	.games_are_played {padding: 40px 0 40px 0;}
	.games_are_played h2 img.games_are_played_icon {right: 18%;top: 36px;width: 56px;}
	.games_are_played p {font-size: 17px;padding: 0 5%;}
	.games_are_played_info {padding: 30px 0 0 0;}
	
	
		
	/* IP Page */
	.ip_banner {background-position: left top;background-size: 100%;}
	.banner_ip h2 {font-size: 36px;}
	.banner_ip h2 img.star_ip {margin: -6px 0 0 -3px;width: 24px;}
	.banner_ip h3 {margin: 10px 0 30px 0;font-size: 36px;}
	.banner_ip {background-position: right 26px;background-size: 110px;padding: 50px 0 50px 0;}
	.banner_ip_top {background-position: 0px 30px;background-size: 100px;}
	.pixels_code {padding: 30px 0 30px 0;}
	.pixels_code h2 {font-size: 31px;margin: 0px 0 0 0;padding: 0 2%;line-height: 34px;}
	.content__img-inner {background-size: 100%;background-position: center center;background-repeat: no-repeat;}
	.craft_worlds {margin: 0 2%;width: 96%;}
	.craft_worlds h3 {font-size: 42px;letter-spacing: -2px;margin: 0px 0 10px 0;}
.craft_worlds p {font-size: 20px;line-height: 27px;}	
	
	.craft_worlds_img img.parallax-image {margin: 0 5% 0 0;}
	.craft_worlds_img {min-height: auto;}
.gameverse_monetize img.forge_your_gameverse_icon {width: 60px;position: absolute;right: 10px;}
	.gameverse_monetize {padding: 30px 0 30px 0;}
	.gameverse_monetize_img img.parallax-image {margin: 0px 10% 0 0;}
	
	.monetize_game_img img.parallax-image {margin: 0px 2% 0 0;}
	.monetize_game_img {min-height: auto;}
	.gameverse_monetize_img {min-height: auto;width: 96%;margin: 40px 2% 0 2%;}
	.gameverse_monetize h3 {font-size: 42px;letter-spacing: -1px;margin: 30px 0 10px 0;}
	.gameverse_monetize p {font-size: 20px;line-height: 27px;}
	.btn_dream_game a {padding: 16px 32px;}
	.gameverse_monetize img.level_up_icon {float: right;margin: 0 10px 0 0px;width: 60px;}
	.gameverse_monetize_content_left {margin: 0 2%;width: 96%;}
	.level_up_game_img img.parallax-image {margin: 0px 2% 0 0;}
	.level_up_game_img {min-height: auto;}
	.roadmap {padding: 30px 0 30px 0;}
	.roadmap img.roadmap_icon {width: 60px;position: absolute;right: 10px;}
	
	.roadmap h2 {font-size: 48px;margin: 40px 0 0 0;padding: 0 0 0 20px;line-height: 48px;}
	.roadmap .roadmap_info {margin: 0 2%;padding: 20px 0 0 0;width: 96%;}
	.roadmap_info p {font-size: 20px;line-height: 27px;}
	.roadmap_services:hover {-webkit-transform: scale(1.0,1.0);transform: scale(1.0,1.0);}
	.roadmap_services {margin: 30px 2% 0 2%;padding: 20px;width: 96%;}
	.roadmap_services .icon_number {font-size: 24px;width: 60px;height: 60px;line-height: 60px;}
	.roadmap_services h3 {margin: 15px 0 10px 0;font-size: 27px;}
	.roadmap_services h3 span {font-size: 20px;}
	.roadmap_services p {font-size: 20px;}
	.roadmap_services ul li {font-size: 20px;margin: 30px 0 0px 15px;}
	
	.trusted_partner {padding: 40px 0 0 0;}
	.trusted_partner p {font-size: 20px;padding: 0 5%;line-height: 24px;}
	.ip_content {padding: 60px 0 0 10px;}
	.ip_content_image {min-height: auto;}
	.ip_content_image img.parallax-image {margin: 0px 8% 0 0;}
	
	
		/* Tools Page */
	.banner_tool h2 {font-size: 48px;letter-spacing: -3px;}
	.banner_tool h5 {margin: 5px 0 30px 0;font-size: 25px;letter-spacing: -1px;}
	.banner_tool h2 img.tool_growing {margin: -3px 0 0 60px;width: 36px;}
	.banner_tool h2 span.star {top: -18px;margin: 0 0 0 -8px;}
	.banner_tool h2 span.star img{width: 36px;}
	.gid_applifier_links a {float: left;margin: 0 10% 10px;width: 80%;font-size: 13px;padding: 20px 40px;}
	.gid_applifier_links a img {top: 0;}
	.tools_banner {margin: 0 0 40px 0;}
	
	.brandanimationcolor span {font-size: 18px;}
	.brandanimationcolor span img {margin: 0px 40px 0 40px;width: 20px;}
	.brandanimationcolor .marquee {height: 20px;}
	
	.brandanimation span {font-size: 18px;}
	.brandanimation span img{margin: 0px 40px 0 40px;width: 20px;}
	.brandanimation .marquee{height: 20px;}
.brandanimationcolor {margin: -32px 0 0 0;}
.brandanimation {transform: rotate(3deg);}
.insights_impact h2 {font-size: 56px;letter-spacing: -3px;}
.insights_impact h2 img {width: 50px;}

	.insights_impact h3 {font-size: 31px;letter-spacing: -2px;margin: 20px 0 10px 0;padding: 0 5%;}
	.insights_impact p {padding: 0 5%;}
	.applifier {padding: 40px 0 0 0;}
	.applifier h2 {font-size: 34px;letter-spacing: -1px;margin: 0 0 20px 0;}
	.applifier p {margin: 0px 0 30px 0;padding: 0 5%;}
	.applifier_info {width: 90%;margin: 0 0 50px 0;}
	.applifier_info h3 {font-size: 38px;}
	.applifier .applifier_info p { margin: 0;padding: 0;}
	
	.bidding_system:hover {-webkit-transform: scale(1.0,1.0);transform: scale(1.0,1.0);}
	.bidding_system {margin: 0 2% 20px 2%;padding: 30px 30px 30px 30px;width: 96%;}
	.bidding_system img {float: left;max-width: 100%;}
	.bidding_system h3 {font-size: 36px;width: 100%;}
	.applifier .bidding_system p{margin: 0;padding: 0;width: 100%;}
	
	.advanced_analytics:hover {-webkit-transform: scale(1.0,1.0);transform: scale(1.0,1.0);}
  .advanced_analytics {margin: 0 2%;padding: 30px 30px 30px 30px;width: 96%;}
	.advanced_analytics img {max-width: 100%;}
	.advanced_analytics h3 {font-size: 36px;width: 100%;}
	.applifier .advanced_analytics p{ margin: 0;padding: 0; width: 100%;}
	
	.applifier_system_info:hover{-webkit-transform: scale(1.0,1.0);transform: scale(1.0,1.0);}
	.applifier_system_info {margin: 20px 2%;width: 96%;min-height: auto;}
	.applifier_system_info .icon_system_info img {width: 60px;}
	.applifier_system_info h3 {font-size: 27px;width: 100%;}
	
	.applifier .applifier_system_info p{margin: 0px 0 0px 0;padding: 0 0%;}
	.applifier .go_beyond h2{font-size: 36px;letter-spacing: -1px;margin: 0;}
	.go_beyond h3 {font-size: 31px}
	.we_help {margin: 0 2% 20px 2%;padding: 30px 30px 30px 30px;width: 96%;}
	
	.we_help h3 {font-size: 42px;letter-spacing: -1px;}
	.we_help h4 {font-size: 56px;letter-spacing: -3px;margin: 10px 0 0px 0;line-height: 56px;}
	
	.gdi_area h2 {font-size: 42px;letter-spacing: -1px;line-height: 42px;}
	.gdi_area h4 {font-size: 36px;margin: 0px 0 10px 0;line-height: 42px;}
	.gdi_area p {margin: 0px 0 30px 0;padding: 0 5%;}
	
	.gdi_info {margin: 0 0 50px 0;padding: 0 0 0 5%;width: 90%;}
	.gdi_info h3 {font-size: 36px;}
	.gdi_area .gdi_info p { margin: 0;padding: 0;}
	
	.monetization_mastermind:hover {-webkit-transform: scale(1.0,1.0);transform: scale(1.0,1.0);}
	.monetization_mastermind {margin: 30px 2% 0 2%;padding: 30px;width: 96%;}
	.monetization_mastermind img {max-width: 100%;}
	.monetization_mastermind h3  {font-size: 29px;width: 100%;}
	.gdi_area .monetization_mastermind p{ font-size: 17px;line-height: 24px;margin: 0;padding: 0; width: 100%;}
	
	.benchmark:hover {-webkit-transform: scale(1.0,1.0);transform: scale(1.0,1.0);}
	.benchmark {margin: 30px 2% 0 2%;padding: 30px;width: 96%;}
	.benchmark h3  {font-size: 29px;width: 100%;}
	.gdi_area .benchmark p{ font-size: 17px;line-height: 24px;margin: 0;padding: 0; width: 100%;}
	.benchmark img {max-width: 100%;}
	
	.gdi_system_info:hover{-webkit-transform: scale(1.0,1.0);transform: scale(1.0,1.0);}
	.gdi_system_info {margin: 30px 2% 0 2%;padding: 20px 20px 30px 20px;width: 96%;min-height: auto;}
	.gdi_system_info .icon_system_info img {width: 60px;}
	.gdi_system_info h3{font-size: 31px;width: 100%;}
	.gdi_area .gdi_system_info p{font-size: 17px;line-height: 24px;margin: 0px 0 0px 0;padding: 0 0;width: 100%;}
	.gdi_area .go_beyond  h2{font-size: 42px;letter-spacing: -1px;line-height: 42px;padding: 0 5%;}
	
	.we_help_gdi {margin: 0 2% 20px 2%;padding: 30px 30px 30px 30px;width: 96%;}
	
	.we_help_gdi h3 {font-size: 42px;letter-spacing: -1px;}
	.we_help_gdi h4 {font-size: 56px;letter-spacing: -3px;margin: 10px 0 0px 0;line-height: 56px;}
	
	
	/* Pixls Page */
	
	.banner_programs .banner_programs_top {display: none;}
	.banner_programs img.banner_programs_top_img{width: 96%; margin: 0 2%; display: block;}
	.just_play2 {margin: 30px 0 30px 0;}
	.right_text {top: 100px;}
	.social_icons_banner {top: 100px;}
	.banner_programs_bottom img.banner_programs_bottom_img{width: 96%; margin: 0 2%; display: block;}
	.banner_programs_bottom .banner_programs_row, .banner_programs_bottom .banner_programs_row_center, .banner_programs_bottom .banner_programs_row_bottom  {display: none;}
	.banner_programs {padding: 50px 0 50px 0;}
	.pixls_program {padding: 0px 2%;width: 100%;}
	.pixls_program_content {padding: 0 0px 30px 0;}
	.pixls_program_animation2 {margin: 0;}
	.pixls_program_info p {font-size: 17px;line-height: 24px;margin: 60px 0 0 0;}
	.pixls_program_animation2 .marquee--inner {width: 480%;}
	.pixls_program_animation2 span img {margin: 5px 50px 0 50px;}
	.pixls_program_content_icon .pixls_icon1 {width: 80%;}
	.pixls_program_content_icon .pixls_icon2 {top: -30px;left: 2%;}
	.program_vision_area {padding: 0 0px;}
	.program_vision_title {margin: 60px 2% 0 2%;padding: 0px 0px;width: 96%;}
	.program_vision_title h1 {font-size: 78px;line-height: 90px;}
	.program_vision_title h1 img {margin: -65px 0 0 179px;width: 36px;}
	.program_vision_title img.program_vision_map {margin: 0 auto;float: none;width: 160px;display: block;}
	.program_vision_info {margin: 20px 0 0 2%;padding: 0px 0px 0 20px;width: 96%;}
	.program_vision_info p {font-size: 21px;line-height: 27px;margin: 20px 0 0 0;}
	.program_vision_info p .program_vision_info_img_1 img {width: 100px;height: auto;top: 10px;}
	.program_vision_info p .program_vision_info_img_2 img {width: 148px;height: auto;top: 6px;}
	.program_vision_info p .program_vision_info_img_3 img {width: 165px;height: auto;top: 5px;}
	.program_vision_info p .program_vision_info_img_4 img {width: 190px;height: auto;top: -8px;left: 0;}
	.program_vision_info p .program_vision_info_img_6 img {width: 62px;height: auto;top: 4px;}
	.program_vision_info p .program_vision_info_img_5 img {width: 150px;height: auto;top: 6px;}
	.program_plan {padding: 30px 0 30px 0;margin: 30px 1% 0px 1%;}
	.program_plan_title {padding: 0px 2%;}
	.program_plan_title h1 {font-size: 88px;line-height: 90px;}
	.program_plan_title h1 img.program_plan_title_icon {width: 39px;top: -8px;left: -59px;}
	.program_plan_title h1 img.program_plan_title_icon2 {position: absolute;width: 80px;left: 63%;top: 30px;}
	.program_plan_title img.program_plan_img {margin: -28px 0 0 20px;max-width: 86%;}
	.program_plan_info {width: 100%;}
	.program_plan_info .program_plan_details {min-height: auto;}
	.our_partners {float: left;margin: 20px 1% 0 1%;padding: 30px 0px;}
	.our_partners h1 {font-size: 26px;margin: 0 0 20px 0;}
	.our_partners h1 img.our_partners_icon{width: 22px;}
.achievements {padding: 30px 0px 0 0;}
.achievements h2 {font-size: 66px;width: 96%;margin: 40px 2% 0px 2%;line-height: 68px;}
.achievements h2 span.margin_left {margin-left: 40px !important;}
.achievements h2 span.margin_left2 {margin-left: 91px !important;}
.achievements img.achievements_star {margin: -30px 0 30px 40px;width: 90px;}
.future_plans img.future_plans_img {width: 92%;margin: 0 4%;}
.future_plans {padding: 30px 0 30px 0;}
.future_plans p {font-size: 17px;margin: 20px 0 20px 0;padding: 0 5%;line-height: 26px;}
	.future_plans_info img.fadein_bottom_23{ width: 70%}
	.future_plans_info img.future_plans_icon_right {right: 10px;top: 0;width: 36px;}
	.future_plans_info img.future_plans_icon_left {left: 10px;width: 40px;}
	.future_plans .future_plans_info {padding: 40px 0 30px 0;}
	.achievements_img {margin: 40px 0 0 0;}
	
	.contact_us_programs {padding: 20px 0;}
	.contact_us_programs h1{font-size: 36px;letter-spacing: -1px;line-height: 36px;padding: 0 0% 0 0;width: 70%;}
	.flip span {font-size: 36px;letter-spacing: -1px;line-height: 36px;}
	.hardworking p {margin: 0 0 10px 0;padding: 0 0 0 5%;}
	.contact_us_programs h2 {font-size: 36px;letter-spacing: -1px;line-height: 36px;width: 70%;}
	.contact_us_programs h2 img {width: 30px;margin: 10px 0 0 0;}
	.flip {height: 40px;width: 70%;}
	.contact_us_programs h1 span.righttext {padding: 0px 0 10px 0;font-size: 10px;line-height: 10px;}
	.contact_us_programs h3 {font-size: 36px;letter-spacing: -1px;line-height: 36px;width: 70%;}
	.just_make_games {margin: 0 0 0 0;}
	.contact_us_programs img.contact_us_programs_bolt{display: block;width: 30%;float: right;}
.contactinfo {float: left;margin: 20px 0 0 0;padding: 0 5%;}
.contactus_programs_info .sign_up {float: left;margin: 40px 0 0 0;}
.footer_area_programs {padding: 30px 30px;}
	.footer_area_programs .email {font-size: 27px;margin: 0 0 0px 0;padding: 0 0 0 0;width: 100%;}
	.footer_area_programs .address {font-size: 17px;margin: 10px 0 20px 0;padding: 0 0;}
	.footer_area_programs .social_icons {padding: 0 0 0 0;}
	.footer_area_programs ul.footer_menu {padding: 0 0 0 0%;}
	
		/* Hackathon Page */
	
	.programs_hackathon_top_area {padding: 10px 0 0 0;background-size: 130%;}
	.together_towards_tomorrow {padding: 0px 0 40px 0;}
	.together_towards_tomorrow_top {padding: 0px 0 0 0;}
	.together_towards_tomorrow_top img.be_bold_make_history {width: 130px;}
	.together_towards_tomorrow_top img.together_towards_tomorrow_icon_right_top {margin: 10px 4px 0 0;width: 50px;}
	.together_towards_tomorrow_top img.together_towards_tomorrow2 {width: 90%;margin: -20px 0 0 5%;}
	.together_towards_tomorrow_top img.together_towards_tomorrow_icon_right_bottom {margin: 0 0 0 0;width: 90px;}
	.just_play_together_towards_tomorrow {margin: 10px 0 0 0;}
	.together_towards_tomorrow h2 {font-size: 31px;margin: 40px 0 20px 0;padding: 0 0 0 3%;}
	.together_towards_tomorrow p {font-size: 17px;padding: 0 0 0 3%;width: 100%;}
	.together_towards_tomorrow h2 img {top: 5px;left: 5px;width: 80px;}

	.together_towards_tomorrow img.why_hackathon_right_icon_bg {margin: 60px 20px 0 0;width: 180px;}
	.together_towards_tomorrow img.why_hackathon_right_icon {right: 80px;top: 20px;width: 190px;}
	.together_towards_tomorrow_border_bottom .marquee {height: 24px;}
	.together_towards_tomorrow_border_bottom .marquee--inner {width: 600%;}
	.hackathon_program {padding: 30px 0 60px 0;}
	.hackathon_program h2 {font-size: 31px;}
	.hackathon_program h2 img {top: 0px;left: 5px;width: 140px;}
	.hackathon_program p {font-size: 17px;margin: 0 0 20px 0;}
	.phase_area {padding: 0 20px;}
	.phase_area h3 {font-size: 19px;margin: 20px 0 20px 0;padding: 15px 20px;border-radius: 30px;}
	.phase_area h3 span {font-size: 14px;padding: 2px 16px;line-height: 20px;}
	.phase_area_days {width: 96%;top: 100px;}
	.phase_area_days2 {margin: 130px 2% 0 2%;width: 96%;}
	.phase_area_days h3 {font-size: 15px;margin: 0px 0 10px 0;padding: 10px 20px;width: 100%;border-radius: 20px;}
	.phase_area_days2 h3 {font-size: 15px;margin: 0px 0 10px 0;padding: 10px 20px;width: 100%;border-radius: 20px;}
.together_towards_tomorrow_border_bottom img {max-width: 200%;margin: 0 0 0 -50%;}
.journey_of_game_district {padding: 30px 0 30px 0;}
	.journey_of_game_district h2 img{width: 110px}
.journey_of_game_district h2 span {font-size: 31px;margin: 0 0px;}
.journey_of_game_district p {font-size: 17px;margin: 20px 0 40px 0;padding: 0 7%;}
.Journey_arrow {padding: 15px 0;width: 100%;text-align: center;}
.Journey_1 {margin: 0 auto;width: 278px;float: none;}	
	.Journey_2 {margin: 0 auto;float: none;width: 312px}
	.Journey_3 {margin: 0 auto;float: none;width: 318px}
	.Journey_4{margin: 0 auto;float: none;width: 340px;padding: 20px 0 0 0;}
	.Journey_5 {margin: 0 auto;float: none;width: 284px}
	.Journey_arrow2 {margin: 0px 0 0 0;padding: 20px 0;float: left;width: 100%;text-align: center;}
	

	
	
	.achievements_programs h2 {font-size: 36px;text-align: center;}
	.achievements_programs h2 img {width: 100%;}
	
	.game_scaled_globally:hover {-webkit-transform: scale(1,1);transform: scale(1,1);}
	.game_scaled_globally2 .eight_games:hover {-webkit-transform: scale(1,1);transform: scale(1,1);}
	.game_scaled_globally2 .eight_games_icon:hover {-webkit-transform: scale(1,1);transform: scale(1,1);}
	.retention_marked:hover {-webkit-transform: scale(1,1);transform: scale(1,1);}
	.million_game_downloads:hover {-webkit-transform: scale(1,1);transform: scale(1,1);}
	.prototypes:hover {-webkit-transform: scale(1,1);transform: scale(1,1);}
	.gap_img:hover {-webkit-transform: scale(1,1);transform: scale(1,1);}
	.hit_games:hover {-webkit-transform: scale(1,1);transform: scale(1,1);}
	.achievements_programs {padding: 30px 2% 30px 2%;border-radius: 0 0 30px 30px;}
	.million_game_downloads h3 {padding:40px 0 0 0; }
	.million_game_downloads {margin: 30px 0;}
	.successful_games {padding: 30px 0 30px 0;}
	.successful_games h2 span {font-size: 33px;margin: 0 15px;float: left;}
	.successful_games h2 img{width: 110px;float: left;margin: 14px 0 0 5px; }
	.successful_games p {font-size: 21px;margin: 10px 0 0 0;}
	.margin_top_games {margin-top: 0px !important;}
	.successful_games_info {margin: 15px 2% 15px 2% !important;;width: 96%;}

	.gallery_gd_area {width: 98%;}
	 .gallery_gd_row {width: 100%;margin: 0;max-width:100%;}
	.gallery_gd_row img.gallery_1 {margin: 0 0 15px 0;width: 100%;height: auto;}
	.gallery_gd_row img.gallery_2 {margin: 0 0 15px 0;width: 100%;height: auto}
	.gallery_gd_row img.gallery_3 {margin: 0 0 15px 0px;width: 100%;height: auto}
	.gallery_gd_row img.gallery_4 {margin: 0 0 15px 0;width: 100%;height: auto}
	
	.gallery_gd_row2 {width: 100%;max-width: 100%;margin: 0 0 15px 0;}
	 .gallery_gd_row2 img {height: auto;margin: 0;width: 100%;}
	.gallery_game_district {margin: 15px 1% 0 1%;padding: 30px 0 20px 0;}
	
}




/*
@media screen and (min-width: 320px) and (max-width:480px) {
	
	
}
    
iPhone 6-8 Plump landscape · width: 736px
iPhone eXpensive landscape · width: 734px



Android (Pixel 2) landscape · width: 684px
iPhone 6-8 landscape · width: 667px
iPad portrait · width: 768px*/




@media screen and (min-width: 481px) and (max-width: 767px) {
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
float: left !important;width: 100% !important;padding-right: 0 !important;padding-left: 0 !important;}
.main_menu {margin: 0;display: none;}
.mobile_menu{display: block;}
.banner {padding: 30px 0 0 0;}
.banner h2 {font-size: 48px;letter-spacing: -2px;}
.banner h2 span.star {position: absolute;top: -18px;width: 29px;margin: 0 0 0 -5px;}
.banner h2 img.growing {margin: 0 0 0 10px;width: 50px;}
.banner h1 {font-size: 48px;letter-spacing: -2px;margin: 0;}
.banner h1 span img.box {width: 36px;}
.banner h1 img.menap {width: 40px;}
.publish {padding: 30px 0 30px 0;background-position: 0 83%;background-size: 100%;}
	
	
.banner .employees {margin: 30px 4% 0 0;width: 160px;min-height: 160px;height: auto;padding: 50px 20px 20px 20px;border-radius: 30px;}
.banner .employees h3{font-size: 42px;line-height: 46px;}
.banner .employees p {font-size: 18px;}
.banner .advertising_partners {margin: 30px 0 0 0;width: 160px;min-height: 160px;height: auto;padding: 50px 20px 20px 20px;border-radius: 30px;}
.banner .advertising_partners h3{font-size: 42px;line-height: 46px;}
.banner .advertising_partners p {font-size: 18px;}

.banner .games_published{margin: 30px 4% 0 0;width: 160px;min-height: 160px;height: auto;padding: 50px 20px 20px 20px;border-radius: 30px;}
.banner .games_published h3{font-size: 42px;line-height: 46px;}
.banner .games_published p {font-size: 18px;}

.banner .dau {margin: 30px 0 0 0;width: 160px;min-height: 160px;height: auto;padding: 50px 20px 20px 20px;border-radius: 30px;}
.banner .dau h3{font-size: 42px;line-height: 46px;}
.banner .dau p {font-size: 18px;}

.banner .downloads {margin: 0 auto;float: none;top: 100px;min-height: 600px;}
.banner .downloads .beating {top: -20px;right: 200px;left: auto;}
.our_games {padding: 50px 0 50px 0;}
.our_games .center_area ul.games_type {padding: 0 10%;}
.content_creators {padding: 0 0 0px 0;background-position: right 30px;background-size: 130px;}
.love_to_work {margin: -60px 2% 0 2%;padding: 30px 30px 120px 30px;width: 96%;}
.love_to_work p {font-size: 17px;width: 100%;}
.love_to_work h2 {font-size: 32px;letter-spacing: -1px;line-height: 36px;width: 100%;}
.work_services .icons img {width: 60px;}
.icon-content {left: 10%;top: 63px;width: 29px;}
.work_services h3 {font-size: 36px;line-height: 36px;}
.work_services p {font-size: 17px;}
img.content_creators_icon {margin: -100px 0 0 0;}
.work_services_top {margin: -20px 0 0 0;}
.work_services {margin: 0 2% 30px 2%;width: 96%;}
.work_services:hover {-webkit-transform: scale(1,1);transform: scale(1,1);-webkit-transition: all 0.3s ease-in-out;}
.content_growth_sand_box {padding: 0 5%;}
.fuel_growth {border: none;padding: 20px 0 0 0px;}
.fuel_growth ul.games_type li {padding: 8px 20px;font-size: 12px;}
.tools h3 {font-size: 31px;line-height: 40px;}
.tools img {padding: 0 0;}
.pixls_inner {padding: 200px 0 45px 34px;}
.gd_created h2 {font-size: 35px;padding: 0 5%;}
.mission_inner {padding: 30px 30px 30px 30px;}
.mission .mission_icom img {width: 84px;}
.mission h2 {font-size: 66px;margin: 40px 0 0 0;}
.mission h2 span {font-size: 42px;}
.mission p {font-size: 18px;line-height: 25px;width: 100%;}
.mission_line_full {top: -200px;}

.vision {margin: 30px 0 0 0;}
.vision_inner {padding: 30px;}
.vision .vision_icom img{width: 84px;}
.pixls_inner {padding: 200px 0 45px 34px;}
.vision h2 {font-size: 42px;margin: 40px 0 0 0;}
.vision h2 span {font-size: 42px;}
.vision p{font-size: 18px;line-height: 25px;width: 100%;}
  
.ceo_pic {padding: 10px 0 0 0;background-size: 100%;width: 80%;margin: 0 10%;}
.message h2 {font-size: 42px;}
.message p {font-size: 17px;margin: 0 0 20px 0;width: 100%;}
.blog_area {margin: 40px 0 0 1%;padding: 40px 0 0 0;}
.blog_area h2 img.icon_left {width: 48px;}
.blog_area h2 span {font-size: 42px;line-height: 64px;}
.blog_area h2 img.icon_right {margin: 0 0 0 5px;
width: 60px;}
.blog_area p {padding: 0 5%;}
.blog_area ul.post_type li a {padding: 8px 14px;font-size: 12px;border-radius: 20px;}
.meet_the_studios {padding: 0px 0;}
.meet_the_studios h2 {font-size: 42px;line-height: 42px;}
.meet_the_studios h2 img {width: 60px;}
.meet_the_studios p {padding: 0 5%;}
.logo_studios .studios_logo {margin: 20px 0 20px 0;width: 50%;}
.logo_studios .studios_logo img {height: 90px;}
.game_changers h2 img {width: 60px;}
.game_changers h2 {font-size: 36px;line-height: 64px;}
.game_changers p {padding: 0 5%;font-size: 17px;}

.home_destop{float: left; margin: 0; padding: 0; width: 100%; display: block;}
.home_mobile{float: left; margin: 0; padding: 0; width: 100%; display: block;}	
.banner .downloads2 {width: 100%;}
.banner .downloads2 .beating {left: 20%;}
.banner .downloads2 img.downloads_dots {left: 36%;}
.left_area .games_icon_row img {margin: 0 10px 10px 0;height: 65px;}
.right_area .games_icon_row_right img {margin: 0 0 10px 10px;height: 65px;}
.our_games .left_area {width: 50%;}
.our_games .right_area {margin: 0px 0 0 0;width: 50%;}
.content_growth_sand_box img {width: 150px;}
.content_growth_sand_box h3 {font-size: 48px;line-height: 48px;margin: 0 0 0 0;}
.fuel_growth p {font-size: 17px;line-height: 22px;margin: 0px 0 15px 0;padding: 0 5%;}
.fuel_growth ul.games_type {margin: 0 0 20px 5%;width: 90%;}
	
	
	
	
.our_games .center_area h1{font-size: 48px;line-height: 48px;}
.our_games .center_area h2{font-size: 48px;line-height: 18px;}
.our_games .center_area h3{font-size: 48px;line-height: 48px;}
.our_games .center_area h3 img{width: 48px;}
.our_games .icons a img{border: none;width: 38px; }
.our_games .center_area p{font-size: 16px;padding: 0 10%;line-height: 22px;}
.our_games .center_area ul.games_type li a {font-size: 12px;}	

.growth_sand_box {padding: 30px 0 30px 0;}	
.growthsandbox-icon img {width: 60px;margin: 0 15px 0 0;}	
.tools {margin: 0 2% 0 2%;width: 96%;border-radius: 30px;}	
.tools p {font-size: 17px;line-height: 24px;margin: 15px 0 30px 0;padding: 0 4%;}
.pixls {margin: 30px 2% 0 2%;width: 96%;border-radius: 30px;}
.gd_hub_content img{width: 180px;}
.pixls h3 {font-size: 31px;line-height: 31px;}
.gd_hub .map img.locations_img {margin: 0;}
.mission_line_full_top {margin: 0 2%;width: 96%;}
.message h2 img {width: 60px;margin: 0 0px 0 0;}
.message {margin: 0 5%;padding: 40px 0 0 0;width: 90%;}
.blog_area .home_blog_post {margin: 0 2%;width: 96%;}

.slider_img_content .item {margin: 0 2% 15px 2%;width: 96%;}
.slider_img_content .active {width: 96%;margin: 0 2%;}
	.slider_img_content .info {width: 100% !important;height: auto;position: relative;margin: 30px 0 0 0;}

	.slider_img_content .item img {max-width: 100%;margin: 0;}
	.slider_img_content .item img.pic_lead {margin: 0;}
	.slider_img_content .item:hover img.pic_lead_active {margin-left: 0;}
	.slider_img_content .info p {font-size: 17px;width: 100%;line-height: 22px;}
	.footer_area {padding: 30px 30px 30px 30px;}
	.footer_area .copy_right {margin: 30px 0 30px 0;}
	.footer_area .phone {margin: 50px 0 15px 0;}
	.contact_us {padding: 30px 3%;}
	.contact_us h1 {font-size: 42px;margin: 0 0 10px 0;}
img.boltmobile{display: block;float: right;width: 110px;position: relative;margin: -60px 0 0 0}
	.contact_us .sign_up {margin: 30px 0 0 0;}
	.bolt {display: none;}
	
	.destop_view{display: none !important;}
.mobile_view{display: block !important;}
.publish_employees{float: none;margin: 0 auto;padding: 0;width: 100%;max-width: 336px;}
	.gd_hub {margin: 20px 1% 0 1%;width: 98%;}
	
/* ABout Page */
	.banner_about h2 {font-size: 48px;letter-spacing: -1px;margin: 0 0 5px 0;}
	.banner_about h2 img.growing {margin: 0 0 0 5px;width: 45px;}
	.banner_about h2 span.star {top: -18px;margin-left: -7px;}
	.banner_about h2 span.star img {border: none;max-width: 36px;}
	.banner_about h1 {font-size: 48px;letter-spacing: -2px;line-height: 48px;}
	.banner_about h1 span.star {position: absolute;top: -30px;margin-left: 5px;}
	.banner_about h1 span.star img{width: 60px;}
	.banner_about h1 span.icon_about_title {position: absolute;top: 30px;margin-left: -15px;}
	.banner_about h1 span.icon_about_title img{width: 45px;}
	.about_banner img {max-width: 90%;}
	.banner_about {padding: 30px 0 0px 0;}
	.about_us {padding: 0 1%;}
	.about_us_col {padding: 20px 0 20px 0;}
	.about_us_col h2 img.about_us_icon_right {right: 5px;width: 60px;}
	.about_us_col h2 img.about_us_title_icon_left {position: relative;left: 10px;width: 70px;top: -9px;}
	.about_us_col h2 span {font-family: 'FivoSansModern-Heavy';font-size: 48px;}
	.about_us_col h2 img.about_us_title_icon_right {position: relative;left: -15px;top: -20px;width: 90px;}
	.about_us_col h2 {font-size: 48px;height: 140px;}
	.about_us_col p {font-size: 17px;margin: 0px 0 30px 0;padding: 0 5%;}
	.about_us_col h3 {font-size: 42px;margin: 0 0 30px 0;}
	.company_philosphy .box {margin: 10px 1%;padding: 20px;width: 100%;border-radius: 30px;min-height: auto;max-width: 31%;}
	.company_philosphy .bg2 {margin-top: 60px;}
	.company_philosphy .box .icon {margin: 0 0 20px 0;}
.company_philosphy .box .icon img {max-width: 60px;}
.company_philosphy .box h4 {font-size: 24px;}
.company_philosphy .box p {font-size: 15px;}
	.empowering_area {padding: 30px 0 0 0;}
	.empowering {padding: 0 1%;}
	.empowering_area h2 {font-size: 48px;margin: 0 0 30px 0;}
	.mission_line_full2 {top: -190px;}
	.empowering_area p {font-size: 17px;margin: 0px 0 20px 0;padding: 0 5%;}
	.empowering_area .gap {height: 0;}
	.empowering_area h2.our_values {font-size: 48px;line-height: 48px;margin: 0 0 20px 0;padding: 30px 30% 0 5%;}
	.our_values_box {margin: 0 1% 25px 1%;padding: 20px 20px 15px 20px;width: 98%;border-radius: 30px;min-height: auto;}
	.our_values_box .icon_number img {width: 60px;}
	.our_values_box h3 {font-size: 24px;margin: 0 0 10px 0;}
	.our_values_box p {font-size: 17px;padding: 0 !important;}
	.our_values_box:hover {-webkit-transform: scale(1,1);transform: scale(1,1);-webkit-transition: all 0.3s ease-in-out;}
	.senior_leadership {margin: 30px 1% 0 1%;padding: 20px;border-radius: 20px 20px;}
	.senior_leadership h2 img {right: 0px;width: 60px;}
	.senior_leadership h2 {font-size: 48px;line-height: 48px;padding: 25px 0 0 0;}
	.senior_leadership p {font-size: 17px;margin: 15px 0 20px 0;width: 100%;line-height: 22px;padding: 0;}
	.col-19 {width: 50%; padding: 0 5px;}
	.col-20 {width: 100%; padding: 0;}
	.leadership_info_1st {margin: 0 0 0 0;}
	.leadership_info_1st .leadership_info_1st_back p {font-size: 20px !important;}
	.leadership_info_2nd {margin: 0;}
	.leadership_info_2nd_back p {font-size: 20px !important;}
	.our_journey h2 {font-size: 31px;line-height: 34px;margin: 0 0 0px 0;padding: 20px 0 20px 0;}
	.game_changers {padding: 30px 0;}	
	
	
	/* Games Page */
	.banner_games h2 {font-size: 56px;letter-spacing: -3px;}
	.banner_games h2 img.games_icon_love {margin: 5px 0 0 5px;width: 50px;}
	.banner_games h3 {margin: -5px 0 0 0;font-size: 56px;letter-spacing: -3px;padding: 0 0 20px 0;}
	.banner_games h3 img.games_icon_title_left {margin: -8px 0 0 -36px;position: absolute; width: 70px;}
	.banner_games h3 img.games_icon_title_right {margin: 10px 0 0 -12px;width: 70px;}
	.slide {width: 70px;height: 78px;margin: 0 4px;}
	.logos_games {margin: 0 0 0 0;}
	
	.our_favorite_games h1 {font-size: 72px;margin: -50px 0 10px 5%;line-height: 72px}
	.our_favorite_games h1 a img {width: 44px;}
	.our_favorite_games h1 img.favoritegame-title-icon {width: 56px;}
	.our_favorite_games img.icon_our_favorite_games {margin: -30px 10px 0 0;width: 80px;}
	.our_favorite_games .content_area {width: 90%;}
	.our_favorite_games {padding: 50px 0 30px 0;}
	.our_awesome_studios {padding: 50px 0 30px 0;}
	.our_awesome_studios img.icon_our_awesome_studios {margin: -30px 10px 0 0;width: 80px;}
	.our_awesome_studios h1 {font-size: 72px;margin: -25px 0 0 5%;line-height: 72px;}
	.our_awesome_studios h1 img.awesome_studios_title-icon {margin: -15px 0 0 10px;width: 80px;}
	.our_awesome_studios .content_area {width: 90%;}
	
	.answer .studios_info .store_links a {margin: 0 10px 20px 10px;padding: 16px 27px;}
	.games_icons_row img {margin: 0 0 10px 10px;width: 90px;}
	ul.accordion-list li {padding: 40px 75px;}

	.our_awesome_studios img.studios_icon_center {width: 200px;height: 200px;left: 33%;top: 200px;}
	.sections .our_studios_logo {width: 274px;height: 274px;}
	.flex-center {height: 294px;}
	
		
	/* gdhub Page */
	
	.banner_gd_hub h2 {font-size: 56px;letter-spacing: -2px;}
	.banner_gd_hub h2 img.icon_gd_hub_left {margin: 0 0 0 0;width: 48px;}
	.banner_gd_hub h1 {font-size: 56px;letter-spacing: -2px;line-height: 56px;}
	.banner_gd_hub h2 span.star {top: -20px;margin-left: 0;}
	.banner_gd_hub h2 span.star img{ width: 48px;}
	.banner_gd_hub h1 span.icon_about_title {top: 30px;margin-left: -39px;}
	.banner_gd_hub h1 span.icon_about_title img {width: 48px;}
	.gd_hub_empowering_studios {padding: 0 2%;}
	.gd_hub_empowering_studios_area {padding: 30px 0 30px 0;}
	.gd_hub_empowering_studios_info {margin: 0 0 0 0; text-align: center;}
	
	.gd_hub_empowering_studios_info img{border: none;max-width: 100%;width: 250px;}
	.gd_hub_empowering_studios_info h3 {font-size: 56px;margin: 30px 0 0px 5%;letter-spacing: -3px;padding: 0 46% 0 0;}
	.gd_hub_empowering_studios_details {margin: 20px 0 0 0;padding: 0 5%;}
	.gd_hub_info_area {margin: 20px 1% 20px 1%;padding: 30px 0 30px 0;}
	.gd_hub_info_area h2 {font-size: 56px;line-height: 56px;float: left;margin: 0 0 20px 0;letter-spacing: -1px;padding: 0 0;}
	.gd_hub_info_area p {font-size: 20px;padding: 0 5%;}
	.gd_hub_info_area p.inner {font-size: 20px;padding: 0 5%;}
	.gd_hub_values_box:hover {-webkit-transform: scale(1.0,1.0);transform: scale(1.0,1.0);-webkit-transition: all 0.3s ease-in-out;}
	.gd_hub_values_box {margin: 0 2% 25px 2%;padding: 20px 20px 20px 20px;width: 96%;border-radius: 30px;min-height: auto;}
	.gd_hub_values_box .icon_number img {width: 60px;}
	.gd_hub_values_box h3 {font-size: 24px;margin: 0 0 15px 0;padding: 0 50px 0 0;}
	.gd_hub_info_area .gd_hub_values_box p {font-size: 17px;padding: 0 0;}
	.gd_hub_values_box_area h2.our_values {font-size: 56px;line-height: 56px;letter-spacing: 0px;padding: 10px 5% 0 5%;}
	.games_are_played h2 {font-size: 64px;line-height: 64px;margin: 0 0 20px 0;letter-spacing: 0px;padding: 0 20% 0 5%;}
	.games_are_played {padding: 40px 0 40px 0;}
	.games_are_played h2 img.games_are_played_icon {right: 25%;top: 47px;width: 64px;}
	.games_are_played p {font-size: 20px;padding: 0 5%;}
	.games_are_played_info {padding: 30px 0 0 0;}
	
	
	
	/* IP Page */
	.ip_banner {background-position: left top;background-size: 100%;}
	.banner_ip h2 {font-size: 48px;}
	.banner_ip h2 img.star_ip {margin: -6px 0 0 -3px;width: 24px;}
	.banner_ip h3 {margin: 10px 0 30px 0;font-size: 48px;}
	.banner_ip {background-position: right 26px;background-size: 110px;padding: 50px 0 50px 0;}
	.banner_ip_top {background-position: 0px 30px;background-size: 100px;}
	.pixels_code {padding: 30px 0 30px 0;}
	.pixels_code h2 {font-size: 31px;margin: 0px 0 0 0;padding: 0 2%;line-height: 34px;}
	.content__img-inner {background-size: cover;
  background-position: 50% 50%;}
	.craft_worlds {margin: 0 2%;width: 96%;}
	.craft_worlds h3 {font-size: 42px;letter-spacing: -2px;margin: 0px 0 10px 0;}
.craft_worlds p {font-size: 20px;line-height: 27px;}	
	
	.craft_worlds_img img.parallax-image {margin: 0 5% 0 0;}
	.craft_worlds_img {min-height: auto;}
.gameverse_monetize img.forge_your_gameverse_icon {width: 60px;position: absolute;right: 10px;}
	.gameverse_monetize {padding: 30px 0 30px 0;}
	.gameverse_monetize_img img.parallax-image {margin: 0px 10% 0 0;}
	
	.monetize_game_img img.parallax-image {margin: 0px 2% 0 0;}
	.monetize_game_img {min-height: auto;}
	.gameverse_monetize_img {min-height: auto;width: 96%;margin: 40px 2% 0 2%;}
	.gameverse_monetize h3 {font-size: 42px;letter-spacing: -1px;margin: 30px 0 10px 0;}
	.gameverse_monetize p {font-size: 20px;line-height: 27px;}
	.btn_dream_game a {padding: 16px 32px;}
	.gameverse_monetize img.level_up_icon {float: right;margin: 0 10px 0 0px;width: 60px;}
	.gameverse_monetize_content_left {margin: 0 2%;width: 96%;}
	.level_up_game_img img.parallax-image {margin: 0px 2% 0 0;}
	.level_up_game_img {min-height: auto;}
	.roadmap {padding: 30px 0 30px 0;}
	.roadmap img.roadmap_icon {width: 60px;position: absolute;right: 10px;}
	
	.roadmap h2 {font-size: 48px;margin: 40px 0 0 0;padding: 0 0 0 20px;line-height: 48px;}
	.roadmap .roadmap_info {margin: 0 2%;padding: 20px 0 0 0;width: 96%;}
	.roadmap_info p {font-size: 20px;line-height: 27px;}
	.roadmap_services:hover {-webkit-transform: scale(1.0,1.0);transform: scale(1.0,1.0);}
	.roadmap_services {margin: 30px 2% 0 2%;padding: 20px;width: 96%;}
	.roadmap_services .icon_number {font-size: 24px;width: 60px;height: 60px;line-height: 60px;}
	.roadmap_services h3 {margin: 15px 0 10px 0;font-size: 27px;}
	.roadmap_services h3 span {font-size: 20px;}
	.roadmap_services p {font-size: 20px;}
	.roadmap_services ul li {font-size: 20px;margin: 30px 0 0px 15px;}
	
	.trusted_partner {padding: 40px 0 0 0;}
	.trusted_partner p {font-size: 20px;padding: 0 5%;line-height: 24px;}
	.ip_content {padding: 60px 0 0 10px;}
	.ip_content_image {min-height: auto;}
	.ip_content_image img.parallax-image {margin: 0px 8% 0 0;}	
	
		/* Tools Page */
	.banner_tool h2 {font-size: 56px;letter-spacing: -3px;}
	.banner_tool h5 {margin: 5px 0 30px 0;font-size: 25px;letter-spacing: -1px;}
	.banner_tool h2 img.tool_growing {margin: 0px 0 0 80px;width: 36px;}
	.banner_tool h2 span.star {top: -21px;margin: 0 0 0 -8px;}
	.banner_tool h2 span.star img{width: 36px;}
	.gid_applifier_links a {float: left;margin: 0 0 10px 6%;width: 40%;font-size: 13px;padding: 20px 40px;}
	.gid_applifier_links a img {top: 0;}
	.tools_banner {margin: 0 0 40px 0;}
	.brandanimationcolor .marquee--inner {width: 440%;}
	.brandanimationcolor span {font-size: 18px;}
	.brandanimationcolor span img {margin: 0px 40px 0 40px;width: 20px;}
	.brandanimationcolor .marquee {height: 20px;}
	
	.brandanimation span {font-size: 18px;}
	.brandanimation span img{margin: 0px 40px 0 40px;width: 20px;}
	.brandanimation .marquee{height: 20px;}
.brandanimationcolor {margin: -32px 0 0 0;}
.brandanimation {transform: rotate(2deg);}
.insights_impact h2 {font-size: 56px;letter-spacing: -3px;}
.insights_impact h2 img {width: 50px;}

	.insights_impact h3 {font-size: 31px;letter-spacing: -2px;margin: 20px 0 10px 0;padding: 0 5%;}
	.insights_impact p {padding: 0 5%;}
	.applifier {padding: 40px 0 0 0;}
	.applifier h2 {font-size: 34px;letter-spacing: -1px;margin: 0 0 20px 0;}
	.applifier p {margin: 0px 0 30px 0;padding: 0 5%;}
	.applifier_info {width: 90%;margin: 0 0 50px 0;}
	.applifier_info h3 {font-size: 38px;}
	.applifier .applifier_info p { margin: 0;padding: 0;}
	
	.bidding_system:hover {-webkit-transform: scale(1.0,1.0);transform: scale(1.0,1.0);}
	.bidding_system {margin: 0 2% 20px 2%;padding: 30px 30px 30px 30px;width: 96%;}
	.bidding_system img {float: left;max-width: 100%;}
	.bidding_system h3 {font-size: 36px;width: 100%;}
	.applifier .bidding_system p{margin: 0;padding: 0;width: 100%;}
	
	.advanced_analytics:hover {-webkit-transform: scale(1.0,1.0);transform: scale(1.0,1.0);}
  .advanced_analytics {margin: 0 2%;padding: 30px 30px 30px 30px;width: 96%;}
	.advanced_analytics img {max-width: 100%;}
	.advanced_analytics h3 {font-size: 36px;width: 100%;}
	.applifier .advanced_analytics p{ margin: 0;padding: 0; width: 100%;}
	
	.applifier_system_info:hover{-webkit-transform: scale(1.0,1.0);transform: scale(1.0,1.0);}
	.applifier_system_info {margin: 20px 2%;width: 96%;min-height: auto;}
	.applifier_system_info .icon_system_info img {width: 60px;}
	.applifier_system_info h3 {font-size: 27px;width: 100%;}
	
	.applifier .applifier_system_info p{margin: 0px 0 0px 0;padding: 0 0%;}
	.applifier .go_beyond h2{font-size: 36px;letter-spacing: -1px;margin: 0;}
	.go_beyond h3 {font-size: 31px}
	.we_help {margin: 0 2% 20px 2%;padding: 30px 30px 30px 30px;width: 96%;}
	
	.we_help h3 {font-size: 42px;letter-spacing: -1px;}
	.we_help h4 {font-size: 56px;letter-spacing: -3px;margin: 10px 0 0px 0;line-height: 56px;}
	
	.gdi_area h2 {font-size: 42px;letter-spacing: -1px;line-height: 42px;}
	.gdi_area h4 {font-size: 36px;margin: 0px 0 10px 0;line-height: 42px;}
	.gdi_area p {margin: 0px 0 30px 0;padding: 0 5%;}
	
	.gdi_info {margin: 0 0 50px 0;padding: 0 0 0 5%;width: 90%;}
	.gdi_info h3 {font-size: 36px;}
	.gdi_area .gdi_info p { margin: 0;padding: 0;}
	
	.monetization_mastermind:hover {-webkit-transform: scale(1.0,1.0);transform: scale(1.0,1.0);}
	.monetization_mastermind {margin: 30px 2% 0 2%;padding: 30px;width: 96%;}
	.monetization_mastermind img {max-width: 100%;}
	.monetization_mastermind h3  {font-size: 29px;width: 100%;}
	.gdi_area .monetization_mastermind p{ font-size: 17px;line-height: 24px;margin: 0;padding: 0; width: 100%;}
	
	.benchmark:hover {-webkit-transform: scale(1.0,1.0);transform: scale(1.0,1.0);}
	.benchmark {margin: 30px 2% 0 2%;padding: 30px;width: 96%;}
	.benchmark h3  {font-size: 29px;width: 100%;}
	.gdi_area .benchmark p{ font-size: 17px;line-height: 24px;margin: 0;padding: 0; width: 100%;}
	.benchmark img {max-width: 100%;}
	
	.gdi_system_info:hover{-webkit-transform: scale(1.0,1.0);transform: scale(1.0,1.0);}
	.gdi_system_info {margin: 30px 2% 0 2%;padding: 20px 20px 30px 20px;width: 96%;min-height: auto;}
	.gdi_system_info .icon_system_info img {width: 60px;}
	.gdi_system_info h3{font-size: 31px;width: 100%;}
	.gdi_area .gdi_system_info p{font-size: 17px;line-height: 24px;margin: 0px 0 0px 0;padding: 0 0;width: 100%;}
	.gdi_area .go_beyond  h2{font-size: 42px;letter-spacing: -1px;line-height: 42px;padding: 0 5%;}
	
	.we_help_gdi {margin: 0 2% 20px 2%;padding: 30px 30px 30px 30px;width: 96%;}
	
	.we_help_gdi h3 {font-size: 42px;letter-spacing: -1px;}
	.we_help_gdi h4 {font-size: 56px;letter-spacing: -3px;margin: 10px 0 0px 0;line-height: 56px;}
	
	/* Pixls Page */
	
	.banner_programs .banner_programs_top {display: none;}
	.banner_programs img.banner_programs_top_img{width: 96%; margin: 0 2%; display: block;}
	.just_play2 {margin: 30px 0 30px 0;}
	.right_text {top: 100px;}
	.social_icons_banner {top: 100px;}
	.banner_programs_bottom img.banner_programs_bottom_img{width: 96%; margin: 0 2%; display: block;}
	.banner_programs_bottom .banner_programs_row, .banner_programs_bottom .banner_programs_row_center, .banner_programs_bottom .banner_programs_row_bottom  {display: none;}
	.banner_programs {padding: 50px 0 50px 0;}
	.pixls_program {padding: 0px 2%;width: 100%;}
	.pixls_program_content {padding: 0 0px 30px 0;}
	.pixls_program_animation2 {margin: 0;}
	.pixls_program_info p {font-size: 17px;line-height: 24px;margin: 0px 0 0 0;}
	.pixls_program_animation2 .marquee--inner {width: 480%;}
	.pixls_program_animation2 span img {margin: 5px 50px 0 50px;}
	.pixls_program_content_icon .pixls_icon1 {width: 80%;}
	.pixls_program_content_icon .pixls_icon2 {top: -30px;left: 13%;}
	.program_vision_area {padding: 0 0px;}
	.program_vision_title {margin: 60px 2% 0 2%;padding: 0px 0px;width: 96%;}
	.program_vision_title h1 {font-size: 78px;line-height: 90px;}
	.program_vision_title h1 img {margin: -65px 0 0 179px;width: 36px;}
	.program_vision_title img.program_vision_map {margin: 0 auto;float: none;width: 160px;display: block;}
	.program_vision_info {margin: 20px 0 0 2%;padding: 0px 0px 0 20px;width: 96%;}
	.program_vision_info p {font-size: 21px;line-height: 27px;margin: 20px 0 0 0;}
	.program_vision_info p .program_vision_info_img_1 img {width: 100px;height: auto;top: 10px;}
	.program_vision_info p .program_vision_info_img_2 img {width: 148px;height: auto;top: 6px;}
	.program_vision_info p .program_vision_info_img_3 img {width: 165px;height: auto;top: 5px;}
	.program_vision_info p .program_vision_info_img_4 img {width: 190px;height: auto;top: -8px;left: 0;}
	.program_vision_info p .program_vision_info_img_6 img {width: 62px;height: auto;top: 4px;}
	.program_vision_info p .program_vision_info_img_5 img {width: 150px;height: auto;top: 6px;}
	.program_plan {padding: 30px 0 30px 0;margin: 30px 1% 0px 1%;}
	.program_plan_title {padding: 0px 2%;}
	.program_plan_title h1 {font-size: 88px;line-height: 90px;}
	.program_plan_title h1 img.program_plan_title_icon {width: 39px;top: -8px;left: -59px;}
	.program_plan_title h1 img.program_plan_title_icon2 {position: absolute;width: 80px;left: 63%;top: 30px;}
	.program_plan_title img.program_plan_img {margin: -28px 0 0 20px;max-width: 86%;}
	.program_plan_info {width: 100%;}
	.program_plan_info .program_plan_details {min-height: auto;}
	.our_partners {float: left;margin: 20px 1% 0 1%;padding: 30px 0px;}
	.our_partners h1 {font-size: 26px;margin: 0 0 20px 0;}
	.our_partners h1 img.our_partners_icon{width: 22px;}
.achievements {padding: 30px 0px 0 0;}
.achievements h2 {font-size: 120px;width: 100%;margin: 40px 0 0px 0;line-height: 110px;}
.achievements h2 span.margin_left {margin-left: 60px !important;}
.achievements h2 span.margin_left2 {margin-left: 146px !important;}
.achievements img.achievements_star {margin: -30px 0 0 60px;width: auto;}
.future_plans img.future_plans_img {width: 92%;margin: 0 4%;}
.future_plans {padding: 30px 0 30px 0;}
.future_plans p {font-size: 17px;margin: 20px 0 20px 0;padding: 0 5%;line-height: 26px;}
	.future_plans_info img.fadein_bottom_23{ width: 70%}
	.future_plans_info img.future_plans_icon_right {right: 10px;top: 0;width: 36px;}
	.future_plans_info img.future_plans_icon_left {left: 10px;width: 40px;}
	.future_plans .future_plans_info {padding: 40px 0 30px 0;}
	.achievements_img {margin: 40px 0 0 0;}
	
	.contact_us_programs {padding: 20px 0;}
	.contact_us_programs h1{font-size: 66px;letter-spacing: -1px;line-height: 66px;padding: 0 0% 0 0;width: 70%;}
	.flip span {font-size: 66px;letter-spacing: -1px;line-height: 66px;}
	.hardworking p {margin: 0 0 10px 0;padding: 0 0 0 5%;}
	.contact_us_programs h2 {font-size: 66px;letter-spacing: -1px;line-height: 66px;width: 70%;}
	.contact_us_programs h2 img {width: 50px;margin: 20px 0 0 0;}
	.flip {height: 66px;width: 70%;}
	.contact_us_programs h1 span.righttext {padding: 0px 0 10px 0;font-size: 12px;line-height: 14px;}
	.contact_us_programs h3 {font-size: 66px;letter-spacing: -1px;line-height: 66px;width: 70%;margin: -15px 0 0 0;}
	.just_make_games {margin: 0 0 0 0;}
	.contact_us_programs img.contact_us_programs_bolt{display: block;width: 20%;float: right;}
.contactinfo {float: left;margin: 20px 0 0 0;padding: 0 5%;}
.contactus_programs_info .sign_up {float: left;margin: 40px 0 0 0;}
.footer_area_programs {padding: 30px 30px;}
	.footer_area_programs .email {font-size: 36px;margin: 0 0 0px 0;padding: 0 0 0 0;width: 100%;}
	.footer_area_programs .address {font-size: 20px;margin: 10px 0 20px 0;padding: 0 35% 0 0;}
	.footer_area_programs .social_icons {padding: 0 0 0 0;}
	.footer_area_programs ul.footer_menu {padding: 0 0 0 0%;}
	
	
	
		/* Hackathon Page */
	
	.programs_hackathon_top_area {padding: 10px 0 0 0;background-size: 130%;}
	.together_towards_tomorrow {padding: 0px 0 40px 0;}
	.together_towards_tomorrow_top {padding: 0px 0 0 0;}
	.together_towards_tomorrow_top img.be_bold_make_history {width: 130px;}
	.together_towards_tomorrow_top img.together_towards_tomorrow_icon_right_top {margin: 10px 4px 0 0;width: 50px;}
	.together_towards_tomorrow_top img.together_towards_tomorrow2 {width: 90%;margin: -20px 0 0 5%;}
	.together_towards_tomorrow_top img.together_towards_tomorrow_icon_right_bottom {margin: 0 0 0 0;width: 90px;}
	.just_play_together_towards_tomorrow {margin: 10px 0 0 0;}
	.together_towards_tomorrow h2 {font-size: 42px;margin: 40px 0 20px 0;padding: 0 0 0 3%;}
	.together_towards_tomorrow p {font-size: 21px;padding: 0 0 0 3%;width: 100%;}
	.together_towards_tomorrow h2 img {top: 5px;left: 5px;width: 100px;}

	.together_towards_tomorrow img.why_hackathon_right_icon_bg {margin: 60px 20px 0 0;width: 180px;}
	.together_towards_tomorrow img.why_hackathon_right_icon {right: 80px;top: 20px;width: 190px;}
	.together_towards_tomorrow_border_bottom .marquee {height: 24px;}
	.together_towards_tomorrow_border_bottom .marquee--inner {width: 600%;}
	.hackathon_program {padding: 30px 0 60px 0;}
	.hackathon_program h2 {font-size: 31px;}
	.hackathon_program h2 img {top: 0px;left: 5px;width: 140px;}
	.hackathon_program p {font-size: 17px;margin: 0 0 20px 0;}
	.phase_area {padding: 0 20px;width: auto;}
	.phase_area h3 {font-size: 19px;margin: 20px 0 20px 0;padding: 15px 20px;border-radius: 30px;}
	.phase_area h3 span {font-size: 14px;padding: 2px 16px;line-height: 20px;}
	.phase_area_days {width: 96%;top: 100px;}
	.phase_area_days2 {margin: 130px 2% 0 2%;width: 96%;}
	.phase_area_days h3 {font-size: 15px;margin: 0px 0 10px 0;padding: 10px 20px;width: 100%;border-radius: 20px;}
	.phase_area_days2 h3 {font-size: 15px;margin: 0px 0 10px 0;padding: 10px 20px;width: 100%;border-radius: 20px;}
.together_towards_tomorrow_border_bottom img {max-width: 200%;margin: 0 0 0 -50%;}
.journey_of_game_district {padding: 30px 0 30px 0;}
	.journey_of_game_district h2 img{width: 150px}
.journey_of_game_district h2 span {font-size: 48px;margin: 0 0px;}
.journey_of_game_district p {font-size: 17px;margin: 20px 0 40px 0;padding: 0 7%;}
.Journey_arrow {padding: 15px 0;width: 100%;text-align: center;}
.Journey_1 {margin: 0 auto;width: 278px;float: none;}	
	.Journey_2 {margin: 0 auto;float: none;width: 312px}
	.Journey_3 {margin: 0 auto;float: none;width: 318px}
	.Journey_4{margin: 0 auto;float: none;width: 340px;padding: 20px 0 0 0;}
	.Journey_5 {margin: 0 auto;float: none;width: 284px}
	.Journey_arrow2 {margin: 0px 0 0 0;padding: 20px 0;float: left;width: 100%;text-align: center;}
	

	
	
	.achievements_programs h2 {font-size: 42px;text-align: center;}
	.achievements_programs h2 img {width: 100%;}
	
	.game_scaled_globally:hover {-webkit-transform: scale(1,1);transform: scale(1,1);}
	.game_scaled_globally2 .eight_games:hover {-webkit-transform: scale(1,1);transform: scale(1,1);}
	.game_scaled_globally2 .eight_games_icon:hover {-webkit-transform: scale(1,1);transform: scale(1,1);}
	.retention_marked:hover {-webkit-transform: scale(1,1);transform: scale(1,1);}
	.million_game_downloads:hover {-webkit-transform: scale(1,1);transform: scale(1,1);}
	.prototypes:hover {-webkit-transform: scale(1,1);transform: scale(1,1);}
	.gap_img:hover {-webkit-transform: scale(1,1);transform: scale(1,1);}
	.hit_games:hover {-webkit-transform: scale(1,1);transform: scale(1,1);}
	.achievements_programs {padding: 30px 2% 30px 2%;border-radius: 0 0 30px 30px;}
	.million_game_downloads h3 {padding:40px 0 0 0; }
	.million_game_downloads {margin: 30px 0;}
	.successful_games {padding: 30px 0 30px 0;}
	.successful_games h2 span {font-size: 42px;margin: 0 15px;float: left;}
	.successful_games h2 img{width: 150px;float: left;margin: 21px 0 0 5px; }
	.successful_games p {font-size: 21px;margin: 10px 0 0 0;}
	.margin_top_games {margin-top: 0px !important;}
	.successful_games_info {margin: 15px 2% 15px 2% !important;;width: 96%;}
.successful_games h2 {padding: 0 6%;}
	.gallery_gd_area {width: 98%;}
	 .gallery_gd_row {width: 100%;margin: 0;max-width:100%;}
	.gallery_gd_row img.gallery_1 {margin: 0 0 15px 0;width: 100%;height: auto;}
	.gallery_gd_row img.gallery_2 {margin: 0 0 15px 0;width: 100%;height: auto}
	.gallery_gd_row img.gallery_3 {margin: 0 0 15px 0px;width: 100%;height: auto}
	.gallery_gd_row img.gallery_4 {margin: 0 0 15px 0;width: 100%;height: auto}
	
	.gallery_gd_row2 {width: 100%;max-width: 100%;margin: 0 0 15px 0;}
	 .gallery_gd_row2 img {height: auto;margin: 0;width: 100%;height: auto}
	.gallery_game_district {margin: 15px 1% 0 1%;padding: 30px 0 20px 0;}
	
	
	
	
	
	
	
}

