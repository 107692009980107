.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    max-width: 750px;
	  width: 100%;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 960px;
	  width: 100%;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1180px;
	  width: 100%;
	  
  }
}
@media (min-width: 1366px) {
  .container {
    max-width: 1280px;
	  width: 100%;
	  
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1280px;
	  width: 100%;
	  
  }

}


.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}


.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-19,.col-20 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-19, .col-20  {
  float: left;
}
.col-12 {
  width: 100%;
}
.col-11 {
  width: 91.66666667%;
}
.col-10 {
  width: 83.33333333%;
}
.col-9 {
  width: 75%;
}
.col-8 {
  width: 66.66666667%;
}
.col-7 {
  width: 58.33333333%;
}
.col-6 {
  width: 50%;
}
.col-5 {
  width: 41.66666667%;
}
.col-4 {
  width: 33.33333333%;
}
.col-3 {
  width: 25%;
}
.col-2 {
  width: 16.66666667%;
}
.col-1 {
  width: 8.33333333%;
}
.col-19 {
  width: 18%;
}
.col-20 {
  width: 28%;
}

