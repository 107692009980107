* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
@font-face {
  font-family: "Metropolis-ExtraLight";
  src: url("../fonts/Metropolis-ExtraLight.eot");
  src: url("../fonts/Metropolis-ExtraLight.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Metropolis-ExtraLight.woff2") format("woff2"),
    url("../fonts/Metropolis-ExtraLight.woff") format("woff"),
    url("../fonts/Metropolis-ExtraLight.ttf") format("truetype"),
    url("../fonts/Metropolis-ExtraLight.svg#Metropolis-ExtraLight")
      format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis-Black";
  src: url("../fonts/Metropolis-Black.eot");
  src: url("../fonts/Metropolis-Black.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Metropolis-Black.woff2") format("woff2"),
    url("../fonts/Metropolis-Black.woff") format("woff"),
    url("../fonts/Metropolis-Black.ttf") format("truetype"),
    url("../fonts/Metropolis-Black.svg#Metropolis-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis-Bold";
  src: url("../fonts/Metropolis-Bold.eot");
  src: url("../fonts/Metropolis-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Metropolis-Bold.woff2") format("woff2"),
    url("../fonts/Metropolis-Bold.woff") format("woff"),
    url("../fonts/Metropolis-Bold.ttf") format("truetype"),
    url("../fonts/Metropolis-Bold.svg#Metropolis-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis-SemiBold";
  src: url("../fonts/Metropolis-SemiBold.eot");
  src: url("../fonts/Metropolis-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Metropolis-SemiBold.woff2") format("woff2"),
    url("../fonts/Metropolis-SemiBold.woff") format("woff"),
    url("../fonts/Metropolis-SemiBold.ttf") format("truetype"),
    url("../fonts/Metropolis-SemiBold.svg#Metropolis-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis-Medium";
  src: url("../fonts/Metropolis-Medium.eot");
  src: url("../fonts/Metropolis-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Metropolis-Medium.woff2") format("woff2"),
    url("../fonts/Metropolis-Medium.woff") format("woff"),
    url("../fonts/Metropolis-Medium.ttf") format("truetype"),
    url("../fonts/Metropolis-Medium.svg#Metropolis-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis-Regular";
  src: url("../fonts/Metropolis-Regular.eot");
  src: url("../fonts/Metropolis-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Metropolis-Regular.woff2") format("woff2"),
    url("../fonts/Metropolis-Regular.woff") format("woff"),
    url("../fonts/Metropolis-Regular.ttf") format("truetype"),
    url("../fonts/Metropolis-Regular.svg#Metropolis-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis-ExtraBold";
  src: url("../fonts/Metropolis-ExtraBold.eot");
  src: url("../fonts/Metropolis-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Metropolis-ExtraBold.woff2") format("woff2"),
    url("../fonts/Metropolis-ExtraBold.woff") format("woff"),
    url("../fonts/Metropolis-ExtraBold.ttf") format("truetype"),
    url("../fonts/Metropolis-ExtraBold.svg#Metropolis-ExtraBold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham-Bold";
  src: url("../fonts/Gotham-Bold.eot");
  src: url("../fonts/Gotham-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Gotham-Bold.woff2") format("woff2"),
    url("../fonts/Gotham-Bold.woff") format("woff"),
    url("../fonts/Gotham-Bold.ttf") format("truetype"),
    url("../fonts/Gotham-Bold.svg#Gotham-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GothamMedium";
  src: url("../fonts/GothamMedium.eot");
  src: url("../fonts/GothamMedium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/GothamMedium.woff2") format("woff2"),
    url("../fonts/GothamMedium.woff") format("woff"),
    url("../fonts/GothamMedium.ttf") format("truetype"),
    url("../fonts/GothamMedium.svg#GothamMedium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GothamBlack";
  src: url("../fonts/GothamBlack.eot");
  src: url("../fonts/GothamBlack.eot?#iefix") format("embedded-opentype"),
    url("../fonts/GothamBlack.woff2") format("woff2"),
    url("../fonts/GothamBlack.woff") format("woff"),
    url("../fonts/GothamBlack.ttf") format("truetype"),
    url("../fonts/GothamBlack.svg#GothamBlack") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FivoSansModern-Heavy";
  src: url("../fonts/FivoSansModern-Heavy.eot");
  src: url("../fonts/FivoSansModern-Heavy.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/FivoSansModern-Heavy.woff2") format("woff2"),
    url("../fonts/FivoSansModern-Heavy.woff") format("woff"),
    url("../fonts/FivoSansModern-Heavy.ttf") format("truetype"),
    url("../fonts/FivoSansModern-Heavy.svg#FivoSansModern-Heavy") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "FivoSansModern-Medium";
  src: url("../fonts/FivoSansModern-Medium.eot");
  src: url("../fonts/FivoSansModern-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/FivoSansModern-Medium.woff2") format("woff2"),
    url("../fonts/FivoSansModern-Medium.woff") format("woff"),
    url("../fonts/FivoSansModern-Medium.ttf") format("truetype"),
    url("../fonts/FivoSansModern-Medium.svg#FivoSansModern-Medium")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FivoSansModern-Regular";
  src: url("../fonts/FivoSansModern-Regular.eot");
  src: url("../fonts/FivoSansModern-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/FivoSansModern-Regular.woff2") format("woff2"),
    url("../fonts/FivoSansModern-Regular.woff") format("woff"),
    url("../fonts/FivoSansModern-Regular.ttf") format("truetype"),
    url("../fonts/FivoSansModern-Regular.svg#FivoSansModern-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FivoSansModern-Bold";
  src: url("../fonts/FivoSansModern-Bold.eot");
  src: url("../fonts/FivoSansModern-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/FivoSansModern-Bold.woff2") format("woff2"),
    url("../fonts/FivoSansModern-Bold.woff") format("woff"),
    url("../fonts/FivoSansModern-Bold.ttf") format("truetype"),
    url("../fonts/FivoSansModern-Bold.svg#FivoSansModern-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sora-ExtraLight";
  src: url("../fonts/Sora-ExtraLight.eot");
  src: url("../fonts/FivoSansModern-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Sora-ExtraLight.woff2") format("woff2"),
    url("../fonts/Sora-ExtraLight.woff") format("woff"),
    url("../fonts/Sora-ExtraLight.ttf") format("truetype"),
    url("../fonts/Sora-ExtraLight.svg#FivoSansModern-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Sora-Light";
  src: url("../fonts/Sora-Light.eot");
  src: url("../fonts/FivoSansModern-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Sora-Light.woff2") format("woff2"),
    url("../fonts/Sora-Light.woff") format("woff"),
    url("../fonts/Sora-Light.ttf") format("truetype"),
    url("../fonts/Sora-Light.svg#FivoSansModern-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Sora-Regular";
  src: url("../fonts/Sora-Regular.eot");
  src: url("../fonts/Sora-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Sora-Regular.woff2") format("woff2"),
    url("../fonts/Sora-Regular.woff") format("woff"),
    url("../fonts/Sora-Regular.ttf") format("truetype"),
    url("../fonts/Sora-Regular.svg#FivoSansModern-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Sora-Bold";
  src: url("../fonts/Sora-Bold.eot");
  src: url("../fonts/Sora-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Sora-Bold.woff2") format("woff2"),
    url("../fonts/Sora-Bold.woff") format("woff"),
    url("../fonts/Sora-Bold.ttf") format("truetype"),
    url("../fonts/Sora-Bold.svg#FivoSansModern-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Sora-ExtraBold";
  src: url("../fonts/Sora-ExtraBold.eot");
  src: url("../fonts/Sora-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Sora-ExtraBold.woff2") format("woff2"),
    url("../fonts/Sora-ExtraBold.woff") format("woff"),
    url("../fonts/Sora-ExtraBold.ttf") format("truetype"),
    url("../fonts/Sora-ExtraBold.svg#FivoSansModern-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sora-SemiBold";
  src: url("../fonts/Sora-SemiBold.eot");
  src: url("../fonts/Sora-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Sora-SemiBold.woff2") format("woff2"),
    url("../fonts/Sora-SemiBold.woff") format("woff"),
    url("../fonts/Sora-SemiBold.ttf") format("truetype"),
    url("../fonts/Sora-SemiBold.svg#FivoSansModern-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Sora-Thin";
  src: url("../fonts/Sora-Thin.eot");
  src: url("../fonts/Sora-Thin.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Sora-Thin.woff2") format("woff2"),
    url("../fonts/Sora-Thin.woff") format("woff"),
    url("../fonts/Sora-Thin.ttf") format("truetype"),
    url("../fonts/Sora-Thin.svg#FivoSansModern-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Sora-Medium";
  src: url("../fonts/Sora-Medium.eot");
  src: url("../fonts/Sora-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Sora-Medium.woff2") format("woff2"),
    url("../fonts/Sora-Medium.woff") format("woff"),
    url("../fonts/Sora-Medium.ttf") format("truetype"),
    url("../fonts/Sora-Medium.svg#FivoSansModern-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Mont Bold";
  src: url("../fonts/Mont-Bold.eot");
  src: url("../fonts/Mont-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Mont-Bold.woff2") format("woff2"),
    url("../fonts/Mont-Bold.woff") format("woff"),
    url("../fonts/Mont-Bold.ttf") format("truetype"),
    url("../fonts/Mont-Bold.svg#Mont-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mont Book";
  src: url("../fonts/Mont-Book.eot");
  src: url("../fonts/Mont-Book.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Mont-Book.woff2") format("woff2"),
    url("../fonts/Mont-Book.woff") format("woff"),
    url("../fonts/Mont-Book.ttf") format("truetype"),
    url("../fonts/Mont-Book.svg#Mont-Book") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mont Regular";
  src: url("../fonts/Mont-Regular.eot");
  src: url("../fonts/Mont-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Mont-Regular.woff2") format("woff2"),
    url("../fonts/Mont-Regular.woff") format("woff"),
    url("../fonts/Mont-Regular.ttf") format("truetype"),
    url("../fonts/Mont-Regular.svg#Mont-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mont SemiBold";
  src: url("../fonts/Mont-SemiBold.eot");
  src: url("../fonts/Mont-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Mont-SemiBold.woff2") format("woff2"),
    url("../fonts/Mont-SemiBold.woff") format("woff"),
    url("../fonts/Mont-SemiBold.ttf") format("truetype"),
    url("../fonts/Mont-SemiBold.svg#Mont-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

html {
  background-color: #1d1d1d;
  margin: 0;
  padding: 0;
  width: 100%;
  float: left;
}
body {
  background-color: #1d1d1d;
  margin: 0;
  padding: 0px;
  width: 100%;
  float: left;
}
.clr {
  clear: both;
}
img {
  border: none;
  max-width: 100%;
}
a {
  text-decoration: none;
  outline: none;
}
.destop_view {
  display: block !important;
}
.mobile_view {
  display: none !important;
}
.top_home_area {
  background-color: #ffffff;
  margin: 1% 1% 0px 1%;
  padding: 40px 0 0 0;
  width: 98%;
  float: left;
  border-radius: 30px 30px 0 0;
}
.header {
  margin: 0;
  padding: 0;
  width: 100%;
  float: left;
}
.logo {
  margin: 0 0 0 5%;
  padding: 0;
  width: auto;
  float: left;
}
.logo a {
  text-decoration: none;
  outline: none;
}
.logo a img {
  height: 45px;
  max-width: 100%;
}
.logo a img.logo_white {
  display: none;
}

.main_menu {
  margin: 17px 5% 0 0;
  padding: 0;
  width: auto;
  float: right;
}
.main_menu ul {
  margin: 0;
  padding: 0;
  width: auto;
  float: left;
}
.main_menu ul li {
  margin: 0 10px;
  padding: 0;
  width: auto;
  float: left;
  list-style: none;
  position: relative;
}
.main_menu ul li a {
  margin: 0;
  padding: 0 0 10px 0;
  width: auto;
  float: left;
  text-decoration: none;
  outline: none;
  font-family: "Metropolis-Regular";
  font-size: 14px;
  color: #000000;
  text-transform: uppercase;
}
.main_menu ul li a:hover {
  color: #fed402;
}
.main_menu ul li.active a {
  color: #fed402;
  font-family: "Metropolis-ExtraBold";
}
.main_menu ul li ul {
  margin: 0;
  padding: 10px;
  width: auto;
  float: left;
  display: none;
  position: absolute;
  left: 0;
  background-color: #f6f7f9;
  border-radius: 15px;
  top: 25px;
  min-width: 190px;
  z-index: 99;
}
.main_menu ul li:hover ul {
  display: block;
}
.main_menu ul li ul li {
  margin: 0 0 0 0;
  padding: 10px 0 0 0;
  width: 100%;
  float: left;
  list-style: none;
  position: relative;
}
.main_menu ul li ul li h3 {
  font-family: "Metropolis-ExtraBold";
  font-size: 14px;
  color: #000000;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}
.main_menu ul li ul li a {
  margin: 0;
  padding: 8px 10px;
  width: 100%;
  float: left;
  text-decoration: none;
  outline: none;
  font-family: "Metropolis-Regular";
  font-size: 14px;
  color: #000 !important;
  text-transform: uppercase;

  border-radius: 5px;
}
.main_menu ul li ul li a:hover {
  color: #fed402;
  background-color: #fff;
}

.banner {
  background-color: #ffffff;
  margin: 0 1% 0px 1%;
  padding: 50px 0 0 0;
  width: 98%;
  float: left;
  border-radius: 0 0 30px 30px;
}
.banner h2 {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  font-family: "GothamMedium";
  font-size: 84px;
  color: #000;
  font-style: italic;
  letter-spacing: -5px;
  position: relative;
}
.banner h2 span.star {
  position: absolute;
  top: -25px;
}
.banner h2 img.growing {
  margin: 0 0 0 10px;
  width: 100px;
}
.banner h1 {
  float: left;
  margin: -12px 0 0 0;
  padding: 0;
  width: 100%;
  text-align: center;
  font-family: "Gotham-Bold";
  font-size: 84px;
  color: #000;
  letter-spacing: -5px;
}
.banner h1 span img.box {
  width: 70px;
}
.banner h1 img.menap {
  width: 78px;
}
.publish {
  float: left;
  margin: 0 0 0 0;
  padding: 60px 0 110px 0;
  width: 100%;
  background-image: url("../images/publish_bg.png");
  background-repeat: no-repeat;
  background-position: center 180px;
  background-size: 68%;
}

.banner .employees {
  margin: 180px 1% 0 0%;
  width: 225px;
  min-height: 260px;
  height: auto;
  padding: 90px 20px 20px 20px;
  float: left;
  text-align: right;
  border-radius: 50px;
  background: rgba(239, 239, 239, 0.5);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  -webkit-transition: all 0.3s ease-in-out;
  z-index: 999;
  position: relative;
}
.banner .employees:hover {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-transition: all 0.3s ease-in-out;
}

.banner .employees h3 {
  font-family: "FivoSansModern-Heavy";
  float: right;
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 56px;
  line-height: 70px;
  color: #010101;
}
.banner .employees p {
  font-family: "Metropolis-Medium";
  float: right;
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 24px;
  color: #010101;
}
.banner .advertising_partners {
  margin: 180px 0 0 0;
  width: 225px;
  min-height: 260px;
  height: auto;
  padding: 90px 20px 20px 20px;

  float: left;
  text-align: right;
  border-radius: 50px;
  background: rgba(239, 239, 239, 0.5);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  -webkit-transition: all 0.3s ease-in-out;
  z-index: 999;
  position: relative;
}
.banner .advertising_partners:hover {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-transition: all 0.3s ease-in-out;
}
.banner .advertising_partners h3 {
  font-family: "FivoSansModern-Heavy";
  float: right;
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 56px;
  line-height: 70px;
  color: #010101;
}
.banner .advertising_partners p {
  font-family: "Metropolis-Medium";
  float: right;
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 24px;
  color: #010101;
}
.banner .games_published {
  margin: 180px 1% 0 0;
  width: 225px;
  min-height: 260px;
  height: auto;
  padding: 90px 20px 20px 20px;

  float: left;
  text-align: left;
  border-radius: 50px;
  background: rgba(239, 239, 239, 0.5);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  -webkit-transition: all 0.3s ease-in-out;
  z-index: 999;
  position: relative;
}
.banner .games_published:hover {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-transition: all 0.3s ease-in-out;
}
.banner .games_published h3 {
  font-family: "FivoSansModern-Heavy";
  float: right;
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 56px;
  line-height: 70px;
  color: #010101;
}
.banner .games_published p {
  font-family: "Metropolis-Medium";
  float: right;
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 24px;
  color: #010101;
}
.banner .dau {
  margin: 180px 0 0 0;
  width: 225px;
  min-height: 260px;
  height: auto;
  padding: 90px 20px 20px 20px;
  float: left;
  text-align: left;
  border-radius: 50px;
  background: rgba(239, 239, 239, 0.5);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  -webkit-transition: all 0.3s ease-in-out;
  z-index: 999;
  position: relative;
}
.banner .dau:hover {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-transition: all 0.3s ease-in-out;
}
.banner .dau h3 {
  font-family: "FivoSansModern-Heavy";
  float: right;
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 56px;
  line-height: 70px;
  color: #010101;
}
.banner .dau p {
  font-family: "Metropolis-Medium";
  float: right;
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 24px;
  color: #010101;
}
.banner .downloads {
  background-image: url("../images/downloads-icon.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 100%;
  margin: 0 0;
  float: left;
  border-radius: 25px;
  text-align: center;
  position: relative;

  width: 300px;
  padding: 260px 0 30px 0;
  height: auto;
  -webkit-transition: all 0.3s ease-in-out;
  z-index: 999;
}
.banner .downloads:hover {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-transition: all 0.3s ease-in-out;
}
.banner .downloads2 img.downloads_dots {
  position: absolute;
  left: 30px;
  z-index: 999;
  top: 66px;
  width: 30px;
}
.banner .downloads2 {
  margin: 0 1%;
  float: left;
  position: relative;
  width: auto;
  padding: 0px 0 0px 0;
  height: auto;
  z-index: 999;
}

.banner .downloads img {
  border: none;
  width: 180px;
}
.banner .downloads h2 {
  font-family: "GothamBlack";
  float: right;
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 56px;
  color: #ffffff;
  font-style: normal;
  letter-spacing: -2px;
}
.banner .downloads p {
  font-family: "GothamMedium";
  float: right;
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 25px;
  color: #ffffff;
  letter-spacing: 0px;
}

.banner .downloads2 .beating {
  background-image: url("../images/beating.png");
  background-repeat: no-repeat;
  float: left;
  width: 240px;
  height: 65px;
  margin: 0;
  padding: 12px 20px;
  font-family: "FivoSansModern-Medium";
  font-size: 18px;
  color: #000;
  letter-spacing: -1px;

  text-align: center;
  position: absolute;
  top: 0;
  left: -170px;
  background-size: 240px;
}
.banner .download2s .beating a {
  font-family: "FivoSansModern-Medium";
  font-size: 18px;
  color: #000;
  letter-spacing: -1px;
}
.banner .downloads2 .beating a span {
  font-family: "FivoSansModern-Medium";
  font-size: 18px;
  color: #000;
  letter-spacing: -1px;
}

.our_games {
  margin: 0;
  padding: 150px 0 150px 0;
  width: 100%;
  float: left;
}
.our_games .left_area {
  margin: 0;
  padding: 0;
  width: 100%;
  float: left;
}
.our_games .center_area {
  margin: 0 0;
  padding: 10px 0 0 0;
  width: 100%;
  float: left;
  text-align: center;
}
.title {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  display: ruby;
}
.our_games .center_area h1 {
  font-family: "FivoSansModern-Heavy";
  font-size: 78px;
  color: #fff;
  float: none;
  width: auto;
  margin: 0;
  padding: 0;
  line-height: 72px;
  position: relative;
}
.our_games .center_area h2 {
  font-family: "FivoSansModern-Heavy";
  font-size: 78px;
  color: #fff;
  float: left;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  line-height: 72px;
  position: relative;
}
.our_games .center_area h3 {
  font-family: "FivoSansModern-Heavy";
  font-size: 78px;
  color: #fff;
  float: left;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  line-height: 72px;
  position: relative;
}
.our_games .center_area h3 img {
  position: relative;
  top: 11px;
  right: 5px;
  width: 65px;
}

.our_games .icons {
  position: relative;
  top: 12px;
}
.our_games .icons a img {
  border: none;
  max-width: 100%;
  width: 55px;
}
.our_games .center_area h1 .game-title-icon {
  float: left;
  margin: 20px 0 0 0;
}
.our_games .center_area p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #ffffff;
  float: left;
  margin: 20px 0 20px 0;
  padding: 0 0;
  text-align: center;
  line-height: 27px;
  width: 100%;
}
.our_games .center_area ul.games_type {
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  list-style: none;
  text-align: center;
}
.our_games .center_area ul.games_type li {
  float: none;
  margin: 3px;
  padding: 0 0 0 0;
  width: auto;
  list-style: none;
  display: inline-flex;
}
.our_games .center_area ul.games_type li a {
  float: none;
  margin: 0;
  padding: 8px 16px;
  width: auto;
  list-style: none;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #ffffff;
  text-transform: uppercase;
  border: 1px solid #4a4a4a;
  border-radius: 30px;
}
.our_games .center_area .view_all {
  float: left;
  margin: 20px 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: center;
  clear: both;
  display: flex;
}
.our_games .center_area .view_all a {
  text-decoration: none;
  outline: none;
  background-color: #fed402 !important;
  border-radius: 30px;
  margin: 0 auto;
  padding: 16px 32px;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000000;
  text-transform: uppercase;
  background: linear-gradient(#4353ff 0 0) calc(100% - var(--p, 0%)) /
    var(--p, 0%) no-repeat;
  transition: 0.4s, background-position 0s;
}

.our_games .center_area .view_all a:hover {
  color: #fff;
  --p: 100%;
}

.our_games .right_area {
  margin: 0;
  padding: 0;
  width: 100%;
  float: left;
}
.left_area .games_icon_row {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}
.left_area .games_icon_row img {
  float: left;
  margin: 0 15px 15px 0;
  padding: 0;
  width: auto;
  height: 96px;
}
.left_area .games_icon_row img:last-child {
  margin-right: 0;
}
.right_area .games_icon_row_right {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}
.right_area .games_icon_row_right img {
  float: right;
  margin: 0 0 15px 15px;
  padding: 0;
  width: auto;
  height: 96px;
}
.right_area .games_icon_row_right img:last-child {
  margin-left: 0;
}

.top_contact_us_area {
  background-color: #ffffff;
  margin: 1% 0 0px 0;
  padding: 40px 0 40px 0;
  width: 100%;
  float: left;
  border-radius: 30px 30px 0 0;
}

.contact_us {
  background-color: #ffffff;
  margin: 20px 0 0 0;
  padding: 80px 0;
  width: 100%;
  float: left;
  border-radius: 30px 30px 0 0;
}
img.boltmobile {
  display: none;
}

.contact_us h1 {
  font-family: "FivoSansModern-Heavy";
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 72px;
  color: #000000;
  background-image: linear-gradient(to right, #1116c6, #1116c6 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  -webkit-transition: background-position 1s ease;
  -moz-transition: background-position 1s ease;
  -o-transition: background-position 1s ease;
  transition: background-position 1s ease;
}
.contact_us h1:hover {
  background-position: 0;
}
.contact_us p {
  font-family: "Metropolis-Regular";
  font-size: 16px;
  color: #000000;
  float: left;
  margin: 0;
  padding: 0;
  width: 70%;
}
.contact_us .sign_up {
  float: left;
  margin: 120px 0 0 0;
  padding: 0;
  width: 100%;
}
.sign_up h4 {
  float: left;
  margin: 0px 0 0 0;
  padding: 0;
  width: 100%;
  font-family: "Metropolis-Bold";
  font-size: 16px;
  color: #000;
}
.sign_up input.email {
  float: left;
  margin: 10px 0 10px 0;
  width: 100%;
  font-family: "Metropolis-Regular";
  font-size: 17px;
  color: #000000;
  border: 1px solid #b3b3b3;
  border-radius: 68px;
  padding: 8px 30px;
}
.sign_up input.email:placeholder {
  font-family: "Metropolis-Regular";
  font-size: 17px;
  color: #000000;
}
.sign_up ::placeholder {
  font-family: "Metropolis-Regular";
  font-size: 17px;
  color: #000000;
  opacity: 1; /* Firefox */
}

.sign_up ::-ms-input-placeholder {
  /* Edge 12-18 */
  font-family: "Metropolis-Regular";
  font-size: 17px;
  color: #000000;
}
.sign_up input.submit {
  float: left;
  margin: 10px 0 10px 0;
  width: auto;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #fff;
  border: none;
  border-radius: 68px;
  padding: 16px 32px;
  background-color: #000;
}
.just_play {
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: center;
}

.just_play_text {
  float: none;
  margin: 0 auto;
  padding: 0;
  width: 120px;
  text-align: center;
}
.circle-btn {
  display: grid;
  place-content: center;
  width: 120px;
  aspect-ratio: 1;
  border-radius: 50%;
  text-decoration: none;
  color: #000;
  position: absolute;
}

#text {
  font-family: "Metropolis-Bold";
  font-size: 10px;
  animation: spinAround 50s linear infinite;
}
#toptext {
  font-family: "Metropolis-Bold";
  font-size: 10px;
  animation: spinAround 50s linear infinite;
}
/* .circle-btn__text{position:absolute;inset:0;font-family: 'Metropolis-SemiBold';font-size:12px;text-transform:uppercase;text-align:center;animation: spinAround 18s linear infinite;} */

/* .circle-btn__text span{position:absolute;top:0;text-transform:uppercase;display:inline-block;transform-origin:0 60px;color: #000;} */

@keyframes spinAround {
  to {
    transform: rotate(-360deg);
  }
}

.text span {
  position: absolute;
  left: 50%;
  color: #fff;
  text-transform: uppercase;
  transform-origin: 0 60px;
}

.just_play img {
  width: 100%;
  max-width: 44px;
  border: none;
  margin: 50px 0 0 0;
}
.submit_project {
  float: left;
  margin: 40px 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
}

.submit_project input.text {
  float: left;
  margin: 0px 0 10px 0;
  width: 100%;
  font-family: "Metropolis-Regular";
  font-size: 17px;
  color: #000000;
  border: 1px solid #b3b3b3;
  border-radius: 68px;
  padding: 8px 30px;
}
.submit_project input.text:placeholder {
  font-family: "Metropolis-Regular";
  font-size: 17px;
  color: #000000;
}
.submit_project ::placeholder {
  font-family: "Metropolis-Regular";
  font-size: 17px;
  color: #000000;
  opacity: 1; /* Firefox */
}

.submit_project ::-ms-input-placeholder {
  /* Edge 12-18 */
  font-family: "Metropolis-Regular";
  font-size: 17px;
  color: #000000;
}

.submit_project .select {
  float: left;
  margin: 0px 0 10px 0;
  width: 100%;

  border: 1px solid #b3b3b3;
  border-radius: 68px;
  padding: 8px 30px;
}

.submit_project .select select {
  text-indent: 1px;
  text-overflow: "";
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border: none;
  background-color: #fff;
  background-image: url("../images/select.png");
  background-repeat: no-repeat;
  background-position: 100% 8px;
  font-family: "Metropolis-Regular";
  font-size: 17px;
  color: #000000;
}
.submit_project textarea.textarea {
  font-family: "Metropolis-Regular";
  font-size: 17px;
  color: #000000;
  border: 1px solid #b3b3b3;
  border-radius: 20px;
  padding: 8px 30px;
  margin: 0 0 0 0;
  resize: none;
  min-height: 170px;
  width: 100%;
}

.submit_project input.file {
  font-family: "Metropolis-Regular";
  font-size: 17px;
  color: #000;
  float: left;
  margin: 10px 0 10px 0;
  padding: 0;
  width: auto;
}
.submit_project input.submit {
  float: right;
  margin: 10px 0 10px 0;
  width: auto;
  font-family: "Metropolis-Regular";
  font-size: 17px;
  color: #fff;
  border: none;
  border-radius: 68px;
  padding: 12px 22px;
  background-color: #000;
}

.submit_project input[type="file"]::file-selector-button {
  border: 1px solid #b3b3b3;
  border-radius: 68px;
  padding: 8px 30px;
  background-color: #fff;
  transition: 1s;
  font-family: "Metropolis-Regular";
  font-size: 17px;
  color: #000;
}

.submit_project input[type="file"]::file-selector-button:hover {
  background-color: #fff;
  border: 1px solid #b3b3b3;
  font-family: "Metropolis-Regular";
  font-size: 17px;
  color: #000;
}

.bolt {
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: center;
}
.bolt img {
  width: 100%;
  max-width: 320px;
  border: none;
}

.work_with {
  float: left;
  margin: 0 0 0 0;
  padding: 0 20px;
  width: 100%;
}
.content_creators {
  float: left;
  margin: 0 0 0 0;
  padding: 90px 0 50px 0;
  width: 100%;
  background-color: #ffffff;
  border-radius: 30px;
  background-image: url("../images/content_creators.png");
  background-repeat: no-repeat;
  background-position: right 160px;
  background-size: 240px;
}
.leaves {
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 20%;
  width: 100%;
  position: relative;
  z-index: 99;
}

.love_to_work {
  background: rgba(239, 239, 239, 0.4);
  float: left;
  margin: -60px 0 0 0;
  padding: 50px 20px 120px 50px;
  width: 80%;
  border-radius: 30px;
  position: relative;
  z-index: 9;
}
.love_to_work h2 {
  font-family: "FivoSansModern-Heavy";
  font-size: 78px;
  color: #1116c6;
  letter-spacing: -3px;
  line-height: 100px;
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 90%;
  position: relative;
  background-image: linear-gradient(to right, #000, #000 50%, #1116c6 50%);
  background-size: 200% 100%;
  background-position: -100%;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  text-decoration: none;

  -webkit-transition: background-position 1s ease;
  -moz-transition: background-position 1s ease;
  -o-transition: background-position 1s ease;
  transition: background-position 1s ease;
}
.content_creators .parallax-image-inner {
  position: relative;
  z-index: 99;
}
.content_creators .parallax-image-wrap {
  overflow: unset !important;
}
.love_to_work h2:hover {
  background-position: 0;
}
.icon-content {
  position: absolute;
  left: 22%;
  top: 160px;
  z-index: 99;
  width: 60px;
}
.love_to_work p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000;
  float: left;
  margin: 10px 0 0 0;
  padding: 0 0 0 0;
  width: 80%;
}
img.content_creators_icon {
  max-width: 90%;
  float: right;
  position: relative;
  margin: -200px 0 0 0;
  z-index: 99;
}
.work_services_top {
  position: relative;
  z-index: 99;
  float: left;
  margin: -90px 0 0 0;
  padding: 0;
  width: 100%;
}
.work_services {
  background: rgba(239, 239, 239, 0.5);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  -webkit-transition: all 0.3s ease-in-out;
  float: left;
  margin: 0 0 0 0;
  padding: 30px 30px;
  width: 100%;
  border-radius: 30px;
  position: relative;
  z-index: 99;
}
.work_services:hover {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-transition: all 0.3s ease-in-out;
}
.work_services .icons {
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: right;
  position: relative;
  border: 0px;
}
.work_services .icons img {
  width: 90px;
}

.work_services h3 {
  font-family: "FivoSansModern-Heavy";
  font-size: 42px;
  color: #1116c6;
  letter-spacing: 0px;
  line-height: 44px;
  float: left;
  margin: 0 0 20px 0;
  padding: 0 0 0 0;
  width: 100%;
}
.work_services p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000;
  float: left;
  margin: 0px 0 20px 0;
  padding: 0 0 0 0;
  width: 100%;
}
.learn_more {
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
}
.learn_more a {
  float: left;
  margin: 10px 0 10px 0;
  width: auto;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  border-radius: 68px;
  padding: 16px 32px;
  border: 1px solid #969696;
  text-decoration: none;
  outline: none;
  text-transform: uppercase;
  background: linear-gradient(#fed402 0 0) calc(100% - var(--p, 0%)) /
    var(--p, 0%) no-repeat;
  transition: 0.4s, background-position 0s;
}
.learn_more a:hover {
  border: 1px solid #fed402;
  color: #000;
  --p: 100%;
}
.growthsandbox {
  float: left;
  margin: 20px 0 0 0;
  padding: 0 20px;
  width: 100%;
}
.growth_sand_box {
  float: left;
  margin: 0 0 0 0;
  padding: 60px 0 60px 0;
  width: 100%;
  background-color: #ffffff;
  border-radius: 30px;
}
.growthsandbox-icon {
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: right;
}
.growthsandbox-icon img {
  border: none;
  max-width: 100%;
  width: 120px;
}
.content_growth_sand_box {
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 100px;
  width: 100%;
}
.content_growth_sand_box img {
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: auto;
  position: relative;
  z-index: 99;
}
.content_growth_sand_box h3 {
  font-family: "FivoSansModern-Heavy";
  font-size: 100px;
  color: #000;
  letter-spacing: 0px;
  line-height: 90px;
  float: left;
  margin: -10px 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  position: relative;
  z-index: 9;
  background-image: linear-gradient(to right, #1116c6, #1116c6 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  -webkit-transition: background-position 1s ease;
  -moz-transition: background-position 1s ease;
  -o-transition: background-position 1s ease;
  transition: background-position 1s ease;
}
.content_growth_sand_box h3:hover {
  background-position: 0;
}

.fuel_growth {
  border-left: 1px solid #7d7d7d;
  float: left;
  margin: 0 0 0 0;
  padding: 60px 0 0 20px;
  width: 100%;
}
.fuel_growth p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  line-height: 27px;
  color: #000;
  float: left;
  margin: 0px 0 20px 0;
  padding: 0 0 0 0;
  width: 100%;
}

.fuel_growth ul.games_type {
  float: left;
  margin: 0 0 20px 0;
  padding: 0 0 0 0;
  width: 100%;
  list-style: none;
  text-align: center;
}
.fuel_growth ul.games_type li {
  float: left;
  margin: 3px;
  padding: 8px 32px;
  width: auto;
  list-style: none;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  text-transform: uppercase;
  border: 1px solid #e5e5e5;
  border-radius: 30px;
  background: linear-gradient(#fed402 0 0) calc(100% - var(--p, 0%)) /
    var(--p, 0%) no-repeat;
  transition: 0.4s, background-position 0s;
}

.fuel_growth ul.games_type li:hover {
  border: 1px solid #fed402;
  color: #000;
  --p: 100%;
}

.tools {
  margin: 30px 0 0 0;
  padding: 0px 0 0 0;
  width: 100%;
  text-align: center;
  border-radius: 40px;
  background: -webkit-linear-gradient(270deg, #f3f4f8, #ccc5f8);
  position: relative;
}

.tools_inner {
  background-image: url("../images/tools_bg.png");
  background-repeat: no-repeat;
  background-position: top left;
  float: left;
  margin: 0px 0 0 0;
  padding: 40px 0 0 0;
  width: 100%;
  background-size: 100%;
  border-radius: 40px;
}

.tools h3 {
  font-family: "FivoSansModern-Heavy";
  font-size: 42px;
  color: #000;
  letter-spacing: 0px;
  line-height: 48px;
  float: left;
  margin: 0px 0 0 0;
  padding: 0 10%;
  width: 100%;
  text-align: center;
}
.tools p {
  font-family: "Metropolis-Medium";
  font-size: 19px;
  color: #000;
  line-height: 27px;
  float: left;
  margin: 30px 0;
  padding: 0 5%;
  width: 100%;
  text-align: center;
}
.view_tools {
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: center;
  height: 50px;
}
.view_tools a {
  float: none;
  margin: 0;
  padding: 16px 32px;
  width: auto;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  text-transform: uppercase;
  border: 1px solid #8d8d95;
  border-radius: 30px;
  background: linear-gradient(#fed402 0 0) calc(100% - var(--p, 0%)) /
    var(--p, 0%) no-repeat;
  transition: 0.4s, background-position 0s;
}
.view_tools a:hover {
  border: 1px solid #fed402;
  color: #000;
  --p: 100%;
}

.tools img {
  border: none;
  max-width: 100%;
  float: left;
  width: 100%;
  padding: 0 5%;
}

.pixls {
  margin: 30px 0 0 0;
  padding: 0;
  width: 100%;
  border-radius: 40px;
  background: -webkit-linear-gradient(270deg, #e6e9f7, #faede5);
  position: relative;
  float: left;
}

.pixls_inner {
  background-image: url("../images/pixls.png");
  background-repeat: no-repeat;
  background-position: top left;
  float: left;
  margin: 0px 0 0 0;
  padding: 320px 0 45px 34px;
  width: 100%;
  background-size: 100%;
  border-radius: 40px;
}

.pixls h3 {
  font-family: "FivoSansModern-Heavy";
  font-size: 50px;
  color: #000;
  letter-spacing: 0px;
  line-height: 55px;
  float: left;
  margin: 0px 0 0 0;
  padding: 0 0;
  width: 100%;
}
.pixls p {
  font-family: "Metropolis-Regular";
  font-size: 19px;
  color: #000;
  float: left;
  margin: 20px 0 30px 0;
  padding: 0;
  width: 100%;
}

.emplore_more {
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
}
.emplore_more a {
  float: left;
  margin: 0;
  padding: 16px 32px;
  width: auto;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  text-transform: uppercase;
  border: 1px solid #8d8d95;
  border-radius: 30px;
  background: linear-gradient(#fed402 0 0) calc(100% - var(--p, 0%)) /
    var(--p, 0%) no-repeat;
  transition: 0.4s, background-position 0s;
}
.emplore_more a:hover {
  border: 1px solid #fed402;
  color: #000;
  --p: 100%;
}
.gd_hub {
  background-image: url("../images/gd_hub_bg.png");
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 100%;
  float: left;
  margin: 20px 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  border-radius: 40px;
}
.gd_hub_content {
  float: left;
  margin: 0 0 0 0;
  padding: 30px 0 0;
  text-align: center;
  width: 100%;
}
.gd_hub_content h3 {
  font-family: "FivoSansModern-Heavy";
  font-size: 30px;
  color: #000;
  letter-spacing: -1px;
  line-height: 30px;
  float: left;
  margin: 15px 0 15px 0;
  padding: 0 0;
  width: 100%;
}
.gd_hub_content p {
  font-family: "Metropolis-Medium";
  font-size: 19px;
  color: #000;
  line-height: 24px;
  float: left;
  margin: 0px 0;
  padding: 0 2%;
  width: 100%;
  text-align: center;
}

.gd_hub_content .learn_more {
  float: left;
  margin: 25px 0 18px 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: center;
  height: 46px;
  clear: both;
}
.gd_hub_content .learn_more a {
  float: none;
  margin: 0;
  padding: 16px 32px;
  width: auto;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  text-transform: uppercase;
  border: 1px solid #737796;
  border-radius: 30px;
  background: none;
  background: linear-gradient(#fed402 0 0) calc(100% - var(--p, 0%)) /
    var(--p, 0%) no-repeat;
  transition: 0.4s, background-position 0s;
}
.gd_hub_content .learn_more a:hover {
  border: 1px solid #fed402;
  color: #000;
  --p: 100%;
}
.gd_hub .map {
  float: left;
  margin: 0px 0 0 0;
  padding: 0 0;
  width: 100%;
  position: relative;
  background-image: url("../images/gd_hub_mapfull.png");
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: 100%;
}
.gd_hub .map img.map_img {
  position: relative;
  margin: 0;
  padding: 0;
  z-index: 1;
  float: left;
  width: 100%;
}
.gd_hub .map img.locations_img {
  position: relative;
  margin: 30px 0 52px 0;
  padding: 0;
  float: left;
  width: 100%;
  height: 100%;
  animation: zoom-in-zoom-out 1s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.01, 1.01);
  }
  100% {
    transform: scale(1, 1);
  }
}

.gd_created {
  float: left;
  margin: 0 0 0 0;
  padding: 100px 0;
  width: 100%;
  text-align: center;
}
.gd_created h4 {
  font-family: "FivoSansModern-Regular";
  font-size: 30px;
  color: #000;
  float: left;
  margin: 0 0 40px 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: center;
}
.gd_created h2 {
  font-family: "FivoSansModern-Regular";
  font-size: 68px;
  float: left;
  margin: 0 0 0 0;
  letter-spacing: -2px;
  padding: 0 7%;
  width: 100%;
  text-align: center;
  color: #fff;
  background: -webkit-linear-gradient(#8498f9, #ed97da);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mission {
  float: left;
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  border-radius: 40px;
  background: -webkit-linear-gradient(300deg, #e6f2f7, #fdeaff);
  position: relative;
}
.mission_line {
  position: absolute;
  z-index: 99;
  top: -25px;
  left: 0;
  width: 100%;
}
.mission_line img {
  width: 100%;
}

svg .svg-elem-1 {
  stroke-dashoffset: 2782.887939453125px;
  stroke-dasharray: 2782.887939453125px;
  -webkit-transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715)
    0s;
  transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
}

svg.active .svg-elem-1 {
  stroke-dashoffset: 0;
}

@-webkit-keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 2782.887939453125px;
    stroke-dasharray: 2782.887939453125px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 2782.887939453125px;
  }
}

@keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 2782.887939453125px;
    stroke-dasharray: 2782.887939453125px;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 2782.887939453125px;
  }
}

.svg-elem-1 {
  -webkit-animation: animate-svg-stroke-1;
  animation: animate-svg-stroke-1;
  animation-iteration-count: infinite;
  animation-duration: 10s;
  animation-direction: alternate;
}
@keyframes diamondMovesBack {
  0% {
    transform: translate(200px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.mission_line_full {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  position: absolute;
  z-index: 99;
  top: -100px;
  left: 0;
}
.mission_line_full .controls {
  display: none;
}

.mission_line_full_top {
  position: relative;
  float: left;
  margin: 0;
  width: 100%;
}
.mission_line_full2 {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  position: absolute;
  z-index: 99;
  top: -140px;
  left: 0;
}

.mission_inner {
  float: left;
  margin: 0 0 0 0;
  padding: 30px 30px 70px 50px;
  width: 100%;
  background-image: url("../images/mission_bg.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 100%;
}
.mission .mission_icom {
  font-family: "Metropolis-ExtraLight";
  font-size: 20px;
  color: #000;
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
}
.mission_icom span {
  font-family: "Metropolis-ExtraLight";
  font-size: 20px;
  color: #000;
  float: left;
  margin: 0;
  padding: 0;
  width: 75%;
}
.mission .mission_icom img {
  float: right;
  width: 120px;
}
.mission h2 {
  font-family: "FivoSansModern-Bold";
  font-size: 100px;
  color: #000000;
  letter-spacing: -2px;
  float: left;
  margin: 200px 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  position: relative;
  z-index: 9999;
}
.mission h2 span {
  font-family: "FivoSansModern-Bold";
  font-size: 100px;
  color: #000000;
  letter-spacing: -2px;
  float: left;
  background-image: linear-gradient(to right, #1116c6, #1116c6 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  -webkit-transition: background-position 1s ease;
  -moz-transition: background-position 1s ease;
  -o-transition: background-position 1s ease;
  transition: background-position 1s ease;
}
.mission h2 span:hover {
  background-position: 0;
}

.mission h2 img {
  position: relative;
  float: left;
}
.mission p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  line-height: 27px;
  color: #000;
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 85%;
}
.mission .more_about_us {
  float: left;
  margin: 25px 0 18px 0;
  padding: 0 0 0 0;
  width: 100%;
  height: 46px;
  clear: both;
}
.mission .more_about_us a {
  float: left;
  margin: 0;
  padding: 16px 32px;
  width: auto;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  text-transform: uppercase;
  border: 1px solid #737796;
  border-radius: 30px;
  background: none;
  background: linear-gradient(#fed402 0 0) calc(100% - var(--p, 0%)) /
    var(--p, 0%) no-repeat;
  transition: 0.4s, background-position 0s;
}
.mission .more_about_us a:hover {
  border: 1px solid #fed402;
  color: #000;
  --p: 100%;
}

.vision {
  float: left;
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  border-radius: 40px;
  background: -webkit-linear-gradient(300deg, #f3f4f8, #c5e3f8);
  position: relative;
}

.vision_inner {
  float: left;
  margin: 0 0 0 0;
  padding: 30px 30px 70px 50px;
  width: 100%;
  background-image: url("../images/vision_bg.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 100%;
}
.vision_line {
  position: absolute;
  z-index: 99;
  top: -25px;
  left: 0;
  width: 100%;
}
.vision_line img {
  width: 100%;
}

.vision .vision_icom {
  font-family: "Metropolis-ExtraLight";
  font-size: 20px;
  color: #000;
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
}
.vision .vision_icom img {
  float: right;
  width: 120px;
}
.vision_icom span {
  font-family: "Metropolis-ExtraLight";
  font-size: 20px;
  color: #000;
  float: left;
  margin: 0;
  padding: 0;
  width: 75%;
}
.vision h2 {
  font-family: "FivoSansModern-Bold";
  font-size: 100px;
  color: #000000;
  letter-spacing: -2px;
  float: left;
  margin: 200px 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  position: relative;
  z-index: 9999;
}
.vision h2 span {
  font-family: "FivoSansModern-Bold";
  font-size: 100px;
  color: #000000;
  letter-spacing: -2px;
  float: left;
  background-image: linear-gradient(to right, #1116c6, #1116c6 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  -webkit-transition: background-position 1s ease;
  -moz-transition: background-position 1s ease;
  -o-transition: background-position 1s ease;
  transition: background-position 1s ease;
}
.vision h2 span:hover {
  background-position: 0;
}

.vision h2 img {
  position: relative;
  float: left;
}
.vision p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  line-height: 27px;
  color: #000;
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 75%;
}
.vision .more_about_us {
  float: left;
  margin: 25px 0 18px 0;
  padding: 0 0 0 0;
  width: 100%;
  height: 46px;
  clear: both;
}
.vision .more_about_us a {
  float: left;
  margin: 0;
  padding: 16px 32px;
  width: auto;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  text-transform: uppercase;
  border: 1px solid #737796;
  border-radius: 30px;
  background: none;
  background: linear-gradient(#fed402 0 0) calc(100% - var(--p, 0%)) /
    var(--p, 0%) no-repeat;
  transition: 0.4s, background-position 0s;
}
.vision .more_about_us a:hover {
  border: 1px solid #fed402;
  color: #000;
  --p: 100%;
}

.ceo_ara {
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0px 0;
  width: 100%;
  background-image: url("../images/ceo_area_bg.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100%;
}
.ceo_pic {
  background-image: url("../images/ceo_bg.png");
  background-repeat: no-repeat;
  background-position: 20% bottom;
  float: left;
  margin: 0 0 0 0;
  padding: 110px 0 0 0;
  width: 100%;
  text-align: center;
}
.message {
  float: left;
  margin: 0 0 0 0;
  padding: 120px 0 0 0;
  width: 100%;
}
.message h2 {
  font-family: "FivoSansModern-Regular";
  font-size: 75px;
  float: left;
  margin: 0 0 20px 0;
  letter-spacing: -2px;
  padding: 0 0;
  width: 100%;
  text-align: left;
  color: #fff;
  background: -webkit-linear-gradient(#ffd057, #f16c7c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.message h2 img {
  float: right;
  width: 120px;
}
.message h6 {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  float: left;
  margin: 0 0 30px 0;
  padding: 0 0;
  width: 100%;
  text-align: left;
  color: #f68c70;
}
.message p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  float: left;
  margin: 0 0 30px 0;
  padding: 0 0;
  width: 80%;
  text-align: left;
  color: #ffffff;
  clear: both;
}

.blog_area {
  background-color: #ffffff;
  margin: 120px 1% 0 1%;
  padding: 80px 0 0 0;
  width: 98%;
  float: left;
  border-radius: 30px 30px 0 0;
}
.blog_area h2 {
  float: none;
  margin: 0 auto;
  padding: 0 0 0 0;
  width: auto;
  text-align: center;
  font-family: "FivoSansModern-Heavy";
  font-size: 100px;
  line-height: 90px;
  color: #000;
  display: table;
}
.blog_area h2 span {
  font-family: "FivoSansModern-Heavy";
  font-size: 100px;
  line-height: 90px;
  color: #000;
  float: left;
  background-image: linear-gradient(to right, #1116c6, #1116c6 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  -webkit-transition: background-position 1s ease;
  -moz-transition: background-position 1s ease;
  -o-transition: background-position 1s ease;
  transition: background-position 1s ease;
}
.blog_area h2 span:hover {
  background-position: 0;
}
.blog_area h2 img.icon_left {
  margin-top: 5px;
  float: left;
}
.blog_area h2 img.icon_right {
  margin: -30px 0 0 5px;
  float: left;
}

.blog_area p {
  font-family: "Metropolis-Regular";
  font-size: 16px;
  color: #000000;
  float: left;
  margin: 20px 0 20px 0;
  padding: 0 34%;
  width: 100%;
  text-align: center;
}

.blog_area ul.post_type {
  float: left;
  margin: 30px 0 50px 0;
  padding: 0 0 0 0;
  width: 100%;
  list-style: none;
  text-align: center;
}
.blog_area ul.post_type li {
  float: none;
  margin: 3px;
  padding: 0 0 0 0;
  width: auto;
  list-style: none;
  display: inline-flex;
}
.blog_area ul.post_type li a {
  float: none;
  margin: 0;
  padding: 8px 32px;
  width: auto;
  list-style: none;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000000;
  text-transform: uppercase;
  border: 1px solid #4a4a4a;
  border-radius: 30px;
}
.blog_area ul.post_type li a:hover {
  color: #fff;
  border: 1px solid #000;
  background-color: #000000;
}
.blog_area ul.post_type li a.active {
  color: #fff;
  border: 1px solid #000;
  background-color: #000000;
}
.tabcontent {
  display: none;
  padding: 0;
  margin: 0;
  float: left;
  width: 100%;
  border: none;
}

.blog_slide {
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  overflow: hidden;
}
.blog_slide .swiper-slide{
padding: 0 15px;
}
.blog_area .home_blog_post {
  float: left;
  margin: 0 0 0 0;
  padding: 20px;
  width: 100%;
  border-radius: 30px;
}
.blog_area .post_bg1 {
  background-color: #f3f4f8; /* For browsers that do not support gradients */
  background-image: linear-gradient(#f3f4f8, #ccc5f8);
}
.blog_area .post_bg2 {
  background-color: #e6e9f7; /* For browsers that do not support gradients */
  background-image: linear-gradient(#e6e9f7, #faede5);
}
.home_blog_post .post_date {
  font-family: "Metropolis-Regular";
  font-size: 18px;
  color: #000000;
  float: left;
  margin: 20px 0 10px 0;
  padding: 0 0 0 0;
  width: 100%;
}
.home_blog_post h3 {
  font-family: "Metropolis-ExtraBold";
  font-size: 25px;
  color: #000;
  float: left;
  margin: 0px 0 20px 0;
  padding: 0 0 0 0;
  width: 100%;
}
.home_blog_post p {
  font-family: "Metropolis-Regular";
  font-size: 16px;
  color: #000000;
  float: left;
  margin: 0px 0 30px 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: left;
}
.home_blog_post .read_more {
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
}
.home_blog_post .read_more a {
  float: left;
  margin: 0px 0 0px 0;
  width: auto;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  border-radius: 68px;
  padding: 16px 32px;
  border: 1px solid #969696;
  text-decoration: none;
  outline: none;
  text-transform: uppercase;
  background: linear-gradient(#fed402 0 0) calc(100% - var(--p, 0%)) /
    var(--p, 0%) no-repeat;
  transition: 0.4s, background-position 0s;
}
.home_blog_post .read_more a:hover {
  border: 1px solid #fed402;
  color: #000;
  --p: 100%;
}

.meet_the_studios {
  background-color: #ffffff;
  margin: 0px 1%;
  padding: 80px 0;
  width: 98%;
  float: left;
  border-radius: 0 0 30px 30px;
}
.meet_the_studios h2 {
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: center;
  font-family: "FivoSansModern-Heavy";
  font-size: 100px;
  line-height: 90px;
  color: #000;
  position: relative;

  background-image: linear-gradient(to right, #1116c6, #1116c6 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  -webkit-transition: background-position 2s ease;
  -moz-transition: background-position 2s ease;
  -o-transition: background-position 2s ease;
  transition: background-position 2s ease;
}
.meet_the_studios h2:hover {
  background-position: 0;
}
.meet_the_studios h2 img {
  position: absolute;
  right: 0;
  top: 0;
  width: 120px;
}
.meet_the_studios p {
  font-family: "Metropolis-Regular";
  font-size: 16px;
  color: #000000;
  float: left;
  margin: 20px 0 20px 0;
  padding: 0 33%;
  width: 100%;
  text-align: center;
}
.logo_studios {
  float: left;
  margin: 0 0 0 0;
  padding: 0 5%;
  width: 100%;
}
.logo_studios .studios_logo {
  float: left;
  margin: 70px 0 30px 0;
  padding: 0 0 0 0;
  width: 25%;
  text-align: center;
}
.logo_studios .studios_logo img {
  max-width: 100%;
  transition: transform 0.5s ease-in-out;
}
.logo_studios .studios_logo:hover img {
  transform: scale(1.3) rotate(5deg);
}

.game_changers {
  background-color: #ffffff;
  margin: 20px 1%;
  padding: 80px 0;
  width: 98%;
  float: left;
  border-radius: 30px 30px;
}
.game_changers h2 {
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: center;
  font-family: "FivoSansModern-Heavy";
  font-size: 100px;
  line-height: 100px;
  color: #000;
  position: relative;
  background-image: linear-gradient(to right, #1116c6, #1116c6 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  -webkit-transition: background-position 1s ease;
  -moz-transition: background-position 1s ease;
  -o-transition: background-position 1s ease;
  transition: background-position 1s ease;
}
.game_changers h2:hover {
  background-position: 0;
}
.game_changers h2 img {
  position: absolute;
  left: 0;
  top: 0;
  width: 120px;
}
.game_changers p {
  font-family: "Metropolis-Regular";
  font-size: 19px;
  color: #000000;
  float: left;
  margin: 20px 0 20px 0;
  padding: 0 28%;
  width: 100%;
  text-align: center;
}

.game_changer {
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
}
.game_changer img {
  border: none;
  height: 650px;
  max-width: none;
  width: 400px;
  float: left;
}
.game_changer .info {
  float: left;
  margin: 160px 0 0 45px;
  padding: 0 0 0 0;
  width: 460px;
}
.game_changer .info h3 {
  font-family: "FivoSansModern-Heavy";
  font-size: 50px;
  color: #000;
  float: left;
  margin: 0 0 0 0px;
  padding: 0 0 0 0;
  width: 100%;
}
.game_changer .info p {
  font-family: "FivoSansModern-Regular";
  font-size: 19px;
  color: #000;
  float: left;
  margin: 0 0 30px 0px;
  padding: 0 0 0 0;
  width: 100%;
  text-align: left;
}

.navmenu {
  float: left;
  margin: 70px 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
}

.navmenu li {
  list-style: none;
  float: left;
  margin: 0 1% 0 0;
  padding: 0 0 0 0;
  width: 11%;
  height: 650px;
  overflow: hidden;
  border-radius: 60px;
  transition-timing-function: ease-out;
}

.navmenu li.active {
  max-width: 63%;
  width: 100%;
}

.footer {
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  width: 100%;
  float: left;
  border-radius: 0;
}
.footer_area {
  margin: 1.5% 1.5% 0px 1.5%;
  padding: 65px 30px 55px 30px;
  width: 97%;
  float: left;
  background-color: #000;
  border-radius: 30px 30px 0 0;
}
.footer_logo {
  float: left;
  margin: 0 0 30px 0;
  padding: 0;
  width: 100%;
}
.footer_area p {
  font-family: "FivoSansModern-Regular";
  font-size: 20px;
  color: #808080;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}
.footer_area .copy_right {
  font-family: "FivoSansModern-Regular";
  font-size: 20px;
  color: #808080;
  float: left;
  margin: 230px 0 0 0;
  padding: 0;
  width: 100%;
}
.footer_area ul.footer_menu {
  margin: 0;
  padding: 0;
  width: auto;
  float: left;
}
.footer_area ul.footer_menu li {
  margin: 0 0px 20px 0;
  padding: 0;
  width: 100%;
  float: left;
  list-style: none;
}
.footer_area ul.footer_menu li a {
  margin: 0;
  padding: 0;
  width: 100%;
  float: left;
  text-decoration: none;
  outline: none;
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #a7a7a7;
}
.footer_area ul.footer_menu li a:hover {
  color: #ffffff;
}
.footer_area h3 {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #a7a7a7;
  float: left;
  margin: 0 0 20px 0;
  padding: 0;
  width: 100%;
}
.footer_area ul.menufooter {
  margin: 0;
  padding: 0;
  width: auto;
  float: left;
}
.footer_area ul.menufooter li {
  margin: 0 0px 10px 30px;
  padding: 0 0 0 0;
  width: 100%;
  float: left;
  color: #a7a7a7;
  list-style: disc;
}
.footer_area ul.menufooter li a {
  margin: 0;
  padding: 0;
  width: 100%;
  float: left;
  text-decoration: none;
  outline: none;
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #a7a7a7;
}
.footer_area ul.menufooter li a:hover {
  color: #fff;
}

.footer_area .phone {
  font-family: "FivoSansModern-Regular";
  font-size: 20px;
  color: #808080;
  float: left;
  margin: 200px 0 15px 0;
  padding: 0;
  width: 100%;
}
.footer_area .email {
  font-family: "Metropolis-ExtraBold";
  font-size: 20px;
  color: #ffca03;
  float: left;
  margin: 0 0 15px 0;
  padding: 0;
  width: 100%;
}

.footer_area .address {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #808080;
  float: left;
  margin: 0 0 15px 0;
  padding: 0;
  width: 100%;
}
.footer_area .social_icons {
  float: left;
  margin: 0 0 15px 0;
  padding: 0 0 0 0;
  width: 100%;
}
.footer_area .social_icons a {
  text-decoration: none;
  outline: none;
  border: 2px solid #4c4c4c;
  color: #4c4c4c;
  border-radius: 66%;
  padding: 10px;
  width: 40px;
  height: 40px;
  float: left;
  text-align: center;
  margin: 0 10px 0 0;
}
.footer_area .social_icons a img {
  border: none;
  max-width: 100%;
}
.mobile_menu {
  display: none;
}
.mobile_menu span {
  float: right;
  font-size: 26px;
  margin: 5px 5% 0 0;
  padding: 0 0 0 0;
  font-weight: bold;
  cursor: pointer;
}
.mobilemenu {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 80px;
}

.mobilemenu a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-family: "Metropolis-Regular";
  font-size: 14px;
  color: #fff;
  display: block;
  transition: 0.3s;
}

.mobilemenu a:hover {
  color: #fed402;
}

.mobilemenu .closebtn {
  position: absolute;
  top: 42px;
  right: 18px;
  font-size: 36px;
  margin-left: 0;
  color: #fed402;
}

@media screen and (max-height: 450px) {
  .mobilemenu {
    padding-top: 15px;
  }
  .mobilemenu a {
    font-size: 18px;
  }
}

.banner_about {
  background-color: #ffffff;
  margin: 0 1% 0px 1%;
  padding: 50px 0 0 0;
  width: 98%;
  float: left;
  border-radius: 0 0 30px 30px;
}
.banner_about h2 {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  font-family: "GothamMedium";
  font-size: 84px;
  color: #000;
  font-style: italic;
  letter-spacing: -5px;
  position: relative;
}
.banner_about h2 span.star {
  position: absolute;
  top: -45px;
  margin-left: -10px;
}
.banner_about h2 img.growing {
  margin: 0 0 0 12px;
  width: 65px;
}
.banner_about h1 {
  float: left;
  margin: 0px 0 0 0;
  padding: 0;
  width: 100%;
  text-align: center;
  font-family: "Gotham-Bold";
  font-size: 84px;
  color: #000;
  letter-spacing: -3px;
  line-height: 90px;
  position: relative;
}
.banner_about h1 span.star {
  position: absolute;
  top: -44px;
  margin-left: 56px;
}
.banner_about h1 span.icon_about_title {
  position: absolute;
  top: 38px;
  margin-left: -17px;
}
.banner_about h1 span.icon_about_title img {
  width: 60px;
}
.about_banner {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
}
.about_banner img {
  border: none;
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

.about_us {
  float: left;
  margin: 20px 0 0 0;
  padding: 0 20px;
  width: 100%;
}
.about_us_col {
  float: left;
  margin: 0 0 0 0;
  padding: 60px 0 60px 0;
  width: 100%;
  background-color: #ffffff;
  border-radius: 30px 30px 0 0;
}
.about_us_col h2 {
  font-family: "FivoSansModern-Heavy";
  font-size: 100px;
  color: #000000;
  float: left;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 60px 0 0 0;
  position: relative;
  height: 220px;
}
.about_us_col h2 span {
  font-family: "FivoSansModern-Heavy";
  font-size: 100px;
  color: #000000;
  position: relative;
  top: -36px;
  z-index: 8;
  background-image: linear-gradient(to right, #1116c6, #1116c6 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  -webkit-transition: background-position 1s ease;
  -moz-transition: background-position 1s ease;
  -o-transition: background-position 1s ease;
  transition: background-position 1s ease;
}
.about_us_col h2 span:hover {
  background-position: 0;
}

.about_us_col h2 img.about_us_icon_right {
  position: absolute;
  right: 0;
  top: 0;
  width: 120px;
}
.about_us_col h2 img.about_us_title_icon_left {
  position: relative;
  left: 10px;
}
.about_us_col h2 img.about_us_title_icon_right {
  position: relative;
  left: -34px;
  top: -18px;
}
.about_us_col p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000000;
  float: left;
  margin: 30px 0 60px 0;
  padding: 0 13%;
  width: 100%;
  text-align: center;
}
.about_us_col h3 {
  font-family: "FivoSansModern-Regular";
  font-size: 75px;
  float: left;
  margin: 0 0 50px 0;
  letter-spacing: -2px;
  padding: 0 0;
  width: 100%;
  text-align: center;
  color: #fff;
  background: -webkit-linear-gradient(#69bcff, #0072ff);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.company_philosphy {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  background-image: url("../images/company_philosphy_bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: left 30px;
  overflow: hidden;
}
.company_philosphy .swiper-slide-prev{
  transform: scale(0.9) !important;
transition: transform 1s !important;
}
.company_philosphy .swiper-slide-next{
  transform: scale(0.9) !important;
transition: transform 1s !important;
}
.company_philosphy .swiper-slide-active{
transform: scale(1) !important;
transition: transform 1s !important;
}

.company_philosphy .box {
  float: left;
  margin: 0;
  padding: 50px;
  width: 100%;
  max-width: 100%;
  border-radius: 60px;
  min-height: 450px;
  max-width: 100%;
}
.company_philosphy .bg1 {
  background: rgba(219, 253, 255, 0.5);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
.company_philosphy .bg2 {
  background: rgba(184, 232, 255, 0.5);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin-top: 140px;
}
.company_philosphy .box .icon {
  float: left;
  margin: 0 0 50px 0;
  padding: 0;
  width: 100%;
}
.company_philosphy .box .icon img {
  width: 100%;
  max-width: 90px;
}
.company_philosphy .owl-item .item .box {
  transform: scale(0.9) !important;
  transition: transform 1s !important;
}
.company_philosphy .owl-item.active .item .box {
  transform: scale(1) !important;
}

.company_philosphy .box h4 {
  font-family: "FivoSansModern-Bold";
  font-size: 40px;
  color: #000;
  float: left;
  margin: 0 0 20px 0;
  padding: 0 30px 0 0;
  width: 100%;
}
.company_philosphy .box p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000000;
  float: left;
  margin: 0px 0 0px 0;
  padding: 0 0;
  width: 100%;
  text-align: left;
}
.empowering {
  float: left;
  margin: 0px 0 0 0;
  padding: 0 20px;
  width: 100%;
  position: relative;
}
.empowering_area {
  float: left;
  margin: 0 0 0 0;
  padding: 75px 0 60px 0;
  width: 100%;
  background-color: #ffffff;
  border-radius: 0 0 0px 0px;
}
.empowering_area h2 {
  font-family: "FivoSansModern-Regular";
  font-size: 75px;
  float: left;
  margin: 0 0 50px 0;
  letter-spacing: -2px;
  padding: 0 8%;
  width: 100%;
  text-align: center;
  color: #fff;
  background: -webkit-linear-gradient(#8498f9, #ed97da);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.empowering_area p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000000;
  float: left;
  margin: 0px 0 35px 0;
  padding: 0 20%;
  width: 100%;
  text-align: center;
}
.empowering_area h2.our_values {
  font-family: "FivoSansModern-Regular";
  font-size: 100px;
  line-height: 90px;
  float: left;
  margin: 0 0 0px 0;
  letter-spacing: -2px;
  padding: 100px 10% 0 0;
  width: 100%;
  text-align: left;
  color: #fff;
  background: -webkit-linear-gradient(#ffccde, #95acff);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.our_values_box {
  float: left;
  margin: 0 0 25px 0;
  padding: 35px 35px 0 40px;
  width: 100%;
  border-radius: 60px;
  min-height: 330px;
  background: rgba(239, 239, 239, 0.4);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  -webkit-transition: all 0.3s ease-in-out;
}
.our_values_box:hover {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-transition: all 0.3s ease-in-out;
}

.our_values_box .icon_number {
  float: left;
  margin: 0;
  padding: 0 0 0 0;
  width: 100%;
  font-family: "Metropolis-Bold";
  font-size: 18px;
  color: #000;
}
.our_values_box .icon_number p {
  float: left;
  margin: 0;
  padding: 40px 0 0 0;
  width: auto;
  font-family: "Metropolis-Bold";
  font-size: 18px;
  color: #000;
}
.our_values_box .icon_number img {
  float: right;
  border: none;
  max-width: 100%;
  width: 80px;
}
.our_values_box h3 {
  font-family: "FivoSansModern-Bold";
  font-size: 36px;
  color: #6734f4;
  float: left;
  margin: 0 0 20px 0;
  padding: 0 0px 0 0;
  width: 100%;
}
.our_values_box p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000000;
  float: left;
  margin: 0px 0 0px 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: left;
}
.empowering_area .gap {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 80px;
}
.mission_vision_area {
  float: left;
  margin: 0 0 0 0;
  padding: 0px 0 60px 0;
  width: 100%;
  background-color: #ffffff;
  border-radius: 0 0 30px 30px;
}
.senior_leadership {
  background-color: #ffffff;
  margin: 160px 1% 0 1%;
  padding: 80px 0;
  width: 98%;
  float: left;
  border-radius: 30px 30px;
}
.senior_leadership h2 {
  font-family: "FivoSansModern-Heavy";
  font-size: 100px;
  line-height: 90px;
  float: left;
  margin: 0 0 0px 0;
  letter-spacing: -2px;
  padding: 100px 0 0 0;
  width: 100%;
  text-align: left;
  color: #fff;
  position: relative;
  background: -webkit-linear-gradient(#69bcff, #0072ff);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.senior_leadership h2 img {
  position: absolute;
  right: 0;
  top: 0;
}
.senior_leadership p {
  font-family: "Metropolis-Regular";
  font-size: 19px;
  color: #000;
  float: left;
  margin: 15px 0 0 0;
  padding: 0 0 0 0;
  width: 31%;
  line-height: 27px;
}
.leadership {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}

.leadership_info_1 {
  float: left;
  margin: 150px 0 0 0;
  padding: 0;
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 278px;
  max-height: 278px;
}
.leadership_info_1 img {
  float: none;
  border: none;
  max-width: 100%;
  background-color: #dbfdff;
  border-radius: 56%;
}
.leadership_info_1 .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: -webkit-linear-gradient(#69bcff, #0072ff);
  overflow: hidden;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transition: 0.3s ease;
  border-radius: 56%;
}
.leadership_info_1:hover .overlay {
  transform: scale(1);
}
.leadership_info_1 .text {
  font-family: "FivoSansModern-Regular";
  font-size: 30px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  text-align: center;
  width: 100%;
  padding: 0 5%;
}

.leadership_info_2 {
  float: left;
  margin: 110px 1.5% 0;
  padding: 0;
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 278px;
  max-height: 278px;
}
.leadership_info_2 img {
  float: none;
  border: none;
  max-width: 100%;
  background-color: #dbfdff;
  border-radius: 56%;
}
.leadership_info_2 .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: -webkit-linear-gradient(#69bcff, #0072ff);
  overflow: hidden;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transition: 0.3s ease;
  border-radius: 56%;
}
.leadership_info_2:hover .overlay {
  transform: scale(1);
}
.leadership_info_2 .text {
  font-family: "FivoSansModern-Regular";
  font-size: 30px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  text-align: center;
  width: 100%;
  padding: 0 5%;
}

.leadership_info_3 {
  float: left;
  margin: 0;
  padding: 0;
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 466px;
  max-height: 466px;
}
.leadership_info_3 img {
  float: none;
  border: none;
  max-width: 100%;
  background-color: #dbfdff;
  border-radius: 56%;
}
.leadership_info_3 .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: -webkit-linear-gradient(#69bcff, #0072ff);
  overflow: hidden;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transition: 0.3s ease;
  border-radius: 56%;
}
.leadership_info_3:hover .overlay {
  transform: scale(1);
}
.leadership_info_3 .text {
  font-family: "FivoSansModern-Regular";
  font-size: 30px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  text-align: center;
  width: 100%;
  padding: 0 5%;
}

.head_of_department {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}
.head_of_department h2 {
  font-family: "FivoSansModern-Heavy";
  font-size: 78px;
  line-height: 84px;
  float: left;
  margin: 0 0 0px 0;
  letter-spacing: -2px;
  padding: 30px 0 0 10%;
  width: 100%;
  text-align: left;
  color: #fff;
  position: relative;
  background: -webkit-linear-gradient(#69bcff, #0072ff);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.head_of_department h2 img {
  position: absolute;
  right: 0;
  top: 0;
}
.head_of_department p {
  font-family: "Metropolis-Regular";
  font-size: 19px;
  color: #000;
  float: left;
  margin: 15px 0 0 0;
  padding: 0 0 0 10%;
  width: 75%;
  line-height: 27px;
}

.head_of_department_leadership {
  float: left;
  margin: 0;
  padding: 0;
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 338px;
  max-height: 338px;
}
.head_of_department_leadership img {
  float: none;
  border: none;
  max-width: 100%;
  background-color: #dbfdff;
  border-radius: 56%;
}
.head_of_department_leadership .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: -webkit-linear-gradient(#69bcff, #0072ff);
  overflow: hidden;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transition: 0.3s ease;
  border-radius: 56%;
}
.head_of_department_leadership:hover .overlay {
  transform: scale(1);
}
.head_of_department_leadership .text {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  text-align: center;
  width: 100%;
  padding: 0 5%;
}
.head_of_department_leadership .text h4 {
  font-family: "FivoSansModern-Bold";
  font-size: 35px;
  color: #ffffff;
  float: left;
  margin: 0;
  padding: 0 0 10px 15%;
}
.head_of_department_leadership .text p {
  font-family: "FivoSansModern-Regular";
  font-size: 30px;
  color: #ffffff;
  float: left;
  margin: 0;
  padding: 0 0 10px 15%;
  text-align: left;
  line-height: 30px;
}

.our_journey {
  float: left;
  margin: 20px 1% 20px 1%;
  padding: 0 0 30px 0;
  width: 98%;
  background-color: #000000;
  border-radius: 30px;
}
.our_journey h2 {
  font-family: "FivoSansModern-Heavy";
  font-size: 100px;
  line-height: 90px;
  float: left;
  margin: 0 0 0px 0;
  letter-spacing: -2px;
  padding: 90px 0 70px 0;
  width: 100%;
  text-align: center;
  color: #fff;
  position: relative;
  background: -webkit-linear-gradient(20deg, #ffd400, #fa8f11);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.no_margin {
  margin: 0 1% !important;
}

.swiper-container {
  width: 100%;
  height: auto;
  float: left;
  padding: 0 0 120px 0 !important;
}
.swiper-slide {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-family: monospace;
  letter-spacing: 0.08rem;
}
.swiper-slide img {
  position: relative;
}
.swiper-slide .info {
  position: absolute;
  left: 0;
  bottom: 20px;
  padding: 0 5%;
}
.swiper-slide .info h3 {
  font-family: "FivoSansModern-Heavy";
  font-size: 30px;
  line-height: 32px;
  color: #fed402;
  float: left;
  margin: 0;
  padding: 0;
  width: 60%;
}
.swiper-slide .info p {
  font-family: "Metropolis-Regular";
  font-size: 18px;
  color: #ffffff;
  line-height: 20px;
  float: left;
  margin: 0px 0 0 0;
  padding: 0 0 0 0;
  width: 60%;
}
.swiper-slide .info .year {
  font-family: "FivoSansModern-Heavy";
  font-size: 50px;
  line-height: 50px;
  float: left;
  margin: 0 0 0px 0;
  padding: 0px 0 0px 0;
  width: 40%;
  text-align: right;
  color: #fff;
  position: relative;
  background: -webkit-linear-gradient(270deg, #ffd400, #fa8f11);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.swiper-slide-active img.slide_top {
  position: absolute;
  opacity: 0 !important;
  z-index: -9;
}
.swiper-slide-prev img.slide_top {
  position: absolute;
  opacity: 0.5 !important;
  z-index: 9;
}
.swiper-slide-next img.slide_top {
  position: absolute;
  opacity: 0.5 !important;
  z-index: 9;
}
.swiper-slide-duplicate img.slide_top {
  position: absolute;
  opacity: 0.3;
  z-index: 9;
}
.swiper-slide img.slide_top {
  position: absolute;
  opacity: 0.8;
  z-index: 9;
}

.c1 {
}
.c2 {
}
.c3 {
}
.c4 {
}
.c5 {
}

.banner_tool {
  padding: 50px 0 0 0;
  float: left;
  background-color: #ffffff;
  margin: 0 1% 0px 1%;
  width: 98%;
}
.banner_tool h2 {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  font-family: "GothamMedium";
  font-size: 100px;
  color: #000;
  font-style: italic;
  letter-spacing: -5px;
  position: relative;
}
.banner_tool h2 span.star {
  position: absolute;
  top: -45px;
  margin: 0 0 0 -5px;
}
.banner_tool h2 img.tool_growing {
  margin: -6px 0 0 129px;
  position: absolute;
}
.banner_tool h5 {
  float: left;
  margin: 0px 0 40px 0;
  padding: 0;
  width: 100%;
  text-align: center;
  font-family: "FivoSansModern-Medium";
  font-size: 40px;
  color: #000;
  letter-spacing: -2px;
}
.gid_applifier_links {
  float: left;
  margin: 0 0 40px 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: center;
  min-height: 70px;
}
.gid_applifier_links a {
  float: none;
  margin: 0px 5px 0px 5px;
  width: auto;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  border-radius: 68px;
  padding: 30px 100px;
  border: 1px solid #969696;
  text-decoration: none;
  outline: none;
  text-transform: uppercase;
}
.gid_applifier_links a img {
  max-width: 100%;
  position: relative;
  top: 11px;
}
.tools_banner {
  float: left;
  margin: 0 0 100px 0;
  padding: 0;
  width: 100%;
  text-align: center;
}
.tools_banner img {
  border: none;
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

.insights_impact {
  float: left;
  padding: 60px 0;
  background-color: #ffffff;
  margin: 0 1% 0px 1%;
  width: 98%;
  border-radius: 0 0 30px 30px;
}
.insights_impact h2 {
  font-family: "FivoSansModern-Bold";
  font-size: 75px;
  color: #000;
  letter-spacing: -5px;
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 5%;
  text-align: left;
  width: 100%;
}
.insights_impact h2 img {
  position: absolute;
  margin: 0 0 0 10px;
}
.insights_impact h3 {
  font-family: "FivoSansModern-Bold";
  font-size: 40px;
  color: #000;
  letter-spacing: -2px;
  float: left;
  margin: 0 0 10px 0;
  padding: 0 0 0 0;
  text-align: left;
  width: 100%;
}
.insights_impact p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000;
  line-height: 27px;
  float: left;
  margin: 0px 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
}

.applifier {
  background-color: #ffffff;
  margin: 20px 1% 0px 1%;
  padding: 90px 0 0 0;
  width: 98%;
  float: left;
  border-radius: 30px;
}
.applifier img.applifier_left_img {
  max-width: 96%;
  border: none;
  margin: 30px 0 0 0;
}
.applifier h2 {
  font-family: "FivoSansModern-Bold";
  font-size: 72px;
  color: #000;
  letter-spacing: -3px;
  float: left;
  margin: 0 0 30px 0;
  padding: 0 0 0 0;
  text-align: center;
  width: 100%;
  background-image: linear-gradient(to right, #1116c6, #1116c6 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  -webkit-transition: background-position 1s ease;
  -moz-transition: background-position 1s ease;
  -o-transition: background-position 1s ease;
  transition: background-position 1s ease;
}
.applifier h2:hover {
  background-position: 0;
}
.applifier p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000;
  line-height: 27px;
  float: left;
  margin: 0px 0 100px 0;
  padding: 0 18%;
  width: 100%;
  text-align: center;
}
.applifier_info {
  float: left;
  margin: 0 0 120px 0;
  padding: 0 0 0 5%;
  width: 100%;
}
.applifier_info img {
  float: left;
  max-width: 100%;
  margin: 20px 0 20px 0;
}
.applifier_info h3 {
  font-family: "FivoSansModern-Bold";
  font-size: 50px;
  color: #000;
  letter-spacing: -2px;
  float: left;
  margin: 0 0 10px 0;
  padding: 0 0 0 0;
  text-align: left;
  width: 100%;
}
.applifier_info p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000;
  line-height: 27px;
  float: left;
  margin: 0px 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: left;
}
.applifier_info .applifier_links {
  float: left;
  margin: 20px 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: center;
}
.applifier_info .applifier_links a {
  float: left;
  margin: 0;
  width: auto;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  border-radius: 68px;
  padding: 16px 66px;
  border: 1px solid #969696;
  text-decoration: none;
  outline: none;
  text-transform: uppercase;
}

.bidding_system {
  float: left;
  margin: 0 0 0 0;
  padding: 56px 30px 40px 30px;
  width: 100%;
  background: -webkit-linear-gradient(270deg, #e8eaff, #f0e8ff);
  position: relative;
  border-radius: 30px;
  -webkit-transition: all 0.3s ease-in-out;
}
.bidding_system:hover {
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-transition: all 0.3s ease-in-out;
}
.bidding_system img {
  float: right;
  max-width: 40%;
  margin: 0;
}
.bidding_system h3 {
  font-family: "FivoSansModern-Bold";
  font-size: 42px;
  color: #000;
  letter-spacing: -2px;
  float: left;
  margin: 0px 0 10px 0;
  padding: 0 0 0 0;
  text-align: left;
  width: 60%;
}
.bidding_system p {
  font-family: "Metropolis-Regular";
  font-size: 17px;
  color: #000;
  line-height: 22px;
  float: left;
  margin: 0px 0 0 0;
  padding: 0 0 0 0;
  width: 60%;
  text-align: left;
}

.advanced_analytics {
  float: left;
  margin: 0 0 0 0;
  padding: 40px 30px 40px 30px;
  width: 100%;
  background: -webkit-linear-gradient(270deg, #e8eaff, #f0e8ff);
  position: relative;
  border-radius: 30px;
  -webkit-transition: all 0.3s ease-in-out;
}
.advanced_analytics:hover {
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-transition: all 0.3s ease-in-out;
}

.advanced_analytics img {
  float: right;
  max-width: 40%;
  margin: 0;
}
.advanced_analytics h3 {
  font-family: "FivoSansModern-Bold";
  font-size: 42px;
  color: #000;
  letter-spacing: -2px;
  float: left;
  margin: 0px 0 10px 0;
  padding: 0 0 0 0;
  text-align: left;
  width: 60%;
}
.advanced_analytics p {
  font-family: "Metropolis-Regular";
  font-size: 17px;
  color: #000;
  line-height: 22px;
  float: left;
  margin: 0px 0 0 0;
  padding: 0 0 0 0;
  width: 60%;
  text-align: left;
}

.applifier_system_info {
  float: left;
  margin: 20px 0;
  padding: 20px 20px 20px 20px;
  width: 100%;
  background: -webkit-linear-gradient(270deg, #e8eaff, #f0e8ff);
  position: relative;
  border-radius: 30px;
  min-height: 356px;
  -webkit-transition: all 0.3s ease-in-out;
}
.applifier_system_info:hover {
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-transition: all 0.3s ease-in-out;
}
.applifier_system_info .icon_system_info {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: right;
}
.applifier_system_info .icon_system_info img {
  float: right;
  max-width: 100%;
  margin: 0;
  width: 80px;
}
.applifier_system_info h3 {
  font-family: "FivoSansModern-Bold";
  font-size: 27px;
  color: #000;
  letter-spacing: -2px;
  float: left;
  margin: 10px 0 10px 0;
  padding: 0 0 0 0;
  text-align: left;
  width: 80%;
}
.applifier_system_info p {
  font-family: "Metropolis-Regular";
  font-size: 17px;
  color: #000;
  line-height: 22px;
  float: left;
  margin: 0px 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: left;
}

.go_beyond {
  float: left;
  margin: 0;
  padding: 90px 0 60px 0;
  width: 100%;
  text-align: center;
}
.go_beyond h2 {
  font-family: "FivoSansModern-Bold";
  font-size: 90px;
  color: #000;
  letter-spacing: -5px;
  float: left;
  margin: 0px 0 0px 0;
  padding: 0 0 0 0;
  text-align: center;
  width: 100%;
}
.go_beyond h3 {
  font-family: "FivoSansModern-Bold";
  font-size: 48px;
  color: #000;
  letter-spacing: -2px;
  float: left;
  margin: 0px 0 10px 0;
  padding: 0 0 0 0;
  text-align: center;
  width: 100%;
}
.go_beyond p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000;
  line-height: 27px;
  float: left;
  margin: 0px 0 40px 0;
  padding: 0 24%;
  width: 100%;
}
.go_beyond .get_started_link {
  float: none;
  margin: 0 auto;
  padding: 0 0 0 0;
  width: 226px;
  text-align: center;
}
.go_beyond .get_started_link a {
  float: left;
  margin: 0;
  width: auto;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  border-radius: 68px;
  padding: 16px 66px;
  border: 1px solid #969696;
  text-decoration: none;
  outline: none;
  text-transform: uppercase;
}

.we_help {
  float: left;
  margin: 0 0 20px 0;
  padding: 60px 50px 50px 50px;
  width: 100%;
  background: -webkit-linear-gradient(260deg, #b74ff4, #383ee8);
  border-radius: 30px;
}
.we_help h3 {
  font-family: "FivoSansModern-Bold";
  font-size: 42px;
  color: #ffffff;
  letter-spacing: -2px;
  float: left;
  margin: 0px 0 10px 0;
  padding: 0 0 0 0;
  text-align: left;
  width: 100%;
}
.we_help h4 {
  font-family: "FivoSansModern-Bold";
  font-size: 78px;
  color: #ffffff;
  letter-spacing: -5px;
  float: left;
  margin: 20px 0 0px 0;
  padding: 0 0 0 0;
  text-align: center;
  width: 100%;
  line-height: 90px;
}
.we_help p {
  font-family: "Metropolis-Regular";
  font-size: 18px;
  color: #ffffff;
  line-height: 27px;
  float: left;
  margin: 0px 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: center;
}

.gdi_area {
  background-color: #ffffff;
  margin: 20px 1% 0px 1%;
  padding: 90px 0 0px 0;
  width: 98%;
  float: left;
  border-radius: 30px;
}
.gdi_area h2 {
  font-family: "FivoSansModern-Bold";
  font-size: 68px;
  color: #000;
  letter-spacing: -3px;
  float: left;
  margin: 0 0 10px 0;
  line-height: 68px;
  padding: 0 0 0 0;
  text-align: center;
  width: 100%;
  background-image: linear-gradient(to right, #1116c6, #1116c6 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  -webkit-transition: background-position 1s ease;
  -moz-transition: background-position 1s ease;
  -o-transition: background-position 1s ease;
  transition: background-position 1s ease;
}
.gdi_area h2:hover {
  background-position: 0;
}
.gdi_area h4 {
  font-family: "FivoSansModern-Bold";
  font-size: 48px;
  color: #000000;
  letter-spacing: -2px;
  float: left;
  margin: 0px 0 0px 0;
  padding: 0 0 0 0;
  text-align: center;
  width: 100%;
  line-height: 72px;
}
.gdi_area p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000;
  line-height: 27px;
  float: left;
  margin: 0px 0 60px 0;
  padding: 0 19%;
  width: 100%;
  text-align: center;
}

.gdi_info {
  float: left;
  margin: 0 0 100px 0;
  padding: 0 0 0 0;
  width: 100%;
}
.gdi_info img {
  float: left;
  max-width: 100%;
  margin: 0px 0 20px 0;
  width: 300px;
}
.gdi_info h3 {
  font-family: "FivoSansModern-Bold";
  font-size: 50px;
  color: #000;
  letter-spacing: -2px;
  float: left;
  margin: 0 0 10px 0;
  padding: 0 0 0 0;
  text-align: left;
  width: 90%;
}
.gdi_info p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000;
  line-height: 27px;
  float: left;
  margin: 0px 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: left;
}
.gdi_info .gdi_links {
  float: left;
  margin: 30px 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: center;
}
.gdi_info .gdi_links a {
  float: left;
  margin: 0;
  width: auto;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  border-radius: 68px;
  padding: 16px 66px;
  border: 1px solid #969696;
  text-decoration: none;
  outline: none;
  text-transform: uppercase;
}

.monetization_mastermind {
  float: left;
  margin: 0 0 0 0;
  padding: 60px 30px 60px 40px;
  width: 100%;
  background: -webkit-linear-gradient(270deg, #ecf5f4, #eaf0fe);
  position: relative;
  border-radius: 30px;
  -webkit-transition: all 0.3s ease-in-out;
}
.monetization_mastermind:hover {
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-transition: all 0.3s ease-in-out;
}
.monetization_mastermind img {
  float: right;
  max-width: 50%;
  margin: 0;
}
.monetization_mastermind h3 {
  font-family: "FivoSansModern-Bold";
  font-size: 42px;
  color: #000;
  letter-spacing: -2px;
  float: left;
  margin: 15px 0 10px 0;
  padding: 0 0 0 0;
  text-align: left;
  width: 50%;
}
.monetization_mastermind p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000;
  line-height: 27px;
  float: left;
  margin: 0px 0 0 0;
  padding: 0 0 0 0;
  width: 50%;
  text-align: left;
}

.benchmark {
  float: left;
  margin: 0 0 0 0;
  padding: 60px 30px 60px 40px;
  width: 100%;
  background: -webkit-linear-gradient(310deg, #ecf5f4, #eaf0fe);
  position: relative;
  border-radius: 30px;
  -webkit-transition: all 0.3s ease-in-out;
}
.benchmark:hover {
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-transition: all 0.3s ease-in-out;
}

.benchmark img {
  float: right;
  max-width: 50%;
  margin: 0;
}
.benchmark h3 {
  font-family: "FivoSansModern-Bold";
  font-size: 42px;
  color: #000;
  letter-spacing: -2px;
  float: left;
  margin: 15px 0 10px 0;
  padding: 0 0 0 0;
  text-align: left;
  width: 50%;
}
.benchmark p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000;
  line-height: 27px;
  float: left;
  margin: 0px 0 0 0;
  padding: 0 0 0 0;
  width: 50%;
  text-align: left;
}

.gdi_system_info {
  float: left;
  margin: 20px 0;
  padding: 20px 20px 20px 20px;
  width: 100%;
  background: -webkit-linear-gradient(270deg, #ecf5f4, #eaf0fe);
  position: relative;
  border-radius: 30px;
  min-height: 400px;
  -webkit-transition: all 0.3s ease-in-out;
}
.gdi_system_info:hover {
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-transition: all 0.3s ease-in-out;
}
.gdi_system_info .icon_system_info {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: right;
}
.gdi_system_info .icon_system_info img {
  float: right;
  max-width: 100%;
  margin: 0;
  width: 80px;
}
.gdi_system_info h3 {
  font-family: "FivoSansModern-Bold";
  font-size: 30px;
  color: #000;
  letter-spacing: -2px;
  float: left;
  margin: 20px 0 10px 0;
  padding: 0 0 0 0;
  text-align: left;
  width: 85%;
}
.gdi_system_info p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000;
  line-height: 25px;
  float: left;
  margin: 0px 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: left;
}

.effortlessly_insights {
  float: left;
  margin: 0;
  padding: 90px 0 60px 0;
  width: 100%;
  text-align: center;
}
.effortlessly_insights h2 {
  font-family: "FivoSansModern-Bold";
  font-size: 100px;
  color: #000;
  letter-spacing: -5px;
  float: left;
  margin: 0px 0 0px 0;
  padding: 0 0 0 0;
  text-align: center;
  width: 100%;
}
.effortlessly_insights h3 {
  font-family: "FivoSansModern-Bold";
  font-size: 50px;
  color: #000;
  letter-spacing: -2px;
  float: left;
  margin: 0px 0 10px 0;
  padding: 0 0 0 0;
  text-align: center;
  width: 100%;
}
.effortlessly_insights p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000;
  line-height: 27px;
  float: left;
  margin: 0px 0 40px 0;
  padding: 0 32%;
  width: 100%;
}
.effortlessly_insights .get_started_link {
  float: none;
  margin: 0 auto;
  padding: 0 0 0 0;
  width: 226px;
  text-align: center;
}
.effortlessly_insights .get_started_link a {
  float: left;
  margin: 0;
  width: auto;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  border-radius: 68px;
  padding: 16px 66px;
  border: 1px solid #969696;
  text-decoration: none;
  outline: none;
  text-transform: uppercase;
}

.we_help_gdi {
  float: left;
  margin: 0 0 20px 0;
  padding: 60px 50px 50px 50px;
  width: 100%;
  background: -webkit-linear-gradient(290deg, #dafffb, #a2c7fe);
  border-radius: 30px;
}
.we_help_gdi h3 {
  font-family: "FivoSansModern-Bold";
  font-size: 42px;
  color: #000000;
  letter-spacing: -2px;
  float: left;
  margin: 0px 0 10px 0;
  padding: 0 0 0 0;
  text-align: left;
  width: 100%;
}
.we_help_gdi h4 {
  font-family: "FivoSansModern-Bold";
  font-size: 78px;
  color: #000000;
  letter-spacing: -5px;
  float: left;
  margin: 20px 0 0px 0;
  padding: 0 0 0 0;
  text-align: center;
  width: 100%;
  line-height: 90px;
}
.we_help h4 span {
  width: 20px;
}
.we_help_gdi p {
  font-family: "Metropolis-Regular";
  font-size: 18px;
  color: #000000;
  line-height: 27px;
  float: left;
  margin: 0px 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: center;
}

.banner_blog {
  padding: 50px 0 110px 0;
  background-color: #ffffff;
  margin: 0 1% 0px 1%;
  width: 98%;
  float: left;
  border-radius: 0 0 30px 30px;
}
.blog_banner {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 9;
}

.blog_banner_content2 {
  padding: 230px 0 0 0;
  text-align: center;

  grid-area: intro;
  place-items: center;
  margin: 0;
  display: grid;
  position: relative;
  z-index: 100;
}
.blog_banner_content2 h2 {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  font-family: "GothamMedium";
  font-size: 84px;
  color: #e3e3e3;
  font-style: italic;
  letter-spacing: -5px;
  position: relative;
}
.blog_banner_content2 h3 {
  float: left;
  margin: 0;
  padding: 0 0 70px 0;
  width: 100%;
  text-align: center;
  font-family: "GothamMedium";
  font-size: 84px;
  color: #e3e3e3;
  font-style: italic;
  letter-spacing: -5px;
  position: relative;
  background-image: url("../images/blog_banner_icon_bottom.png");
  background-repeat: no-repeat;
  background-size: 60px;
  background-position: center 84px;
}

.blog_banner_content {
  float: left;
  margin: 0;
  padding: 230px 0 0 0;
  width: 100%;
  text-align: center;
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
}
.blog_banner_content h2 {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  font-family: "GothamMedium";
  font-size: 84px;
  color: #fff;
  font-style: italic;
  letter-spacing: -5px;
  position: relative;
}
.blog_banner_content h3 {
  float: left;
  margin: 0;
  padding: 0 0 70px 0;
  width: 100%;
  text-align: center;
  font-family: "GothamMedium";
  font-size: 84px;
  color: #fff;
  font-style: italic;
  letter-spacing: -5px;
  position: relative;
  background-image: url("../images/blog_banner_icon_bottom.png");
  background-repeat: no-repeat;
  background-size: 60px;
  background-position: center 84px;
}

.banner_blog h2 span.star {
  position: absolute;
  top: -45px;
  margin: 0 0 0 -5px;
}
.banner_blog h2 img.blog_banner_icon {
  margin: -20px 0 0 -40px;
  position: absolute;
  width: 80px;
}
.banner_blog h2 img.blog_banner_icon_play {
  margin: -40px 0 0 -10px;
  position: absolute;
  width: 244px;
}
.banner_blog h2 img.blog_banner_icon_right {
  margin: 40px 0 0 10px;
  position: absolute;
  width: 70px;
}

.latest_blogs {
  background-color: #ffffff;
  margin: 20px 1% 0px 1%;
  padding: 90px 0 0 0;
  width: 98%;
  float: left;
  border-radius: 30px;
}
.latest_blogs h2 span.latest_blogs_icon {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
}
.latest_blogs h2 span.latest_blogs_icon img {
  border: none;
  max-width: 100%;
}
.latest_blogs h2 {
  font-family: "FivoSansModern-Heavy";
  font-size: 100px;
  color: #000;
  letter-spacing: 0px;
  float: left;
  margin: 0 0 10px 0;
  padding: 0 0 0 0;
  text-align: center;
  width: 100%;
  background-image: linear-gradient(to right, #1116c6, #1116c6 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  -webkit-transition: background-position 1s ease;
  -moz-transition: background-position 1s ease;
  -o-transition: background-position 1s ease;
  transition: background-position 1s ease;
}
.latest_blogs h2:hover {
  background-position: 0;
}
.latest_blogs p {
  font-family: "Metropolis-Regular";
  font-size: 19px;
  color: #000;
  line-height: 27px;
  float: left;
  margin: 0px 0 100px 0;
  padding: 0 0;
  width: 100%;
  text-align: center;
}

.featured_post {
  float: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 1080px;
}

.featured_post_info {
  float: left;
  margin: 0 0 0 0;
  padding: 20px 240px 20px 80px;
  width: 100%;
}
.featured_post_info .post_date {
  font-family: "Metropolis-Regular";
  font-size: 18px;
  color: #000000;
  float: left;
  margin: 20px 0 0px 0;
  padding: 0 0 0 0;
  width: 100%;
}
.featured_post_info h3 {
  font-family: "Metropolis-ExtraBold";
  font-size: 50px;
  color: #000;
  float: left;
  margin: 0px 0 20px 0;
  padding: 0 0 0 0;
  width: 100%;
  background-image: linear-gradient(to right, #1116c6, #1116c6 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  -webkit-transition: background-position 1s ease;
  -moz-transition: background-position 1s ease;
  -o-transition: background-position 1s ease;
  transition: background-position 1s ease;
}
.featured_post_info h3:hover {
  background-position: 0;
}
.featured_post_info p {
  font-family: "Metropolis-Regular";
  font-size: 16px;
  color: #000000;
  float: left;
  margin: 0px 0 30px 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: left;
}
.featured_post_info .read_more {
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
}
.featured_post_info .read_more a {
  float: left;
  margin: 0px 0 0px 0;
  width: auto;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  border-radius: 68px;
  padding: 16px 32px;
  border: 1px solid #969696;
  text-decoration: none;
  outline: none;
  text-transform: uppercase;
}

.post_search {
  float: left;
  margin: 40px 0 30px 0;
  padding: 0 5%;
  width: 100%;
}
.post_search .select {
  float: left;
  margin: 0px 0 10px 0;
  width: auto;
  border: 1px solid #b3b3b3;
  border-radius: 68px;
  padding: 16px 32px;
}
.post_search .select select {
  text-indent: 1px;
  text-overflow: "";
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0 20px 0 0;
  border: none;
  background-color: #fff;
  background-image: url("../images/select.png");
  background-repeat: no-repeat;
  background-position: 100% 3px;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000000;
  text-transform: uppercase;
}
.post_search .select select option {
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000000;
  text-transform: uppercase;
}
.post_search .post_search_filed {
  float: left;
  margin: 0;
  padding: 0;
  width: auto;
}
.post_search_filed input {
  float: left;
  margin: 0px 0 10px 40px;
  width: 100%;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000000;
  text-transform: uppercase;
  border: 1px solid #b3b3b3;
  border-radius: 68px;
  padding: 16px 32px 16px 70px;
  background-image: url("../images/search_btn.png");
  background-repeat: no-repeat;
  background-position: 32px 12px;
}
.post_search ::placeholder {
  color: #000000;
  opacity: 1; /* Firefox */
}
.post_search ::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #000000;
}
.all_post {
  float: left;
  margin: 0 0 60px 0;
  padding: 0;
  width: 100%;
}

.latest_blogs .blog_post {
  float: left;
  margin: 0 0 30px 0;
  padding: 15px;
  width: 100%;
  border-radius: 30px;
  background-color: #f3f4f8; /* For browsers that do not support gradients */
  background-image: linear-gradient(#f3f4f8, #ccc5f8);
  min-height: 290px;
}
.blog_post .post_img {
  float: left;
  margin: 0;
  padding: 0;
  width: 33%;
}
.blog_post .post_content_info {
  float: left;
  margin: 0;
  padding: 0 0 0 4%;
  width: 65%;
}
.blog_post .post_content_info .post_date {
  font-family: "Metropolis-Regular";
  font-size: 18px;
  color: #000000;
  float: left;
  margin: 0px 0 10px 0;
  padding: 0 0 0 0;
  width: 100%;
}
.blog_post .post_content_info h3 {
  font-family: "Metropolis-ExtraBold";
  font-size: 24px;
  color: #000;
  float: left;
  margin: 0px 0 10px 0;
  padding: 0 0 0 0;
  width: 100%;
}
.blog_post .post_content_info p {
  font-family: "Metropolis-Regular";
  font-size: 16px;
  color: #000000;
  float: left;
  margin: 0px 0 20px 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: left;
  line-height: 22px;
}
.blog_post .post_content_info .read_more {
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
}
.blog_post .post_content_info .read_more a {
  float: right;
  margin: 0px 0 0px 0;
  width: auto;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  border-radius: 68px;
  padding: 16px 32px;
  border: 1px solid #969696;
  text-decoration: none;
  outline: none;
  text-transform: uppercase;
}
.post_block_more {
  display: none;
}
.btn_more_post {
  float: left;
  margin: 30px 0 0 0;
  padding: 0;
  width: 100%;
}
.btn_more_post #load_more {
  float: none;
  margin: 0 auto;
  width: 100%;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  border-radius: 68px;
  padding: 16px 32px;
  border: 1px solid #969696;
  text-decoration: none;
  outline: none;
  text-transform: uppercase;
  clear: both;
  text-align: center;
  max-width: 192px;
}

.banner_games {
  padding: 50px 0 30px 0;
  background-color: #ffffff;
  margin: 0 1% 0px 1%;
  width: 98%;
  border-radius: 0 0 30px 30px;
  float: left;
}
.banner_games h2 {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  font-family: "Gotham-Bold";
  font-size: 90px;
  color: #000000;
  letter-spacing: -5px;
  position: relative;
}
.banner_games h3 {
  float: left;
  margin: -20px 0 0 0;
  padding: 0 0 70px 0;
  width: 100%;
  text-align: center;
  font-family: "GothamMedium";
  font-size: 90px;
  color: #000000;
  font-style: italic;
  letter-spacing: -5px;
  position: relative;
}
.banner_games h2 img.games_icon_love {
  margin: 22px 0 0 10px;
  position: absolute;
  width: 75px;
}
.banner_games h3 img.games_icon_title_left {
  margin: -17px 0 0 -55px;
  position: absolute;
  width: 110px;
}
.banner_games h3 img.games_icon_title_right {
  margin: 4px 0 0 -32px;
  position: absolute;
  width: 135px;
}

.logos_games {
  float: left;
  margin: 0 0 90px 0;
  padding: 0 0 0 0;
  width: 100%;
}
.logo_animation {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
  height: auto;
  clear: both;
}
.logo_animation img {
  width: 150px;
  height: 150px;
  margin: 5px;
  border-radius: 30px;
}
.icons-animation {
  width: 200%;
}
.icons-animation-toleft {
  margin-left: -100% !important;
  animation: icons-animation-toleft 25s infinite linear;
  position: relative;
}
@keyframes icons-animation-toleft {
  from {
    left: 100%;
  }
  to {
    left: 0%;
  }
}

.icons-animation-toright {
  margin-left: -100% !important;
  animation: icons-animation-toright 25s infinite linear;
  position: relative;
}
@keyframes icons-animation-toright {
  from {
    left: 0%;
  }
  to {
    left: 100%;
  }
}
.opacity {
}

.our_favorite_games {
  background-color: #ffffff;
  margin: 20px 1% 0px 1%;
  padding: 120px 0 100px 0;
  width: 98%;
  float: left;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}
.our_favorite_games img.icon_our_favorite_games {
  position: relative;
  float: right;
  margin: -30px 0 0 0;
  width: 120px;
}
.our_favorite_games h1 {
  font-family: "FivoSansModern-Heavy";
  font-size: 100px;
  color: #000;
  float: none;
  width: auto;
  margin: -90px 0 0 5%;
  padding: 0;
  line-height: 82px;
  position: relative;
}
.our_favorite_games h1 a {
  text-decoration: none;
  outline: none;
}
.our_favorite_games h1 a img {
  position: relative;
  margin: 0;
  padding: 0;
  width: 56px;
}
.our_favorite_games h1 img.favoritegame-title-icon {
  position: relative;
  margin: 0;
  padding: 0;
  width: 68px;
}
.our_favorite_games ul.games_type {
  float: left;
  margin: 0 0 0 5%;
  padding: 0 0 0 0;
  width: 100%;
  list-style: none;
  text-align: center;
}
.our_favorite_games ul.games_type li {
  float: left;
  margin: 3px;
  padding: 0 0 0 0;
  width: auto;
  list-style: none;
}
.our_favorite_games ul.games_type li a {
  float: left;
  margin: 0;
  padding: 8px 30px;
  width: auto;
  list-style: none;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  text-transform: uppercase;
  border: 1px solid #4a4a4a;
  border-radius: 30px;
}
.our_favorite_games .content_area {
  float: left;
  margin: 30px 0 0 5%;
  padding: 0 0 0 0;
  width: 75%;
}
.our_favorite_games .content_area p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000;
  float: left;
  margin: 0px 0 30px 0;
  padding: 0 0 0 0;
  width: 100%;
}

.games_info {
  float: left;
  margin: 120px 0 60px 0;
  padding: 0;
  width: 100%;
  border-radius: 30px;
  position: relative;
  max-height: 390px;
}

.games_info .games_icon_left {
  float: left;
  margin: -40px 0 0 20px;
  padding: 0 0 0 0px;
  width: 31%;
  z-index: 99;
  position: relative;
}

.games_info .games_icon_left img {
  border: none;
  max-width: 100%;
  width: 185px;
}

.games_info .games_icon_right {
  float: right;
  margin: -40px 20px 0 0px;
  padding: 0;
  width: 31%;
  position: relative;
}
.games_info .games_icon_right img {
  border: none;
  max-width: 100%;
  width: 185px;
}

.games_info .about_games {
  float: left;
  margin: 0 0 0 -200px;
  padding: 40px 0 40px 215px;
  width: 98%;
  background-color: #bef3ff;
  border-radius: 30px;
  position: relative;
}

.about_games .game_title_icon {
  float: left;
  margin: 0;
  padding: 0px 0 0 0px;
  width: 100%;
}
.about_games .game_title_icon .icon_game {
  float: left;
  margin: 0;
  padding: 0;
  width: 100px;
}
.about_games .game_title_icon .icon_game img {
  border: none;
  max-width: 100%;
  width: 90px;
}
.about_games .game_title_icon h5 {
  font-family: "Metropolis-Bold";
  font-size: 20px;
  color: #000000;
  float: left;
  margin: 5px 0 0 0;
  padding: 0;
  width: 70%;
}
.about_games .game_title_icon h3 {
  font-family: "FivoSansModern-Heavy";
  font-size: 30px;
  color: #000;
  float: left;
  margin: 0;
  padding: 0;
  width: 70%;
  line-height: 32px;
}

.about_games ul.games_type1 {
  float: left;
  margin: 10px 0 15px 0;
  padding: 0 0 0 0;
  width: 100%;
  list-style: none;
  text-align: center;
}
.about_games ul.games_type1 li {
  float: left;
  margin: 3px;
  padding: 0 0 0 0;
  width: auto;
  list-style: none;
}
.about_games ul.games_type1 li a {
  float: left;
  margin: 0;
  padding: 5px 18px;
  width: auto;
  list-style: none;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  text-transform: uppercase;
  border: 1px solid #4a4a4a;
  border-radius: 30px;
}
.about_games p {
  font-family: "Metropolis-Regular";
  font-size: 18px;
  color: #000;
  float: left;
  margin: 0px 0 20px 0;
  padding: 0 0 0 0;
  width: 90%;
}
.about_games .store_links {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}
.about_games .store_links a {
  float: left;
  margin: 0 15px 0 0;
  padding: 12px 26px;
  width: auto;
  list-style: none;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  text-transform: uppercase;
  border: 1px solid #4a4a4a;
  border-radius: 30px;
  line-height: 24px;
}

.about_games .store_links a img {
  float: left;
  border: none;
  max-width: 100%;
  margin: 0 10px 0 0;
}

.games_info .about_games_right {
  float: left;
  margin: 0 -200px 0 0;
  padding: 40px 200px 40px 20px;
  width: 98%;
  background-color: #bef3ff;
  border-radius: 30px;
  position: relative;
}

.about_games_right .game_title_icon {
  float: left;
  margin: 0;
  padding: 0px 0 0 0px;
  width: 100%;
}
.about_games_right .game_title_icon .icon_game {
  float: left;
  margin: 0;
  padding: 0;
  width: 100px;
}
.about_games_right .game_title_icon .icon_game img {
  border: none;
  max-width: 100%;
  width: 90px;
}
.about_games_right .game_title_icon h5 {
  font-family: "Metropolis-Bold";
  font-size: 20px;
  color: #000000;
  float: left;
  margin: 5px 0 0 0;
  padding: 0;
  width: 70%;
}
.about_games_right .game_title_icon h3 {
  font-family: "FivoSansModern-Heavy";
  font-size: 30px;
  color: #000;
  float: left;
  margin: 0;
  padding: 0;
  width: 70%;
  line-height: 32px;
}

.about_games_right ul.games_type1 {
  float: left;
  margin: 10px 0 15px 0;
  padding: 0 0 0 0;
  width: 100%;
  list-style: none;
  text-align: center;
}
.about_games_right ul.games_type1 li {
  float: left;
  margin: 3px;
  padding: 0 0 0 0;
  width: auto;
  list-style: none;
}
.about_games_right ul.games_type1 li a {
  float: left;
  margin: 0;
  padding: 5px 18px;
  width: auto;
  list-style: none;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  text-transform: uppercase;
  border: 1px solid #4a4a4a;
  border-radius: 30px;
}
.about_games_right p {
  font-family: "Metropolis-Regular";
  font-size: 18px;
  color: #000;
  float: left;
  margin: 0px 0 20px 0;
  padding: 0 0 0 0;
  width: 90%;
}
.about_games_right .store_links {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}
.about_games_right .store_links a {
  float: left;
  margin: 0 15px 0 0;
  padding: 12px 26px;
  width: auto;
  list-style: none;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  text-transform: uppercase;
  border: 1px solid #4a4a4a;
  border-radius: 30px;
  line-height: 24px;
}

.about_games_right .store_links a img {
  float: left;
  border: none;
  max-width: 100%;
  margin: 0 10px 0 0;
}

.our_awesome_studios {
  background-color: #ffffff;
  margin: 20px 1% 0px 1%;
  padding: 120px 0 100px 0;
  width: 98%;
  float: left;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}
.our_awesome_studios img.icon_our_awesome_studios {
  position: relative;
  float: right;
  margin: -30px 0 0 0;
  width: 120px;
}
.our_awesome_studios h1 {
  font-family: "FivoSansModern-Heavy";
  font-size: 100px;
  color: #000;
  float: none;
  width: auto;
  margin: -25px 0 0 10%;
  padding: 0;
  line-height: 82px;
  position: relative;
}
.our_awesome_studios h1 span {
  color: #ec2d5b;
}
.our_awesome_studios h1 img.awesome_studios_title-icon {
  position: absolute;
  margin: -25px 0 0 15px;
  z-index: 99;
}

.our_awesome_studios .content_area {
  float: left;
  margin: 20px 0 0 5%;
  padding: 0 0 0 0;
  width: 75%;
}
.our_awesome_studios .content_area p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000;
  float: left;
  margin: 0px 0 30px 0;
  padding: 0 0 0 0;
  width: 100%;
}

.studios_logo_scroll {
  float: left;
  margin: 0px 0 0 0;
  padding: 0;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1;
}
.studios_logo_scroll2 {
  float: left;
  margin: -90px 0 0 0;
  padding: 0;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1;
}
.our_studios_logo {
  float: left;
  margin: 10px;
  padding: 70px 0 0 0;
  width: 285px;
  background-color: #f7f7f7;
  border-radius: 30px;
  text-align: center;
  height: 285px;
}
.studios_icon {
  float: left;
  margin: -90px 0 0 0;
  padding: 0;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 9;
}

ul.accordion-list {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  padding: 0px;
  margin: 120px 0 0 0;
  list-style: none;
  background-color: #ffffff;
}
ul.accordion-list li {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  background-color: #fff;
  padding: 40px 90px;
  margin: 0 0 0 0;
  border: 1px solid #b3b3b3;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(179, 179, 179);
  border-radius: 60px 60px 0 0;
  cursor: pointer;
  border-bottom: 0;
}
ul.accordion-list li.active h3:after {
  content: "-";
  position: absolute;
  left: -30px;
  top: 0;
  color: #000;
  transition: all 0.3s ease-in-out;
  font-size: 24px;
}
ul.accordion-list li h3 {
  font-family: "FivoSansModern-Regular";
  font-size: 25px;
  color: #000;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  padding: 0 0 0 0;
  margin: 0;
  text-transform: uppercase;
}
ul.accordion-list li h3:after {
  content: "+";
  position: absolute;
  left: -30px;
  top: 0;
  color: #000;
  transition: all 0.3s ease-in-out;
  font-size: 24px;
}
.answer {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 30px 0;
}
.answer .studios_info {
  float: left;
  margin: 0;
  padding: 10px 0 0 0;
  width: 100%;
  text-align: center;
}
.answer .studios_info p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000;
  float: left;
  margin: 30px 0 30px 0;
  padding: 0 0 0 0;
  width: 100%;
}
.answer .studios_info .store_links {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}
.answer .studios_info .store_links a {
  float: left;
  margin: 0 18px;
  padding: 16px 40px;
  width: auto;
  list-style: none;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  text-transform: uppercase;
  border: 1px solid #4a4a4a;
  border-radius: 30px;
  line-height: 24px;
}
.answer .studios_info .store_links a img {
  float: left;
  border: none;
  max-width: 100%;
  margin: 0 10px 0 0;
}
.games_icons_row {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}
.games_icons_row img {
  float: right;
  margin: 0 0 15px 15px;
  padding: 0;
  width: 110px;
  max-width: 100%;
  cursor: pointer;
}

.banner_ip {
  padding: 50px 0 120px 0;
  float: left;
  background-image: url("../images/ip_banner_bg_right.png");
  background-repeat: no-repeat;
  background-position: right 200px;
  overflow: hidden;
  background-color: #ffffff;
  margin: 0 1% 0px 1%;
  width: 98%;
  border-radius: 0 0 30px 30px;
}
.banner_ip_top {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  background-image: url("../images/ip_banner_bg_left.png");
  background-repeat: no-repeat;
  background-position: 80px 40px;
}
.banner_ip h2 {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  font-family: "GothamMedium";
  font-size: 84px;
  color: #000000;
  letter-spacing: -5px;
  position: relative;
  font-style: italic;
}
.banner_ip h3 {
  float: left;
  margin: 10px 0 40px 0;
  padding: 0 0 0px 0;
  width: 100%;
  text-align: center;
  font-family: "Gotham-Bold";
  font-size: 44px;
  color: #000000;
  letter-spacing: -3px;
  position: relative;
}
.banner_ip h2 img.star_ip {
  margin: 0px 0 0 -10px;
  position: absolute;
}

.btn_get_started {
  float: left;
  margin: 0px 0 0 0;
  padding: 0;
  width: 100%;
  text-align: center;
  display: flex;
}
.btn_get_started a {
  float: left;
  margin: 0 auto;
  width: 222px;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  border-radius: 68px;
  padding: 16px 64px;
  border: 1px solid #969696;
  text-decoration: none;
  outline: none;
  text-transform: uppercase;
  clear: both;
  text-align: center;
}
.ip_banner {
  float: left;
  margin: 0px 0 0 0;
  padding: 0;
  width: 100%;
  position: relative;

  background-image: url("https://gamedistrictpublishing.com/GD/assets/images/ip_banner_bg.png");
  background-repeat: no-repeat;
  background-position: center 100px;
}

.ip_banner .parallax-image-inner {
  position: relative;
  z-index: 99;
}
.ip_banner .parallax-image-wrap {
  overflow: unset !important;
}

.craft_worlds_img {
  float: left;
  margin: 0px 0 0 0;
  padding: 0;
  width: 100%;
  position: relative;
  background-image: url("https://gamedistrictpublishing.com/GD/assets/images/craft_worlds_bg.png");
  background-repeat: no-repeat;
  background-position: center 70px;
  background-size: 100%;
  min-height: 380px;
}

.craft_worlds_img .parallax-image-inner {
  position: relative;
  z-index: 99;
}
.craft_worlds_img .parallax-image-wrap {
  overflow: unset !important;
}
.craft_worlds_img img.parallax-image {
  max-width: 86%;
  float: right;
  margin: -100px 5% 0 0;
}

.pixels_code {
  background-color: #ffffff;
  margin: 20px 1% 0px 1%;
  padding: 90px 0 90px 0;
  width: 98%;
  float: left;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}
.banner_pixels_code {
  float: left;
  margin: 0;
  padding: 0 0;
  width: 100%;
}
.pixels_code h2 {
  font-family: "FivoSansModern-Regular";
  font-size: 72px;
  color: #000;
  float: none;
  width: auto;
  margin: 100px 0 0 0;
  padding: 0 16%;
  line-height: 84px;
  position: relative;
  text-align: center;
}

.craft_worlds {
  float: left;
  margin: 0;
  padding: 50px 0 0 0;
  width: 100%;
}
.craft_worlds h3 {
  font-family: "FivoSansModern-Bold";
  font-size: 60px;
  color: #000000;
  letter-spacing: -3px;
  float: left;
  margin: 70px 0 10px 0;
  padding: 0 0 0 0;
  text-align: left;
  width: 100%;
  background-image: linear-gradient(to right, #1116c6, #1116c6 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  -webkit-transition: background-position 1s ease;
  -moz-transition: background-position 1s ease;
  -o-transition: background-position 1s ease;
  transition: background-position 1s ease;
}
.craft_worlds h3:hover {
  background-position: 0;
}
.craft_worlds p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}

.gameverse_monetize {
  background-color: #ffffff;
  margin: 20px 1% 0px 1%;
  padding: 60px 0 40px 0;
  width: 98%;
  float: left;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}
.gameverse_monetize img.forge_your_gameverse_icon {
  position: relative;
  float: right;
  margin: 0 0px 0 0;
  z-index: 99;
  width: 120px;
}
.gameverse_monetize img.level_up_icon {
  position: relative;
  float: left;
  margin: 0 0px 0 0px;
  z-index: 99;
  width: 120px;
}

.gameverse_monetize img.game_img {
  float: left;
  margin: 70px 0 0 0;
  max-width: 100%;
  border: none;
}

.gameverse_monetize_img {
  float: left;
  margin: 40px 0 0 0;
  padding: 0;
  width: 100%;
  position: relative;
  background-image: url("https://gamedistrictpublishing.com/GD/assets/images/gameverse_monetize_img_bg.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100%;
  min-height: 380px;
}

.gameverse_monetize_img .parallax-image-inner {
  position: relative;
  z-index: 99;
}
.gameverse_monetize_img .parallax-image-wrap {
  overflow: unset !important;
}
.gameverse_monetize_img img.parallax-image {
  max-width: 80%;
  float: right;
  margin: -100px 10% 0 0;
}

.level_up_game_img {
  float: left;
  margin: 40px 0 0 0;
  padding: 0;
  width: 100%;
  position: relative;
  background-image: url("https://gamedistrictpublishing.com/GD/assets/images/level_up_game_icon_bg.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100%;
  min-height: 450px;
}

.level_up_game_img .parallax-image-inner {
  position: relative;
  z-index: 99;
}
.level_up_game_img .parallax-image-wrap {
  overflow: unset !important;
}
.level_up_game_img img.parallax-image {
  max-width: 96%;
  float: right;
  margin: -100px 2% 0 0;
}

.monetize_game_img {
  float: left;
  margin: 40px 0 0 0;
  padding: 0;
  width: 100%;
  position: relative;
  background-image: url("https://gamedistrictpublishing.com/GD/assets/images/monetize_game_icon_bg.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100%;
  min-height: 450px;
}

.monetize_game_img .parallax-image-inner {
  position: relative;
  z-index: 99;
}
.monetize_game_img .parallax-image-wrap {
  overflow: unset !important;
}
.monetize_game_img img.parallax-image {
  max-width: 96%;
  float: right;
  margin: -100px 2% 0 0;
}

.ip_content_image {
  float: left;
  margin: 40px 0 0 0;
  padding: 0;
  width: 100%;
  position: relative;
  background-image: url("https://gamedistrictpublishing.com/GD/assets/images/ip_content_img.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100%;
  min-height: 600px;
}

.ip_content_image .parallax-image-inner {
  position: relative;
  z-index: 99;
}
.ip_content_image .parallax-image-wrap {
  overflow: unset !important;
}
.ip_content_image img.parallax-image {
  max-width: 80%;
  float: right;
  margin: -100px 8% 0 0;
}

.gameverse_monetize_content_left {
  float: left;
  margin: 0;
  padding: 0 0 0 0;
  width: 90%;
  clear: both;
}
.gameverse_monetize_content_right {
  float: left;
  margin: 0;
  padding: 0 0 0 5%;
  width: 100%;
}
.gameverse_monetize h3 {
  font-family: "FivoSansModern-Bold";
  font-size: 60px;
  color: #000000;
  letter-spacing: -3px;
  float: left;
  margin: 100px 0 10px 0;
  padding: 0 0 0 0;
  text-align: left;
  width: 100%;
  background-image: linear-gradient(to right, #1116c6, #1116c6 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  -webkit-transition: background-position 1s ease;
  -moz-transition: background-position 1s ease;
  -o-transition: background-position 1s ease;
  transition: background-position 1s ease;
}
.gameverse_monetize h3:hover {
  background-position: 0;
}
.gameverse_monetize p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  float: left;
  margin: 0;
  padding: 0 0 0 0;
  width: 100%;
}
.btn_dream_game {
  float: left;
  margin: 30px 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
}
.btn_dream_game a {
  float: left;
  margin: 0 0 0 0;
  width: auto;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  border-radius: 68px;
  padding: 16px 48px;
  border: 1px solid #969696;
  text-decoration: none;
  outline: none;
  text-transform: uppercase;
  clear: both;
  text-align: center;
}

.roadmap {
  background-color: #ffffff;
  margin: 20px 1% 0px 1%;
  padding: 90px 0 50px 0;
  width: 98%;
  float: left;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}
.roadmap img.roadmap_icon {
  position: relative;
  float: right;
  margin: 0px 0 0 0;
  width: 120px;
}
.roadmap h2 {
  font-family: "FivoSansModern-Bold";
  font-size: 84px;
  color: #000;
  float: none;
  width: auto;
  margin: 100px 0 0 0;
  padding: 0 0 0 40px;
  line-height: 84px;
  position: relative;
  text-align: left;
  background-image: linear-gradient(to right, #1116c6, #1116c6 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  -webkit-transition: background-position 1s ease;
  -moz-transition: background-position 1s ease;
  -o-transition: background-position 1s ease;
  transition: background-position 1s ease;
}
.roadmap h2:hover {
  background-position: 0;
}
.roadmap .roadmap_info {
  float: left;
  margin: 0;
  padding: 190px 0 0 0;
  width: 100%;
}
.roadmap_info p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}
.roadmap_services {
  float: left;
  margin: 70px 0 0 0;
  padding: 30px;
  width: 100%;
  background-color: #bef3ff;
  border-radius: 30px;
  -webkit-transition: all 0.3s ease-in-out;
}
.roadmap_services:hover {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-transition: all 0.3s ease-in-out;
}

.roadmap_services .icon_number {
  float: right;
  margin: 0;
  padding: 0;
  font-family: "FivoSansModern-Regular";
  font-size: 32px;
  color: #5138ed;
  border: 1px solid #ccd5e8;
  border-radius: 60px;
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 80px;
}
.roadmap_services h3 {
  float: left;
  margin: 40px 0 10px 0;
  padding: 0;
  width: 100%;
  clear: both;
  font-family: "FivoSansModern-Heavy";
  font-size: 30px;
  color: #5138ed;
}
.roadmap_services h3 span {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000;
  font-weight: normal;
}
.roadmap_services p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000;
  float: left;
  margin: 110px 0 0 0;
  padding: 0;
  width: 100%;
}
.roadmap_services ul {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}
.roadmap_services ul li {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000;
  float: left;
  margin: 30px 0 0px 0;
  padding: 0;
  width: 100%;
  clear: both;
}
.roadmap_services .gap_hight {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 24px;
}
.trusted_partner {
  float: left;
  margin: 0;
  padding: 90px 0 0 0;
  width: 100%;
}
.trusted_partner p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000;
  float: left;
  margin: 0px 0 30px 0;
  padding: 0 23%;
  width: 100%;
  text-align: center;
  clear: both;
  line-height: 27px;
}
.roadmap .btn_get_started {
  float: left;
  margin: 0px 0 0 0;
  padding: 0;
  width: 100%;
  text-align: center;
  display: flex;
}
.roadmap .btn_get_started a {
  float: left;
  margin: 0 auto;
  width: 222px;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  border-radius: 68px;
  padding: 16px 64px;
  border: 1px solid #969696;
  text-decoration: none;
  outline: none;
  text-transform: uppercase;
  clear: both;
  text-align: center;
}
.ip_content {
  float: left;
  margin: 0;
  padding: 120px 0 0 0;
  width: 100%;
}
.ip_content .icon_ip {
  float: left;
  margin: 0 0 30px 0;
  padding: 0;
  width: 100%;
}
.ip_content .icon_ip img {
  max-width: 100%;
  border: none;
  width: 120px;
}

.ip_content p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000;
  float: left;
  margin: 0px 0 20px 0;
  padding: 0 10% 0 10%;
  width: 100%;
  text-align: left;
  clear: both;
  line-height: 27px;
}
.ip_content p span {
  color: #5138ed;
}
.ip_content .btn_make_your_ip {
  float: left;
  margin: 0px 0 0 0;
  padding: 0 0 0 13%;
  width: 100%;
}
.ip_content .btn_make_your_ip a {
  float: left;
  margin: 0 0 0 0;
  width: auto;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #000;
  border-radius: 68px;
  padding: 16px 48px;
  border: 1px solid #969696;
  text-decoration: none;
  outline: none;
  text-transform: uppercase;
  clear: both;
  text-align: center;
}
.ip_content img.ip_content_icon {
  position: relative;
  margin: -320px 0 0 17%;
  width: 68%;
  z-index: 99;
}
.ip_content img.ip_content_img {
  position: relative;
}

.scroll {
  position: fixed;
  bottom: 0;
  left: 0;
}

/**
  * z-index ordering of the different layers, do this for your layers,
  * also assign absolute width (to prevent issues if the script gets executed before the images get loaded)
  */
.horizon {
  z-index: 1;
  width: 3000px;
}

.middle {
  z-index: 500;
  width: 4000px;
}

.front {
  z-index: 1000;
  width: 6000px;
}

.slider_img_content {
  float: left;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  width: 100%;
}

.slider_img_content .item {
  width: 11%;
  float: left;
  height: auto;
  cursor: pointer;
  overflow: hidden;
  margin: 0 1% 0 0;
  border-radius: 30px;
  background: linear-gradient(#e6e9f7, #faede5);
  transition: all 0.3s ease-out;
  max-height: 482px;
}
.slider_img_content .item img.pic_lead {
  display: block;
  margin: 0 0 0 -60px;
}
.slider_img_content .item img.pic_lead_active {
  display: none;
}

.slider_img_content .item.active img.pic_lead {
  display: none;
}
.slider_img_content .item.active img.pic_lead_active {
  display: block;

  -ms-transform: scale(1.4);
  -moz-transform: scale(1.4);
  -webkit-transform: scale(1.4);
  -o-transform: scale(1.4);
  transform: scale(1.4);
  margin: 70px 0 0 0;
}

.slider_img_content .item:hover img.pic_lead {
  display: none;
}
.slider_img_content .item.active:hover img.pic_lead_active {
  display: block;
  margin-left: 0px;
}
.slider_img_content .item:hover img.pic_lead_active {
  display: block;
  margin-left: -60px;
}

.slider_img_content .item img {
  width: 398px;
  float: left;
  max-width: 298px;
  transform: scale(1);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.slider_img_content .item:last-child {
  margin: 0;
}

.slider_img_content .active {
  width: 24%;
}

.slider_img_content .info {
  width: 0;
  float: left;
  margin: 50px 0 0 0;
  color: #222;
  display: none;
  overflow: hidden;
  height: 400px;
}
.slider_img_content .info h3 {
  font-family: "FivoSansModern-Heavy";
  font-size: 42px;
  color: #000;
  float: left;
  margin: 0 0 0 0px;
  padding: 0 0 0 20px;
  width: 100%;
}
.slider_img_content .info p {
  font-family: "FivoSansModern-Regular";
  font-size: 19px;
  color: #000;
  float: left;
  margin: 0 0 30px 0px;
  padding: 0 0 0 20px;
  width: 80%;
  text-align: left;
}

.leadership_info_1st {
  background-color: transparent;
  width: 100%;
  height: auto;
  perspective: 1000px;
  float: left;
  margin: 100px 0 0 0;
}

.leadership_info_1st_inner {
  float: left;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.leadership_info_1st:hover .leadership_info_1st_inner {
  transform: rotateY(180deg);
}

.leadership_info_1st_front {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 50%;
  background-color: #dbfdff;
  float: left;
}
.leadership_info_1st_back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 50%;
}

.leadership_info_1st_front img {
  width: 100%;
  float: left;
}
.leadership_info_1st_back {
  background: -webkit-linear-gradient(#69bcff, #0072ff);
  transform: rotateY(180deg);
}
.leadership_info_1st .leadership_info_1st_back p {
  font-family: "FivoSansModern-Regular";
  font-size: 30px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  text-align: center;
  width: 100%;
  padding: 0 5%;
  padding: 0;
  margin: 0;
}

.leadership_info_2nd {
  background-color: transparent;
  width: 100%;
  height: auto;
  perspective: 1000px;
  float: left;
  margin: 60px 0 0 0;
}

.leadership_info_2nd_inner {
  float: left;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.leadership_info_2nd:hover .leadership_info_2nd_inner {
  transform: rotateY(180deg);
}

.leadership_info_2nd_front {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 50%;
  background-color: #dbfdff;
  float: left;
}
.leadership_info_2nd_back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 50%;
}

.leadership_info_2nd_front img {
  width: 100%;
  float: left;
}
.leadership_info_2nd_back {
  background: -webkit-linear-gradient(#69bcff, #0072ff);
  transform: rotateY(180deg);
}
.leadership_info_2nd .leadership_info_2nd_back p {
  font-family: "FivoSansModern-Regular";
  font-size: 30px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  text-align: center;
  width: 100%;
  padding: 0 5%;
  padding: 0;
  margin: 0;
}

.leadership_info_3rd {
  background-color: transparent;
  width: 100%;
  height: auto;
  perspective: 1000px;
  float: left;
  margin: 0px 0 0 0;
}

.leadership_info_3rd_inner {
  float: left;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.leadership_info_3rd:hover .leadership_info_3rd_inner {
  transform: rotateY(180deg);
}

.leadership_info_3rd_front {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 50%;
  background-color: #dbfdff;
  float: left;
}
.leadership_info_3rd_back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 50%;
}

.leadership_info_3rd_front img {
  width: 100%;
  float: left;
}
.leadership_info_3rd_back {
  background: -webkit-linear-gradient(#69bcff, #0072ff);
  transform: rotateY(180deg);
}
.leadership_info_3rd .leadership_info_3rd_back p {
  font-family: "FivoSansModern-Regular";
  font-size: 30px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  text-align: center;
  width: 100%;
  padding: 0 5%;
  padding: 0;
  margin: 0;
}

.leadership_info_4th {
  background-color: transparent;
  width: 100%;
  height: auto;
  perspective: 1000px;
  float: left;
  margin: 15px 0 0 0;
}

.leadership_info_4th_inner {
  float: left;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.leadership_info_4th:hover .leadership_info_4th_inner {
  transform: rotateY(180deg);
}

.leadership_info_4th_front {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 50%;
  background-color: #dbfdff;
  float: left;
}
.leadership_info_4th_back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 50%;
}

.leadership_info_4th_front img {
  width: 100%;
  float: left;
}
.leadership_info_4th_back {
  background: -webkit-linear-gradient(#69bcff, #0072ff);
  transform: rotateY(180deg);
}
.leadership_info_4th .leadership_info_4th_back .text {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  text-align: center;
  width: 100%;
  padding: 0 5%;
}
.leadership_info_4th .leadership_info_4th_back .text p {
  font-family: "FivoSansModern-Regular";
  font-size: 30px;
  line-height: 30px;
  color: #fff;
  float: left;
  text-align: left;
  margin: 0;
  padding: 0 0 10px 15%;
}
.leadership_info_4th .leadership_info_4th_back .text h4 {
  font-family: "FivoSansModern-Bold";
  font-size: 35px;
  color: #ffffff;
  float: left;
  padding: 0 0 10px 15%;
  margin: 0;
}

.slide-container {
  display: flex;
  overflow: scroll;
  flex-wrap: nowrap;
  gap: 30px;
}

.slide-ontainer2 {
  display: flex;
  overflow: scroll;
  flex-wrap: nowrap;
  gap: 30px;
}
.slidecontainer2 .our_studios_logo {
  flex: 0 0 auto;
}

#scrollableElement {
  max-width: 100%;
  padding: 0px;
  margin: 0 0 0 0;
  box-sizing: border-box;
  display: -webkit-box;
  overflow-y: hidden;
  float: left;
  text-align: left;
  overflow-x: hidden;
}
#scrollableElement2 {
  max-width: 100%;
  padding: 0px;
  margin: 30px 0 0 0;
  box-sizing: border-box;
  display: -webkit-box;
  overflow-y: hidden;
  float: left;
  text-align: left;
  overflow-x: hidden;
}
#scrollableElement .our_studios_logo:first-child {
  margin-left: 49.9992vw;
}
#scrollableElement .our_studios_logo:last-child {
  margin-right: 49.9992vw;
}
#scrollableElement2 .our_studios_logo:last-child {
  margin-right: 49.9992vw;
}
#scrollableElement2 .our_studios_logo:first-child {
  margin-left: 49.9992vw;
}

#scrollableElement .our_studios_logo,
#scrollableElement2 .our_studios_logo {
  float: left;
  margin: 10px;
  padding: 70px 0 0 0;
  width: 285px;
  background-color: #f7f7f7;
  border-radius: 30px;
  text-align: center;
  height: 285px;
}
#scrollableElement img.our_studios_slide_gap {
  float: left;
  margin: 0;
  width: 100%;
  height: 285px;
  max-width: 49%;
}
.our_awesome_studios img.studios_icon_center {
  float: none;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 224px;
  height: 224px;
  position: absolute;
  z-index: 999;
  left: 44%;
  top: 200px;
}

.logo_animation_area {
  position: relative;
  float: left;
  width: 100%;
  overflow: hidden;
  margin: 60px 0 0px 0;
  padding: 0;
}

.sections {
  width: 100% !important;
  position: relative;
  height: 100% !important;
  display: grid;
  margin: 0px 0 0 0 !important;
  max-height: 700px !important;
  overflow: hidden !important;
}

.flex-center {
  position: relative;
  height: 360px;
}

.container-page {
  width: 100%;
}
.contento {
  background-color: #fff;
}

.panels {
  width: 100%;
  /*   height: 100vh; */

  overflow: hidden;
  position: relative;
}

.panel {
}

section.spacer {
  width: 100%;
  height: 100vh;
  background: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
.sections .our_studios_logo {
  float: left;
  margin: 10px;
  margin-left: 10px;
  padding: 70px 0 0 0;
  width: 285px;
  background-color: #f7f7f7;
  border-radius: 30px;
  text-align: center;
  height: 285px;
}
.sections img {
  border: none;
  max-width: 100%;
}
.gsap-marker-end,
.gsap-marker-start,
.gsap-marker-scroller-end,
.gsap-marker-scroller-start {
  display: none !important;
}

.slider {
  height: auto;
  position: relative;
  width: 100%;

  display: grid;
  place-items: center;
  overflow: hidden;
}

/*  IMPORTANT CODE BELOW */

.slide-track {
  display: flex;
  animation: scroll 20s linear infinite;
  justify-content: space-between;
}

.slide-track2 {
  display: flex;
  animation: scroll2 15s linear infinite;
  justify-content: space-between;
}

.slide-track3 {
  display: flex;
  animation: scrolls 18s linear infinite;
  justify-content: space-between;
}

.slide {
  width: 170px;
  height: 160px;

  display: grid;
  place-items: center;
  transition: 0.5s;
  cursor: pointer;
}

@keyframes scroll {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(calc(-150px * 10));
  }
}

@keyframes scroll2 {
  0% {
    transform: translateX(calc(-150px * 5));
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes scrolls {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(calc(-150px * 10));
  }
}

.tickerwrapper2 {
  /* the outer div */

  position: relative;
  background: #fff;
  width: 100%;
  height: auto;
  overflow: hidden;
  cursor: pointer;
  float: left;
  margin: 0;
  padding: 0;
  clear: both;
  z-index: 999;
}
.tickerwrapper {
  /* the outer div */

  position: relative;
  background: #fff;
  width: 100%;
  height: auto;
  overflow: hidden;
  cursor: pointer;
  float: left;
  margin: 0;
  padding: 0;
  clear: both;
  z-index: 999;
}

.tickerwrapper3 {
  /* the outer div */

  position: relative;
  background: #fff;
  width: 100%;
  height: auto;
  overflow: hidden;
  cursor: pointer;
  float: left;
  margin: 0;
  padding: 0;
  clear: both;
  z-index: 999;
}
ul.list {
  position: relative;
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.list.cloned {
  position: absolute;
  top: 0px;
  left: 0px;
}

ul.list li {
  float: left;
  padding: 0 10px;
}

.banner_programs {
  margin: 0 1%;
  padding: 50px 0 120px 0;
  width: 98%;
  float: left;
  background-color: #000;
}

.banner_programs .banner_programs_top {
  margin: 0 auto;
  padding: 0 0 0 0;
  width: 100%;
  float: none;
  max-width: 1162px;
  position: relative;
}

.banner_programs_top img.banner_top_1 {
  float: left;
  margin: 0 0 0 0;
  padding: 0;
  border: none;
  max-width: 100%;
}
.banner_programs_top img.banner_top_logo {
  float: left;
  margin: 82px 0 0 14.4%;
  padding: 0;
  border: none;
  max-width: 100%;
}
.banner_programs_top img.banner_top_title {
  float: left;
  margin: -18px 0 0 13%;
  padding: 0;
  border: none;
  max-width: 100%;
}
.banner_programs_top img.banner_top_3 {
  float: left;
  margin: -143px 0 0 6%;
  padding: 0;
  border: none;
  max-width: 100%;
  position: relative;
  z-index: 99;
}
.banner_programs_top img.banner_top_4 {
  float: left;
  margin: -83px 0 0 44%;
  padding: 0;
  border: none;
  max-width: 100%;
  position: relative;
  z-index: 99;
}
.banner_programs_top img.banner_top_5 {
  float: right;
  margin: -192px 26% 0 0;
  padding: 0;
  border: none;
  max-width: 100%;
  position: relative;
  z-index: 99;
}
.banner_programs_top img.banner_top_2 {
  float: right;
  margin: -120px 0 0 0;
  padding: 0;
  border: none;
  max-width: 100%;
  position: relative;
  z-index: 99;
}

.just_play2 {
  float: left;
  margin: 70px 0 70px 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: center;
}
.just_play2 .just_play_text {
  float: none;
  margin: 0 auto;
  padding: 0;
  width: 120px;
  text-align: center;
}
.just_play2 .circle-btn {
  display: grid;
  place-content: center;
  width: 120px;
  aspect-ratio: 1;
  border-radius: 50%;
  text-decoration: none;
  color: #fff;
  position: absolute;
}

.just_play2 .circle-btn__text {
  position: absolute;
  inset: 0;
  font-family: "Metropolis-SemiBold";
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  animation: spinAround 18s linear infinite;
}

.just_play2 .circle-btn__text span {
  position: absolute;
  top: 0;
  text-transform: uppercase;
  display: inline-block;
  transform-origin: 0 60px;
  color: #fff;
}

.just_play2 img {
  width: 100%;
  max-width: 44px;
  border: none;
  margin: 50px 0 0 0;
}

.right_text {
  position: absolute;
  right: 0;
  z-index: 99;
  top: 150px;
}

.right_text span.text {
  font-family: "Metropolis-Medium";
  font-size: 12px;
  color: #fff;
  transform: rotate(90deg);
  float: left;
  margin: 26px 0 0 0;
  padding: 0 0 0 0;
  width: auto;
  text-transform: uppercase;
}
.right_text span.text2 {
  font-family: "Metropolis-Medium";
  font-size: 12px;
  color: #fff;
  transform: rotate(90deg);
  float: left;
  margin: 0;
  padding: 0;
  width: auto;
  text-transform: uppercase;
  position: relative;
  left: 78px;
}

.social_icons_banner {
  position: absolute;
  right: 64px;
  z-index: 99;
  top: 150px;
}
.social_icons_banner a {
  text-decoration: none;
  outline: none;
  float: left;
  margin: 12px 0 0 0;
  padding: 0;
  width: auto;
  clear: both;
}
.banner_links {
  position: relative;
  float: left;
  margin: 0px 0 0 0;
  padding: 0;
  width: 100%;
}

.banner_programs_bottom {
  margin: 0 auto;
  padding: 0 0 0 0;
  width: 100%;
  float: none;
  max-width: 1097px;
  position: relative;
}
.banner_programs_row {
  position: relative;
  float: left;
  margin: 60px 0 0 0;
  padding: 0;
  width: 100%;
}
.banner_programs_row_center {
  position: relative;
  float: left;
  margin: -10px 0 0 0;
  padding: 0;
  width: 100%;
}
.banner_programs_row_bottom {
  position: relative;
  float: left;
  margin: 10px 0 0 0;
  padding: 0;
  width: 100%;
}

.banner_programs_row img {
  padding: 0;
  border: none;
  max-width: 100%;
}
.banner_programs_row img.top_line_ing_1 {
  float: left;
  margin: 0 0 0 16px;
}
.banner_programs_row img.top_line_ing_2 {
  float: left;
  margin: 21px 0 0 39px;
}
.banner_programs_row img.top_line_ing_3 {
  float: left;
  margin: 5px 0 0 26px;
}
.banner_programs_row img.top_line_ing_4 {
  float: left;
  margin: -42px 0 0 -129px;
  position: relative;
  z-index: 99;
}
.banner_programs_row img.top_line_ing_5 {
  float: left;
  margin: 7px 0 0 22px;
}
.banner_programs_row img.top_line_ing_6 {
  float: right;
  margin: -63px 0 0 0;
}

.banner_programs_row_center img {
  padding: 0;
  border: none;
  max-width: 100%;
}
.banner_programs_row_center img.center_line_ing_1 {
  float: left;
  margin: 0 0 0 0;
}
.banner_programs_row_center img.center_line_ing_2 {
  float: left;
  margin: 1px 0 0 25px;
}
.banner_programs_row_center img.center_line_ing_3 {
  float: left;
  margin: -24px 0 0 -67px;
  position: relative;
  z-index: 99;
}
.banner_programs_row_center img.center_line_ing_4 {
  float: left;
  margin: 0 0 0 -66px;
}
.banner_programs_row_center img.center_line_ing_5 {
  float: left;
  margin: 12px 0 0 27px;
}

.banner_programs_row_bottom img {
  padding: 0;
  border: none;
  max-width: 100%;
}
.banner_programs_row_bottom img.bottom_line_ing_1 {
  float: left;
  margin: 21px 0 0 46px;
}
.banner_programs_row_bottom img.bottom_line_ing_2 {
  float: left;
  margin: 42px 0 0 28px;
}
.banner_programs_row_bottom img.bottom_line_ing_3 {
  float: left;
  margin: 91px 0 0 -41%;
  position: relative;
  z-index: 99;
}
.banner_programs_row_bottom img.bottom_line_ing_4 {
  float: left;
  margin: 43px 0 0 20px;
}

.pixls_program {
  float: left;
  margin: 0px;
  padding: 0px 20px;
  width: 100%;
}
.pixls_program_content {
  float: left;
  margin: 0px;
  padding: 150px 0px 170px 0;
  width: 100%;
  background-color: #ffffff;
}
.pixls_program_content img.pixls_program_img {
  float: left;
  margin: 40px 0 0 0;
  max-width: 100%;
}
.pixls_program_info {
  float: left;
  margin: 0px;
  padding: 0px 0px;
  width: 100%;
  text-align: center;
}
.pixls_program_info p {
  font-family: "Sora-Light";
  font-size: 24px;
  color: #000;
  line-height: 35px;
  float: left;
  margin: 30px 0 0 0;
  padding: 0;
  width: 100%;
  text-align: left;
}

.pixls_program_info p b {
  font-family: "Sora-Bold";
}

.pixls_program_content_icon {
  float: left;
  margin: 0px;
  padding: 0px 0px;
  width: 100%;
  text-align: center;
  position: relative;
}
.pixls_program_content_icon .pixls_icon1 {
  position: relative;
  z-index: 9;
}
.pixls_program_content_icon .pixls_icon2 {
  position: absolute;
  top: 0;
  z-index: 99;
  left: 16%;
}

.pixls_program_animation2 {
  float: left;
  margin: -60px 0 0 0;
  padding: 4px 0;
  width: 100%;
  overflow: hidden;
  height: auto;
  clear: both;
  background-color: #000000;
  transform: rotate(359deg);
  position: relative;
  z-index: 99;
}

.pixls_program_animation2 .marquee {
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  border-radius: 0;
  white-space: nowrap;
}

.pixls_program_animation2 .marquee--inner {
  display: block;
  width: 300%;
  position: absolute;
  animation: marquee2 30s linear infinite;
}

.pixls_program_animation2 span {
  float: left;
  width: auto;
  overflow: hidden;
  font-family: "Sora-Regular";
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
}
.pixls_program_animation2 span img {
  margin: 5px 100px 0 100px;
  float: left;
}
@keyframes marquee2 {
  0% {
    right: 0;
  }
  100% {
    right: -100%;
  }
}

.brandanimation .marquee {
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  border-radius: 0;
  white-space: nowrap;
}

.brandanimation .marquee--inner {
  display: block;
  width: 300%;
  position: absolute;
  animation: marquee3 30s linear infinite;
}

.brandanimation span {
  float: left;
  width: auto;
  overflow: hidden;
  font-family: "FivoSansModern-Regular";
  font-size: 30px;
  color: #696868;
  text-transform: uppercase;
}
.brandanimation span img {
  margin: 0px 50px 0 50px;
  float: left;
}
@keyframes marquee3 {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}

.brandanimation {
  position: relative;
  background: #000;
  width: 100%;
  height: auto;
  overflow: hidden;
  cursor: pointer;
  float: left;
  margin: 0;
  padding: 5px 0;
  clear: both;
  z-index: 999;
  transform: rotate(1deg);
}

.brandanimationcolor {
  /* the outer div */

  position: relative;
  background: #000;
  width: 100%;
  height: auto;
  overflow: hidden;
  cursor: pointer;
  float: left;
  margin: -50px 0 0 0;
  padding: 5px 0;
  clear: both;
  z-index: 999;
  transform: rotate(359deg);
}
.brandanimationcolor .marquee {
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  border-radius: 0;
  white-space: nowrap;
}

.brandanimationcolor .marquee--inner {
  display: block;
  width: 300%;
  position: absolute;
  animation: marquee4 30s linear infinite;
}

.brandanimationcolor span {
  float: left;
  width: auto;
  overflow: hidden;
  font-family: "FivoSansModern-Regular";
  font-size: 30px;
  color: #fff;
  text-transform: uppercase;
}
.brandanimationcolor span img {
  margin: 0px 50px 0 50px;
  float: left;
}
@keyframes marquee4 {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

.program_vision_area {
  float: left;
  margin: 0px;
  padding: 170px 0px;
  width: 100%;
  background-color: #ffffff;
}
.program_vision_title {
  float: left;
  margin: 180px 0 0 0;
  padding: 0px 0px;
  width: 100%;
}
.program_vision_title h1 {
  font-family: "Sora-ExtraBold";
  font-size: 124px;
  line-height: 120px;
  color: #000;
  float: left;
  margin: 0px;
  padding: 0px 0px;
  width: 100%;
}
.program_vision_title h1 span.extralight {
  font-family: "Sora-ExtraLight";
}
.program_vision_title h1 span.regular {
  font-family: "Sora-Regular";
}
.program_vision_title h1 span.bold {
  font-family: "Sora-Bold";
}
.program_vision_title h1 img {
  margin: -89px 0 0 287px;
  position: relative;
  float: left;
  width: 50px;
}
.program_vision_title img.program_vision_map {
  margin: 65px 0 0 65px;
  float: left;
}
.program_vision_info {
  float: left;
  margin: 0px;
  padding: 0px 0px 0 50px;
  width: 100%;
  border-left: 4px solid #bbdf00;
}
.program_vision_info p {
  font-family: "Sora-Light";
  font-size: 24px;
  color: #000;
  line-height: 35px;
  float: left;
  margin: 30px 0 0 0;
  padding: 0;
  width: 100%;
  text-align: left;
}
.program_vision_info p .program_vision_info_img_1 {
  position: relative;
  margin: -22px 0 0 0;
  display: inline-block;
}
.program_vision_info p .program_vision_info_img_1 img {
  position: relative;
  z-index: 9;
  width: 144px;
  height: 47px;
  top: 14px;
}

.program_vision_info p .program_vision_info_img_2 {
  position: relative;
  margin: -22px 0 0 0;
  display: inline-block;
}
.program_vision_info p .program_vision_info_img_2 img {
  position: relative;
  z-index: 9;
  width: 235px;
  height: 40px;
  top: 14px;
}
.program_vision_info p .program_vision_info_img_3 {
  position: relative;
  margin: -22px 0 0 0;
  display: inline-block;
}
.program_vision_info p .program_vision_info_img_3 img {
  position: relative;
  z-index: 9;
  width: 261px;
  height: 36px;
  top: 14px;
}

.program_vision_info p .program_vision_info_img_4 {
  position: relative;
  margin: -22px 0 0 0;
  display: inline-block;
}
.program_vision_info p .program_vision_info_img_4 img {
  position: relative;
  z-index: 9;
  width: 231px;
  height: 12px;
  top: -16px;
  left: 88%;
}

.program_vision_info p .program_vision_info_img_5 {
  position: relative;
  margin: -22px 0 0 0;
  display: inline-block;
}
.program_vision_info p .program_vision_info_img_5 img {
  position: relative;
  z-index: 9;
  width: 203px;
  height: 35px;
  top: 14px;
}

.program_vision_info p .program_vision_info_img_6 {
  position: relative;
  margin: -22px 0 0 0;
  display: inline-block;
}
.program_vision_info p .program_vision_info_img_6 img {
  position: relative;
  z-index: 9;
  width: 92px;
  height: 31px;
  top: 14px;
}

.program_plan {
  background-color: #000;
  margin: 1% 1% 0px 1%;
  padding: 120px 0 120px 0;
  width: 98%;
  float: left;
  border-radius: 30px;
}

.program_plan_title {
  float: left;
  margin: 0px;
  padding: 0px 0px;
  width: 100%;
}
.program_plan_title h1 {
  font-family: "Sora-SemiBold";
  font-size: 124px;
  line-height: 120px;
  color: #fff;
  float: left;
  margin: 0px;
  padding: 0px 0px;
  width: 100%;
  text-transform: uppercase;
}
.program_plan_title h1 span.thin {
  font-family: "Sora-Thin";
}

.program_plan_title h1 img.program_plan_title_icon {
  position: relative;
  border: none;
  max-width: 100%;
  width: 56px;
  top: -10px;
  left: -82px;
}
.program_plan_title h1 img.program_plan_title_icon2 {
  position: relative;
  width: 80px;
  left: -40px;
  top: 30px;
  z-index: 99;
}

.program_plan_title img.program_plan_img {
  margin: -28px 0 0 33px;
  float: left;
  position: relative;
  z-index: 99;
}
.program_plan_info {
  float: left;
  margin: 0px;
  padding: 20px 0 0 0px;
  width: 33.3%;
  position: relative;
}
.program_plan_info .program_plan_details {
  float: left;
  margin: 0 0 20px 0;
  padding: 0px 0px;
  width: 100%;
  min-height: 150px;
}
.margin_top {
  margin-top: -12px !important;
}

.program_plan_info .program_plan_details h3 {
  font-family: "Sora-Medium";
  font-size: 18px;
  color: #000;
  float: left;
  width: 90%;
  margin: 0 5% 15px 5%;
  padding: 10px 30px;
  border-radius: 50px;
  text-align: center;
}
.program_plan_info .program_plan_details p {
  font-family: "Sora-Light";
  font-size: 16px;
  color: #fff;
  float: left;
  margin: 0 5% 15px 5%;
  padding: 0;
  width: 90%;
  text-align: center;
}
.program_plan_info .program_plan_details h3.bg1 {
  background-color: #c0f500;
}
.program_plan_info .program_plan_details h3.bg2 {
  background-color: #3db7ff;
}
.program_plan_info .program_plan_details h3.bg3 {
  background-color: #f58220;
}
.program_plan_info .program_plan_details h3.bg4 {
  background-color: #6b30ff;
}
.program_plan_info .program_plan_details h3.bg5 {
  background-color: #ff90c8;
}
.program_plan_info .program_plan_details h3.bg6 {
  background-color: #ffde01;
}
.program_plan_info .program_plan_details h3.bg7 {
  background-color: #3db7ff;
}

.program_plan_info .program_plan_details h3.bg8 {
  background-color: #c0f500;
}
.program_plan_info img.star_participation_criteria {
  float: right;
  margin: 0;
  padding: 0;
  width: 70px;
  position: absolute;
  bottom: -15px;
  right: -60px;
}
.our_partners {
  float: left;
  margin: 120px 1% 0 1%;
  padding: 120px 0px;
  width: 98%;
  border: 1px solid #fff;
  border-radius: 30px;
  text-align: center;
}
.our_partners h1 {
  font-family: "Sora-SemiBold";
  font-size: 90px;
  color: #fff;
  text-align: center;
  float: left;
  width: 100%;
  text-transform: uppercase;
  margin: 0 0 80px 0;
  padding: 0;
}
.our_partners h1 span {
  font-family: "Sora-Thin";
}
.our_partners h1 img.our_partners_icon {
}
.achievements {
  float: left;
  margin: 0 0 0 0;
  padding: 150px 0px 0 0;
  width: 100%;
}
.achievements h2 {
  font-family: "Sora-Thin";
  font-size: 120px;
  color: #fff;
  float: left;
  width: 100%;
  margin: 40px 0 0px 0;
  padding: 0;
  line-height: 110px;
}
.achievements h2 span.margin_left {
  float: left;
  margin-left: 60px !important;
}
.achievements h2 span.margin_left2 {
  float: left;
  margin-left: 146px !important;
}
.achievements img.achievements_star {
  float: left;
  margin: -30px 0 0 60px;
}

.achievements_img {
  float: left;
  margin: 70px 0;
  padding: 0px 0;
  width: 100%;
  background-color: #090909;
}
.achievements_img img.achievements_img_1 {
  border: none;
  max-width: 100%;
  float: left;
  margin: 0 0 20px 0;
}
.achievements_img img.achievements_img_2 {
  border: none;
  max-width: 100%;
  float: left;
  margin: 0 0 20px 0;
}
.achievements_img img.achievements_img_3 {
  border: none;
  max-width: 100%;
  float: left;
  margin: 0 0 20px 0;
}
.achievements_img img.achievements_img_4 {
  border: none;
  max-width: 100%;
  float: left;
  margin: 0 0 20px 0;
}

.future_plans {
  float: left;
  margin: 0px 0;
  padding: 60px 0 40px 0;
  width: 100%;
  text-align: center;
}
.future_plans img.future_plans_img {
  border: none;
  max-width: 926px;
  width: 100%;
  margin: 0 auto;
}
.future_plans p {
  font-family: "Sora-Light";
  font-size: 20px;
  color: #fff;
  float: left;
  margin: 80px 0 40px 0;
  padding: 0 25%;
  width: 100%;
  text-align: center;
  line-height: 35px;
}
.future_plans .future_plans_info {
  float: left;
  width: 100%;
  margin: 0 0 0 0;
  padding: 40px 0 100px 0;
  text-align: center;
  position: relative;
}
.future_plans_info img.future_plans_icon_right {
  position: absolute;
  right: 0;
  top: -100px;
}
.future_plans_info img.future_plans_icon_left {
  position: absolute;
  left: 0;
  bottom: 0;
}

.contact_us_programs {
  background-color: #ffffff;
  margin: 20px 0 0 0;
  padding: 80px 0;
  width: 100%;
  float: left;
  border-radius: 30px 30px 0 0;
}
.contact_us_programs_inner {
  margin: 0px 0 0 0;
  padding: 0px 5%;
  width: 100%;
  float: left;
}
.hardworking {
  margin: 0px 0 0 0;
  padding: 0px 0;
  width: 100%;
  float: left;
}
.hardworking p {
  font-family: "Metropolis-Medium";
  font-size: 12px;
  color: #9a9a9a;
  float: left;
  margin: 0 0 30px 0;
  padding: 0 0 0 16%;
  width: 100%;
  text-transform: uppercase;
  line-height: 15px;
}

.contact_us_programs h1 {
  font-family: "FivoSansModern-Heavy";
  float: left;
  margin: 0;
  padding: 0 2% 0 6%;
  width: 100%;
  font-size: 160px;
  color: #000000;
  letter-spacing: -3px;
  line-height: 140px;
}
.flip {
  display: inline-block;
  width: 90%;
  height: 120px;
  position: relative;
  top: 10px;
  -moz-perspective: 500;
  -webkit-perspective: 500;
  perspective: 500;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.flip span {
  font-family: "FivoSansModern-Heavy";
  margin: 0;
  padding: 0;
  font-size: 160px;
  color: #000000;
  letter-spacing: -3px;
  line-height: 120px;
  display: block;
  width: auto;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  -moz-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -moz-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
  position: absolute;
  top: -20px;
  left: 0;
}
.flip span.set {
  top: 0;
  opacity: 1;
  -moz-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.flip span.down {
  top: 120px;
  opacity: 0;
  -moz-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -moz-transform: rotateX(-90deg);
  -ms-transform: rotateX(-90deg);
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}

.contact_us_programs h1 span.righttext {
  font-family: "Metropolis-Medium";
  font-size: 12px;
  color: #9a9a9a;
  float: right;
  margin: 0;
  padding: 40px 0 0 0;
  width: auto;
  text-transform: uppercase;
  line-height: 15px;
  letter-spacing: 0;
  font-weight: normal;
}
.contact_us_programs h2 {
  font-family: "FivoSansModern-Heavy";
  float: left;
  margin: -20px 0 0 0;
  padding: 0;
  width: 100%;
  font-size: 160px;
  color: #fed402;
  letter-spacing: -3px;
  line-height: 90px;
}
.contact_us_programs h2 img {
  float: left;
  width: 118px;
  margin: 12px 0 0 0;
}
.contact_us_programs h3 {
  font-family: "FivoSansModern-Heavy";
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 3%;
  width: 100%;
  font-size: 160px;
  color: #000000;
  letter-spacing: -3px;
  line-height: 110px;
}

.just_make_games {
  float: left;
  margin: -175px 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  position: relative;
}
.just_make_games .right_text {
  position: absolute;
  right: 0;
  z-index: 99;
  top: 100px;
}
.just_make_games .right_text span.text2 {
  font-family: "Metropolis-Medium";
  font-size: 12px;
  color: #9a9a9a;
  transform: rotate(90deg);
  float: left;
  margin: 0;
  padding: 0;
  width: auto;
  text-transform: uppercase;
  position: relative;
  left: 78px;
}
.just_make_games .right_text span.text {
  font-family: "Metropolis-Medium";
  font-size: 12px;
  color: #9a9a9a;
  transform: rotate(90deg);
  float: left;
  margin: 26px 0 0 0;
  padding: 0 0 0 0;
  width: auto;
  text-transform: uppercase;
}
.social_icons_footer {
  position: absolute;
  right: 64px;
  z-index: 99;
  top: 100px;
}
.social_icons_footer a {
  text-decoration: none;
  outline: none;
  float: left;
  margin: 12px 0 0 0;
  padding: 0;
  width: auto;
  clear: both;
}
.just_make_games .just_play_text img {
  width: 100%;
  max-width: 44px;
  border: none;
  margin: 50px 0 0 0;
}
.contactus_programs_info {
  float: left;
  margin: 40px 0 0 0;
  padding: 0;
  width: 100%;
}

.contactus_programs_info h1 {
  font-family: "FivoSansModern-Heavy";
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 72px;
  color: #000000;
  text-align: center;
  line-height: 70px;
}

.contactus_programs_info .sign_up {
  float: left;
  margin: 120px 0 0 0;
  padding: 0;
  width: 100%;
}
.contactus_programs_info .sign_up input.email {
  float: left;
  margin: 10px 0 10px 0;
  width: 100%;
  font-family: "Metropolis-Regular";
  font-size: 17px;
  color: #000000;
  border: 1px solid #b3b3b3;
  border-radius: 68px;
  padding: 8px 30px;
}
.contactus_programs_info .sign_up input.email:placeholder {
  font-family: "Metropolis-Regular";
  font-size: 17px;
  color: #000000;
}
.contactus_programs_info .sign_up ::placeholder {
  font-family: "Metropolis-Regular";
  font-size: 17px;
  color: #000000;
  opacity: 1; /* Firefox */
}

.contactus_programs_info .sign_up ::-ms-input-placeholder {
  /* Edge 12-18 */
  font-family: "Metropolis-Regular";
  font-size: 17px;
  color: #000000;
}
.contactus_programs_info .sign_up input.submit {
  float: left;
  margin: 10px 0 10px 0;
  width: auto;
  font-family: "Metropolis-SemiBold";
  font-size: 13px;
  color: #fff;
  border: none;
  border-radius: 68px;
  padding: 16px 32px;
  background-color: #000;
}
.contactinfo {
  float: left;
  margin: 40px 0 0 0;
  padding: 0 15%;
  width: 100%;
}
.contactinfo p {
  font-family: "Metropolis-Regular";
  font-size: 16px;
  color: #000000;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}
.footer_area_programs {
  margin: 1.5% 0 0px 0;
  padding: 65px 30px 55px 30px;
  width: 100%;
  float: left;
  background-color: #000;
  border-radius: 30px 30px 0 0;
}

.footer_area_programs ul.footer_menu {
  margin: 0;
  padding: 0 0 0 40%;
  width: auto;
  float: left;
}
.footer_area_programs ul.footer_menu li {
  margin: 0 0px 10px 0;
  padding: 0;
  width: 100%;
  float: left;
  list-style: none;
}
.footer_area_programs ul.footer_menu li a {
  margin: 0;
  padding: 0;
  width: 100%;
  float: left;
  text-decoration: none;
  outline: none;
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #a7a7a7;
}
.footer_area_programs ul.footer_menu li a:hover {
  color: #ffffff;
}
.footer_area_programs h3 {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #a7a7a7;
  float: left;
  margin: 0 0 20px 0;
  padding: 0;
  width: 100%;
}
.footer_area_programs .phone {
  font-family: "FivoSansModern-Regular";
  font-size: 20px;
  color: #808080;
  float: left;
  margin: 0px 0 15px 0;
  padding: 0;
  width: 100%;
}
.footer_area_programs .email {
  font-family: "Metropolis-ExtraBold";
  font-size: 40px;
  color: #ffca03;
  float: left;
  margin: 0 0 15px 0;
  padding: 0 0 0 15%;
  width: 100%;
}
.footer_area_programs .address {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #808080;
  float: left;
  margin: 40px 0 55px 0;
  padding: 0 35% 0 15%;
  width: 100%;
}

.footer_area_programs .social_icons {
  float: left;
  margin: 0 0 15px 0;
  padding: 0 0 0 15%;
  width: 100%;
}
.footer_area_programs .social_icons a {
  text-decoration: none;
  outline: none;
  border: 2px solid #4c4c4c;
  color: #4c4c4c;
  border-radius: 66%;
  padding: 10px;
  width: 40px;
  height: 40px;
  float: left;
  text-align: center;
  margin: 0 10px 0 0;
}
.footer_area_programs .social_icons a img {
  border: none;
  max-width: 100%;
}

body.programs_hackathon {
  background-color: #fff;
}
body.programs_hackathon .main_menu ul li a {
  color: #fff !important;
}
body.programs_hackathon .main_menu ul li.active a {
  color: #fed402 !important;
}
body.programs_hackathon .main_menu ul li a:hover {
  color: #fed402 !important;
}
body.programs_hackathon .main_menu ul li ul li a {
  color: #000 !important;
}

.programs_hackathon_top_area {
  background-image: url("../images/programs_hackathon.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;
  background-color: #002b3c !important;
  margin: 0 1% 0px 1%;
  padding: 40px 0 0 0;
  width: 98%;
  float: left;
  border-radius: 0;
}

.together_towards_tomorrow {
  margin: 0;
  padding: 50px 0 120px 0;
  width: 100%;
  float: left;
}
.together_towards_tomorrow_top {
  margin: 0 auto;
  padding: 90px 0 0 0;
  width: 100%;
  float: none;
  max-width: 996px;
  position: relative;
}
.together_towards_tomorrow_top img.be_bold_make_history {
  border: none;
  max-width: 100%;
  float: left;
  width: 290px;
}
.together_towards_tomorrow_top img.game-district-logo {
  border: none;
  max-width: 100%;
  float: left;
  margin: 142px 0 0 116px;
}
.together_towards_tomorrow_top img.together_towards_tomorrow2 {
  border: none;
  max-width: 100%;
  width: 556px;
  float: left;
  padding: 0;
  margin: -53px 0 0 220px;
}
.together_towards_tomorrow_top img.together_towards_tomorrow_icon_right_top {
  border: none;
  max-width: 100%;
  float: right;
  margin: 64px 0 0 60px;
}
.together_towards_tomorrow_top img.together_towards_tomorrow_icon_right_bottom {
  border: none;
  max-width: 100%;
  float: right;
  margin: 93px 0 0 0;
}

.just_play_together_towards_tomorrow {
  float: left;
  margin: 95px 0 70px 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: center;
}
.just_play_together_towards_tomorrow .just_play_text {
  float: none;
  margin: 0 auto;
  padding: 0;
  width: 120px;
  text-align: center;
}
.just_play_together_towards_tomorrow .circle-btn {
  display: grid;
  place-content: center;
  width: 120px;
  aspect-ratio: 1;
  border-radius: 50%;
  text-decoration: none;
  color: #fff;
  position: absolute;
}

.just_play_together_towards_tomorrow .circle-btn__text {
  position: absolute;
  inset: 0;
  font-family: "Metropolis-SemiBold";
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  animation: spinAround 18s linear infinite;
}

.just_play_together_towards_tomorrow .circle-btn__text span {
  position: absolute;
  top: 0;
  text-transform: uppercase;
  display: inline-block;
  transform-origin: 0 60px;
  color: #fff;
}

.just_play_together_towards_tomorrow img {
  width: 100%;
  max-width: 44px;
  border: none;
  margin: 50px 0 0 0;
}

.banner_links_just_play {
  position: relative;
  float: left;
  margin: 0px 0 0 0;
  padding: 0;
  width: 100%;
}
.together_towards_tomorrow h2 {
  font-family: "Mont Bold";
  font-weight: bold;
  font-size: 56px;
  color: #ddef15;
  float: left;
  margin: 40px 0 50px 0;
  padding: 0 0 0 0;
  width: 100%;
}
.together_towards_tomorrow h2 img {
  position: relative;
  top: 6px;
  left: 10px;
}
.together_towards_tomorrow p {
  font-family: "Mont Book";
  font-size: 36px;
  font-weight: 500;
  color: #ffffff;
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 88%;
}

.together_towards_tomorrow img.why_hackathon_right_icon {
  float: right;
  border: none;
  max-width: 100%;
  position: absolute;
  right: 73px;
  top: -40px;
}
.together_towards_tomorrow img.why_hackathon_right_icon_bg {
  float: right;
  border: none;
  max-width: 100%;
  position: relative;
  margin: 60px 0 0 0;
}

.together_towards_tomorrow_border_bottom {
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  background-color: #6656e1;
}
.together_towards_tomorrow_border_bottom img {
  max-width: 100%;
  float: left;
  margin: 0;
  padding: 0;
}

.together_towards_tomorrow_border_bottom .marquee {
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  border-radius: 0;
  white-space: nowrap;
}

.together_towards_tomorrow_border_bottom .marquee--inner {
  display: block;
  width: 200%;
  position: absolute;
  animation: marquee 18s linear infinite;
}

.together_towards_tomorrow_border_bottom span {
  float: left;
  width: 50%;
  overflow: hidden;
}

@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

.hackathon_program {
  float: left;
  margin: 0 1%;
  padding: 120px 0 120px 0;
  width: 98%;
  background-color: #002b3c;
  background-image: url("../images/hackathon_program_bg_star.png");
  background-repeat: no-repeat;
  background-position: center 90%;
}
.hackathon_program h2 {
  font-family: "Mont Bold";
  font-weight: bold;
  font-size: 48px;
  color: #ddef15;
  float: left;
  margin: 0px 0 20px 0;
  padding: 0 0 0 0;
  width: 100%;
}
.hackathon_program h2 img {
  position: relative;
  top: 6px;
  left: 10px;
  width: 278px;
}
.hackathon_program p {
  font-family: "Mont Book";
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
  float: left;
  margin: 0 0 40px 0;
  padding: 0 0 0 0;
  width: 100%;
}
.phase_area {
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
}

.phase_area h3 {
  font-family: "Mont Regular";
  font-size: 27px;
  color: #0ff099;
  float: left;
  margin: 40px 0 0 0;
  padding: 24px 36px;
  width: auto;
  border: 1px solid #ddef15;
  border-radius: 50px;
  position: relative;
}
.phase_area h3 span {
  font-family: "Mont SemiBold";
  font-size: 20px;
  color: #002b3e;
  float: left;
  margin: 0 0 0 0;
  padding: 2px 16px;
  width: auto;
  background-color: #fff;
  border-radius: 30px;
  position: absolute;
  text-transform: uppercase;
  line-height: 24px;
  top: -15px;
  left: 50px;
}
.phase_area_days_icon {
  float: left;
  margin: 0 0%;
  padding: 0;
  width: 100%;
  position: absolute;
  top: -114px;
  z-index: 99;
  left: 0;
}
.phase_area_days_icon img.days_1_to_12_icon_left {
  float: left;
}

.phase_area_days_icon img.days_1_to_12_icon_right {
  float: right;
  margin: 30px 0 0 0;
}

.phase_area_days {
  float: left;
  margin: 0 2%;
  padding: 25px 20px;
  width: 46%;
  border: 1px solid #ddef15;
  border-radius: 30px;
  position: relative;
  top: 160px;
}

.phase_area_days h3 {
  font-family: "Mont Bold";
  font-size: 21px;
  color: #002b3e;
  float: left;
  margin: 0px 0 0 0;
  padding: 15px 30px;
  width: auto;
  position: relative;
  background-color: #1bca89;
  text-align: center;
  border-radius: 30px;
}

.phase_area_days p {
  font-family: "Mont Regular";
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  float: left;
  margin: 20px 0 0px 0;
  padding: 0 0 0 0;
  width: 100%;
}

.phase_area_days2 {
  float: left;
  margin: 0 2%;
  padding: 25px 20px;
  width: 46%;
  border: 1px solid #ddef15;
  border-radius: 30px;
  position: relative;
}

.phase_area_days2 h3 {
  font-family: "Mont Bold";
  font-size: 21px;
  color: #002b3e;
  float: left;
  margin: 0px 0 0 0;
  padding: 15px 30px;
  width: auto;
  position: relative;
  background-color: #1bca89;
  text-align: center;
  border-radius: 30px;
}

.phase_area_days2 p {
  font-family: "Mont Regular";
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  float: left;
  margin: 20px 0 0px 0;
  padding: 0 0 0 0;
  width: 100%;
}

.phase_area_days2 img.phase_area_days2_bg {
  position: absolute;
  bottom: -33px;
  right: 20px;
  width: 60px;
}
.journey_of_game_district {
  float: left;
  margin: 0 1%;
  padding: 120px 0 120px 0;
  width: 98%;
  background-color: #002b3c;
  background-image: url("../images/journey_of_game_district_stars.png");
  background-repeat: no-repeat;
  background-position: center 82%;
  background-size: 60%;
}
.journey_of_game_district h2 {
  font-family: "Mont Bold";
  font-size: 56px;
  color: #ddef15;
  text-align: center;
  margin: 0;
  padding: 0;
  float: left;
  width: 100%;
}
.journey_of_game_district h2 span {
  font-family: "Mont Bold";
  font-size: 56px;
  color: #ddef15;
  margin: 0 50px;
}
.journey_of_game_district p {
  font-family: "Mont Book";
  font-size: 27px;
  color: #ffffff;
  float: left;
  margin: 20px 0 80px 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: center;
}
.Journey_1 {
  margin: 0;
  padding: 0;
  float: left;
  width: auto;
}
.Journey_1 .Journey_1_info {
  margin: 1px 0 0 0;
  padding: 0;
  float: left;
  width: auto;
  border-top: 2px solid #0e7a62;
  border-bottom: 2px solid #0e7a62;
  border-right: 2px solid #0e7a62;
  border-radius: 0px 55px 55px 0;
}

.Journey_1 img.one {
  margin: 0;
  padding: 0;
  float: left;
  width: auto;
}
.Journey_1 .Journey-Icon-1 {
  margin: 1px 0 0 0;
  padding: 7px 7px 7px 7px;
  float: left;
  width: auto;
  position: relative;
  z-index: 99;
}
.Journey_1 h3 {
  float: left;
  font-family: "Mont Bold";
  font-size: 27px;
  color: #fff;
  margin: 1px 0 0 0;
  padding: 37px 0 36px 0;
  position: relative;
  z-index: 9;
}

.Journey_2 {
  margin: 0;
  padding: 0;
  float: left;
  width: auto;
}
.Journey_2 .Journey_2_info {
  margin: 0px 0 0 0;
  padding: 0;
  float: left;
  width: auto;
  border-top: 2px solid #0e7a62;
  border-bottom: 2px solid #0e7a62;
  border-right: 2px solid #0e7a62;
  border-radius: 0px 55px 55px 0;
}
.Journey_2 img.two {
  margin: 0;
  padding: 0;
  float: left;
  width: auto;
}
.Journey_2 .Journey-Icon-2 {
  margin: 0px 0 0 0;
  padding: 8px 7px 8px 7px;
  float: left;
  width: auto;
  position: relative;
  z-index: 99;
}
.Journey_2 h3 {
  float: left;
  font-family: "Mont Bold";
  font-size: 27px;
  color: #fff;
  margin: 0px 0 0 0;
  padding: 20px 0 18px 0;
  position: relative;
  z-index: 9;
}

.Journey_3 {
  margin: 0;
  padding: 0;
  float: left;
  width: auto;
}
.Journey_3 .Journey_3_info {
  margin: 1px 0 0 0;
  padding: 0;
  float: left;
  width: auto;
  border-top: 2px solid #0e7a62;
  border-bottom: 2px solid #0e7a62;
  border-right: 2px solid #0e7a62;
  border-radius: 0px 55px 55px 0;
}
.Journey_3 img.three {
  margin: 0;
  padding: 0;
  float: left;
  width: auto;
}
.Journey_3 .Journey-Icon-3 {
  margin: 1px 0 0 0;
  padding: 7px 7px 7px 7px;
  float: left;
  width: auto;
  position: relative;
  z-index: 99;
}
.Journey_3 h3 {
  float: left;
  font-family: "Mont Bold";
  font-size: 27px;
  color: #fff;
  margin: 1px 0 0 0;
  padding: 18px 0 18px 0;
  position: relative;
  z-index: 9;
}

.Journey_4 {
  margin: 40px 0 0 16%;
  padding: 0;
  float: left;
  width: auto;
}
.Journey_4 .Journey_4_info {
  margin: 0px 0 0 0;
  padding: 0;
  float: left;
  width: auto;
  border-top: 2px solid #0e7a62;
  border-bottom: 2px solid #0e7a62;
  border-right: 2px solid #0e7a62;
  border-radius: 0px 55px 55px 0;
}
.Journey_4 img.four {
  margin: 0;
  padding: 0;
  float: left;
  width: auto;
}
.Journey_4 .Journey-Icon-4 {
  margin: 0px 0 0 0;
  padding: 8px 7px 7px 7px;
  float: left;
  width: auto;
  position: relative;
  z-index: 99;
}
.Journey_4 h3 {
  float: left;
  font-family: "Mont Bold";
  font-size: 27px;
  color: #fff;
  margin: 0px 0 0 0;
  padding: 19px 0 18px 0;
  position: relative;
  z-index: 9;
}

.Journey_5 {
  margin: 40px 0 0 0;
  padding: 0;
  float: left;
  width: auto;
}
.Journey_5 .Journey_5_info {
  margin: 0px 0 0 0;
  padding: 0;
  float: left;
  width: auto;
  border-top: 2px solid #0e7a62;
  border-bottom: 2px solid #0e7a62;
  border-right: 2px solid #0e7a62;
  border-radius: 0px 55px 55px 0;
}
.Journey_5 img.five {
  margin: 0;
  padding: 0;
  float: left;
  width: auto;
}
.Journey_5 .Journey-Icon-5 {
  margin: 0px 0 0 0;
  padding: 7px 7px 8px 7px;
  float: left;
  width: auto;
  position: relative;
  z-index: 99;
}
.Journey_5 h3 {
  float: left;
  font-family: "Mont Bold";
  font-size: 27px;
  color: #fff;
  margin: 0px 0 0 0;
  padding: 38px 0 36px 0;
  position: relative;
  z-index: 9;
}

.Journey_arrow {
  margin: 0;
  padding: 37px 3%;
  float: left;
  width: auto;
}
.Journey_arrow2 {
  margin: 60px 0 0 0;
  padding: 37px 3%;
  float: left;
  width: auto;
}

.be_bold_make_history_icon {
  margin: 60px 0 0 0;
  padding: 0 0 0;
  float: left;
  width: 100%;
  text-align: center;
}
.be_bold_make_history_icon img.icon_big {
  border: none;
  max-width: 100%;
  position: relative;
  z-index: 8;
  animation: spinAround 18s linear infinite;
}
.be_bold_make_history_icon img.icon_small {
  border: none;
  max-width: 100%;
  position: relative;
  z-index: 9;
  top: -42px;
  left: -81px;
}

.achievements_programs {
  float: left;
  margin: 0 1%;
  padding: 120px 0 120px 0;
  width: 98%;
  background-color: #002b3c;
  background-image: url("../images/achievements_programs_star.png");
  background-repeat: no-repeat;
  background-position: 41% 39%;
  background-size: 68%;
  border-radius: 0 0 60px 60px;
}
.achievements_programs h2 {
  font-family: "Mont Bold";
  font-size: 48px;
  color: #ddef15;
  margin: 0 0 40px 0;
  float: left;
  padding: 0;
  width: 100%;
}
.achievements_programs h2 img {
  margin: 12px 0px 0 0;
  max-width: 880px;
  float: right;
}
.game_scaled_globally {
  float: left;
  margin: 0;
  padding: 15px;
  width: 100%;
  background-color: #1cd791;
  border-radius: 30px;
  -webkit-transition: all 0.3s ease-in-out;
}
.game_scaled_globally:hover {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-transition: all 0.3s ease-in-out;
}
.game_scaled_globally h3 {
  font-family: "Mont Bold";
  font-size: 110px;
  color: #5f51cf;
  margin: 0 0 10px 0;
  float: left;
  padding: 0;
  width: 100%;
  line-height: 110px;
}
.game_scaled_globally h3 img {
  float: right;
}
.game_scaled_globally h4 {
  font-family: "Mont Book";
  font-size: 24px;
  color: #161616;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  font-weight: normal;
}
.game_scaled_globally2 {
  float: left;
  margin: 20px 0;
  padding: 0px;
  width: 100%;
}
.game_scaled_globally2 .eight_games {
  float: left;
  margin: 0;
  padding: 0 20px;
  width: auto;
  background-color: #6734f4;
  border-radius: 67px;
  -webkit-transition: all 0.3s ease-in-out;
}
.game_scaled_globally2 .eight_games:hover {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-transition: all 0.3s ease-in-out;
}
.game_scaled_globally2 .eight_games h3 {
  font-family: "Mont Bold";
  font-size: 90px;
  color: #ddef15;
  float: left;
  margin: 0;
  padding: 0;
  width: auto;
  line-height: 100px;
}
.game_scaled_globally2 .eight_games p {
  font-family: "Mont Book";
  font-size: 24px;
  color: #ddef15;
  float: left;
  margin: 0;
  padding: 33px 0 0 0;
  width: auto;
}

.game_scaled_globally2 .eight_games_icon {
  float: right;
  width: 90px;
  margin: 4px 0 0 0;
  -webkit-transition: all 0.3s ease-in-out;
}
.game_scaled_globally2 .eight_games_icon:hover {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-transition: all 0.3s ease-in-out;
}

.retention_marked {
  float: left;
  margin: 0;
  padding: 10px 20px 15px 20px;
  width: auto;
  background-color: #f7f7f7;
  border-radius: 30px;
  -webkit-transition: all 0.3s ease-in-out;
}
.retention_marked:hover {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-transition: all 0.3s ease-in-out;
}
.retention_marked h3 {
  font-family: "Mont Bold";
  font-size: 90px;
  color: #5f51cf;
  margin: 0;
  float: left;
  padding: 15px 0;
  width: 100%;
  line-height: 80px;
  text-align: center;
}
.retention_marked p {
  font-family: "Mont Book";
  font-size: 24px;
  color: #161616;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  font-weight: normal;
  text-align: center;
}
.million_game_downloads {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #6656e1;
  background-image: url("../images/million_game_downloads.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100%;
  min-height: 510px;
  border-radius: 30px;

  -webkit-transition: all 0.3s ease-in-out;
}
.million_game_downloads:hover {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-transition: all 0.3s ease-in-out;
}
.million_game_downloads h3 {
  font-family: "Mont Bold";
  font-size: 120px;
  color: #ddef15;
  margin: 0;
  float: left;
  padding: 80px 0 0 0;
  width: 100%;
  line-height: 120px;
  text-align: center;
}
.million_game_downloads p {
  font-family: "Mont Book";
  font-size: 24px;
  color: #ffffff;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  font-weight: normal;
  text-align: center;
}

.prototypes {
  float: left;
  margin: 0;
  padding: 20px 20px 15px 20px;
  width: 70%;
  background-color: #ddef15;
  border-radius: 30px;
  text-align: left;
  -webkit-transition: all 0.3s ease-in-out;
}
.prototypes:hover {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-transition: all 0.3s ease-in-out;
}
.prototypes_img {
  float: left;
  margin: 90px 0 0 0;
  padding: 0;
  width: 30%;
  text-align: right;
}
.prototypes h3 {
  font-family: "Mont Bold";
  font-size: 90px;
  color: #5f51cf;
  margin: 0;
  float: left;
  padding: 15px 0;
  width: 100%;
  line-height: 80px;
  text-align: left;
}
.prototypes p {
  font-family: "Mont Book";
  font-size: 24px;
  color: #161616;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  font-weight: normal;
  text-align: left;
}
.prototypes p img {
  float: right;
}
.gap_img {
  float: left;
  margin: 20px 0;
  padding: 0;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
}
.gap_img:hover {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-transition: all 0.3s ease-in-out;
}

.hit_games {
  float: left;
  margin: 0;
  padding: 20px 20px 15px 20px;
  width: 100%;
  background-color: #f7f7f7;
  border-radius: 30px;
  -webkit-transition: all 0.3s ease-in-out;
}
.hit_games:hover {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-transition: all 0.3s ease-in-out;
}
.hit_games h3 {
  font-family: "Mont Bold";
  font-size: 90px;
  color: #005473;
  margin: 0;
  float: left;
  padding: 15px 0;
  width: 40%;
  line-height: 80px;
  text-align: left;
}
.hit_games p {
  font-family: "Mont Book";
  font-size: 24px;
  color: #161616;
  float: left;
  margin: 0;
  padding: 0;
  width: 40%;
  font-weight: normal;
  text-align: left;
  clear: both;
}

.hit_games img.hit_games_icons {
  float: right;
  width: 54%;
  margin: -15px 0 0 0;
}

.successful_games {
  float: left;
  margin: 0 1%;
  padding: 120px 0 120px 0;
  width: 98%;
  background-color: #ffffff;
}
.successful_games h2 {
  font-family: "Mont Bold";
  font-size: 56px;
  color: #005473;
  text-align: center;
  margin: 0;
  padding: 0;
  float: left;
  width: 100%;
}
.successful_games h2 span {
  font-family: "Mont Bold";
  font-size: 56px;
  color: #005473;
  margin: 0 50px;
}
.successful_games p {
  font-family: "Mont Book";
  font-size: 27px;
  color: #005473;
  float: left;
  margin: 20px 0 80px 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: center;
}
.margin_top_games {
  margin-top: 60px !important;
}
.successful_games_info {
  float: left;
  margin: 0px 0 0px 0;
  padding: 0 0 0 0;
  width: 100%;
  background-color: #002b3d;
  border-radius: 20px;
}
.successful_games_info img.game_icon {
  border-radius: 20px 20px 0 0;
  width: 100%;
}
.successful_games_info .game_info {
  float: left;
  margin: 0px 0 0px 0;
  padding: 15px;
  width: 100%;
  background-color: #002b3d;
  border-radius: 0 0 20px 20px;
}

.successful_games_info .game_info h5 {
  font-family: "Mont Bold";
  font-size: 20px;
  color: #fff;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}
.successful_games_info .game_info p {
  font-family: "Mont Bold";
  font-size: 20px;
  color: #fff;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: right;
}
.successful_games_info .game_info p img {
  margin: 0 6px 0 0;
  width: 14px;
}

.gallery_game_district {
  float: left;
  margin: 0 1%;
  padding: 70px 0 70px 0;
  width: 98%;
  background-color: #002b3d;
  border-radius: 30px;
}

.gallery_gd_row img {
  border: none;
  max-width: 100%;
  margin: 0 0 15px 15px;
  float: left;
}
.gallery_gd_row2 img {
  border: none;
  max-width: 100%;
  margin: 0 0 0px 12px;
  float: left;
  width: 97%;
}
.gallery_gd_row {
  max-width: 72%;
  margin: 0 0 0 0;
  float: left;
  width: 100%;
}
.gallery_gd_row2 {
  max-width: 28%;
  margin: 0 0 0 0;
  float: left;
  width: 100%;
}
.gallery_gd_area {
  max-width: 100%;
  margin: 0 auto;
  padding: 0px 0 0px 0;
  float: none;
  width: 94%;
}
.gallery_gd_row img.gallery_1 {
  border: none;
  max-width: 100%;
  margin: 0 0 15px 15px;
  float: left;
  width: 61%;
}
.gallery_gd_row img.gallery_2 {
  border: none;
  max-width: 100%;
  margin: 0 0 15px 15px;
  float: left;
  width: 36%;
}
.gallery_gd_row img.gallery_4 {
  border: none;
  max-width: 100%;
  margin: 0 0 15px 15px;
  float: left;
  width: 61%;
}
.gallery_gd_row img.gallery_3 {
  border: none;
  max-width: 100%;
  margin: 0 0 15px 15px;
  float: left;
  width: 36%;
}

.gallery_gd_2ndrow img {
  border: none;
  max-width: 100%;
  margin: 0 0 15px 15px;
  float: left;
}
.gallery_gd_2ndrow2 img {
  border: none;
  max-width: 100%;
  margin: 0 0 15px 15px;
  float: left;
}
.gallery_gd_2ndrow {
  max-width: 100%;
  margin: 0 0 0 0;
  float: left;
  width: 67.5%;
}
.gallery_gd_2ndrow2 {
  max-width: 100%;
  margin: 0 0 0 3%;
  float: left;
  width: 26.5%;
}

.banner_gd_hub {
  padding: 50px 0 140px 0;
  float: left;
  background-color: #ffffff;
  margin: 0 1% 0px 1%;
  width: 98%;
  border-radius: 0 0 30px 30px;
}
.banner_gd_hub h2 {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  font-family: "GothamMedium";
  font-size: 84px;
  color: #000;
  font-style: italic;
  letter-spacing: -5px;
  position: relative;
}
.banner_gd_hub h2 span.star {
  position: absolute;
  top: -36px;
  margin-left: 0;
}
.banner_gd_hub h2 img.icon_gd_hub_left {
  margin: 0 0 0 12px;
  width: 80px;
}

.banner_gd_hub h1 {
  float: left;
  margin: 0px 0 0 0;
  padding: 0;
  width: 100%;
  text-align: center;
  font-family: "Gotham-Bold";
  font-size: 84px;
  color: #000;
  letter-spacing: -3px;
  line-height: 70px;
  position: relative;
}

.banner_gd_hub h1 span.icon_about_title {
  position: absolute;
  top: 41px;
  margin-left: -58px;
}
.banner_gd_hub h1 span.icon_about_title img {
  width: 80px;
}

.gd_hub_empowering_studios {
  float: left;
  margin: -70px 0 0 0;
  padding: 0 20px;
  width: 100%;
  position: relative;
}

.gd_hub_empowering_studios_area {
  float: left;
  margin: 0 0 0 0;
  padding: 75px 0 60px 0;
  width: 100%;
  background-color: #010101;
  border-radius: 30px;
}

.gd_hub_empowering_studios_info {
  float: left;
  margin: -130px 0 0 0;
  padding: 0 0px;
  width: 100%;
  position: relative;
}

.gd_hub_empowering_studios_info h3 {
  font-family: "FivoSansModern-Regular";
  font-size: 75px;
  float: left;
  margin: 30px 0 0px 0;
  letter-spacing: -5px;
  padding: 0 35% 0 0;
  width: 100%;
  text-align: left;
  color: #fff;
  background: -webkit-linear-gradient(#dd5151, #a530a0);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gd_hub_empowering_studios_details {
  float: left;
  margin: 130px 0 0 0;
  padding: 0 0px;
  width: 100%;
}
.gd_hub_empowering_studios_details p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #fff;
  float: left;
  margin: 30px 0px 0;
  padding: 0 0%;
  width: 100%;
  text-align: left;
}
.gd_hub_empowering_studios_details p b {
  font-family: "Metropolis-Bold";
}

.gd_hub_info_area {
  float: left;
  margin: 20px 1% 20px 1%;
  padding: 70px 0 60px 0;
  width: 98%;
  background-color: #ffffff;
  border-radius: 30px;
}
.gd_hub_info_area h2 {
  font-family: "FivoSansModern-Bold";
  font-size: 100px;
  line-height: 100px;
  float: left;
  margin: 0 0 30px 0;
  letter-spacing: -2px;
  padding: 0 8%;
  width: 100%;
  text-align: center;
  color: #fff;
  background: -webkit-linear-gradient(#dd5151, #a530a0);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gd_hub_info_area p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000000;
  float: left;
  margin: 0px 0 35px 0;
  padding: 0 19%;
  width: 100%;
  text-align: center;
}
.gd_hub_info_area p.inner {
  font-family: "Metropolis-SemiBold";
  font-size: 20px;
  color: #000000;
  float: left;
  margin: 0px 0 35px 0;
  padding: 0 24%;
  width: 100%;
  text-align: center;
}

.gd_hub_values_box_area {
  float: left;
  margin: 0;
  padding: 60px 0 0 0;
  width: 100%;
}

.gd_hub_values_box_area h2.our_values {
  font-family: "FivoSansModern-Heavy";
  font-size: 50px;
  line-height: 50px;
  float: left;
  margin: 0 0 0px 0;
  letter-spacing: -2px;
  padding: 66px 10% 0 0;
  width: 100%;
  text-align: left;
  color: #fff;
  background: -webkit-linear-gradient(#dd5151, #a530a0);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gd_hub_values_box {
  float: left;
  margin: 0 0 25px 0;
  padding: 35px 35px 0 40px;
  width: 100%;
  border-radius: 60px;
  min-height: 330px;
  background: rgba(239, 239, 239, 0.4);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  -webkit-transition: all 0.3s ease-in-out;
}
.gd_hub_values_box:hover {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-transition: all 0.3s ease-in-out;
}

.gd_hub_values_box .icon_number {
  float: left;
  margin: 0;
  padding: 0 0 0 0;
  width: 100%;
  font-family: "Metropolis-Bold";
  font-size: 18px;
  color: #000;
}
.gd_hub_values_box .icon_number p {
  float: left;
  margin: 0;
  padding: 40px 0 0 0;
  width: auto;
  font-family: "Metropolis-Bold";
  font-size: 18px;
  color: #000;
}
.gd_hub_values_box .icon_number img {
  float: right;
  border: none;
  max-width: 100%;
  width: 80px;
}
.gd_hub_values_box h3 {
  font-family: "FivoSansModern-Bold";
  font-size: 36px;
  color: #000000;
  float: left;
  margin: 0 0 20px 0;
  padding: 0 0px 0 0;
  width: 100%;
}
.gd_hub_values_box p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000000;
  float: left;
  margin: 0px 0 0px 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: left;
}

.games_are_played {
  float: left;
  margin: 0px 1% 0px 1%;
  padding: 140px 0 70px 0;
  width: 98%;
  background-color: #ffffff;
  border-radius: 30px;
}
.games_are_played h2 {
  font-family: "FivoSansModern-Heavy";
  font-size: 78px;
  line-height: 78px;
  float: left;
  margin: 0 0 30px 0;
  letter-spacing: -2px;
  padding: 0 10% 0 0;
  width: 100%;
  text-align: left;
  color: #fff;
  background: -webkit-linear-gradient(#dd5151, #a530a0);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}
.games_are_played h2 img.games_are_played_icon {
  position: absolute;
  right: 17%;
  top: 55px;
  z-index: 99;
  width: 112px;
}
.games_are_played p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000000;
  float: left;
  margin: 0px 0 0px 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: left;
}
.games_are_played_info {
  float: left;
  margin: 0px 0 0px 0;
  padding: 215px 0 0 0;
  width: 100%;
}
.games_are_played_info p {
  font-family: "Metropolis-Regular";
  font-size: 20px;
  color: #000000;
  float: left;
  margin: 0px 0 0px 0;
  padding: 0 0 0 0;
  width: 100%;
  text-align: left;
}

.home_destop {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  display: block;
}
.home_mobile {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  display: none;
}
.banner_programs img.banner_programs_top_img {
  display: none;
}
.banner_programs_bottom img.banner_programs_bottom_img {
  width: 96%;
  margin: 0 2%;
  display: none;
}
.contact_us_programs img.contact_us_programs_bolt {
  display: none;
}
@media (max-width: 1280px) {
  .gallery_gd_row {
    width: 71%;
    margin: 0;
  }
  .gallery_gd_row2 {
    width: 28%;
  }
  .gallery_gd_row img {
    height: 241px;
  }
  .gallery_gd_row2 img {
    height: 498px;
  }

  .gallery_gd_2ndrow {
    width: 72%;
  }
  .gallery_gd_2ndrow2 {
    margin: 0;
    width: 28%;
  }
  .gallery_gd_2ndrow img {
    height: 241px;
  }
  .gallery_gd_2ndrow2 img {
    height: 496px;
  }
}

@media screen and (min-width: 1278px) and (max-width: 1360px) {
  .banner .employees {
    margin: 180px 1% 0 0%;
    width: 200px;
    min-height: 240px;
    padding: 80px 20px 20px 20px;
  }
  .banner .employees h3 {
    font-size: 48px;
  }
  .banner .employees p {
    font-size: 21px;
  }
  .banner .advertising_partners {
    margin: 180px 0 0 0;
    width: 200px;
    min-height: 240px;
    padding: 80px 20px 20px 20px;
  }
  .banner .advertising_partners h3 {
    font-size: 48px;
  }
  .banner .advertising_partners p {
    font-size: 21px;
  }
  .banner .games_published {
    margin: 180px 1% 0 0;
    width: 200px;
    min-height: 240px;
    padding: 80px 20px 20px 20px;
  }
  .banner .games_published h3 {
    font-size: 48px;
  }
  .banner .games_published p {
    font-size: 21px;
  }
  .banner .dau {
    margin: 180px 0 0 0;
    width: 200px;
    min-height: 240px;
    padding: 80px 20px 20px 20px;
  }
  .banner .dau h3 {
    font-size: 48px;
  }
  .banner .dau p {
    font-size: 21px;
  }

  .left_area .games_icon_row img {
    height: 86px;
  }
  .right_area .games_icon_row_right img {
    height: 86px;
  }
  .our_games .center_area p {
    font-size: 19px;
  }
  .our_games .center_area ul.games_type li a {
    padding: 8px 15px;
  }
  .love_to_work {
    padding: 50px 20px 120px 40px;
  }
  .love_to_work h2 {
    font-size: 72px;
    line-height: 82px;
  }
  .icon-content {
    left: 21%;
    top: 128px;
  }
  .content_growth_sand_box {
    padding: 0 0 0 60px;
  }
  .pixls_inner {
    padding: 300px 0 45px 34px;
  }
  .gd_hub_content p {
    padding: 0 0%;
  }
  .ceo_pic {
    padding: 80px 0 0 0;
  }
  .message p {
    width: 100%;
  }
  .slider_img_content .active {
    overflow: hidden;
  }
  .contact_us h1 {
    font-size: 60px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1278px) {
  .banner h2 {
    font-size: 72px;
  }
  .banner h2 span.star {
    top: -25px;
  }
  .banner h2 img.growing {
    margin: 0 0 0 10px;
    width: 80px;
  }
  .banner h1 {
    font-size: 72px;
  }
  .banner h1 span img {
    width: 60px;
  }
  .banner h1 img {
    width: 70px;
  }

  .banner .employees {
    margin: 180px 1% 0 0%;
    width: 155px;
    min-height: 180px;
    height: auto;
    padding: 64px 15px 0 15px;
    border-radius: 30px;
  }
  .banner .employees h3 {
    font-size: 42px;
    line-height: 50px;
  }
  .banner .employees p {
    font-size: 18px;
  }
  .banner .advertising_partners {
    margin: 180px 0 0 0;
    width: 155px;
    min-height: 180px;
    height: auto;
    padding: 64px 15px 0 15px;
    border-radius: 30px;
  }
  .banner .advertising_partners h3 {
    font-size: 42px;
    line-height: 50px;
  }
  .banner .advertising_partners p {
    font-size: 18px;
  }
  .banner .games_published {
    margin: 180px 1% 0 0;
    width: 155px;
    min-height: 180px;
    height: auto;
    padding: 64px 15px 0 15px;
    border-radius: 30px;
  }
  .banner .games_published h3 {
    font-size: 42px;
    line-height: 50px;
  }
  .banner .games_published p {
    font-size: 18px;
  }
  .banner .dau {
    margin: 180px 0 0 0;
    width: 155px;
    min-height: 180px;
    height: auto;
    padding: 64px 15px 0 15px;
    border-radius: 30px;
  }
  .banner .dau h3 {
    font-size: 42px;
    line-height: 50px;
  }
  .banner .dau p {
    font-size: 18px;
  }

  .banner .downloads .beating {
    top: 0;
    left: -210px;
    background-size: 240px;
    padding: 14px 20px;
    width: 240px;
    font-size: 18px;
    height: auto;
    min-height: 66px;
  }
  .banner .downloads {
    width: 270px;
    padding: 230px 0 26px 0;
    height: auto;
  }
  .banner .downloads img {
    border: none;
    width: 170px;
  }
  .banner .downloads h2 {
    font-size: 48px;
  }
  .our_games .center_area {
    padding: 0px 0 0 0;
  }
  .left_area .games_icon_row img {
    margin: 0 10px 10px 0;
    height: 70px;
  }
  .right_area .games_icon_row_right img {
    margin: 0 0 10px 10px;
    height: 70px;
  }
  .our_games .center_area h1 {
    font-size: 64px;
    line-height: 60px;
  }
  .our_games .icons a img {
    width: 48px;
  }
  .our_games .center_area h2 {
    font-size: 64px;
    line-height: 60px;
  }
  .our_games .center_area h3 {
    font-size: 64px;
    line-height: 60px;
  }
  .our_games .center_area h3 img {
    top: 6px;
    right: 5px;
    width: 56px;
  }
  .our_games .center_area p {
    font-size: 17px;
    margin: 15px 0 15px 0;
    line-height: 24px;
  }
  .our_games {
    padding: 90px 0 90px 0;
  }
  .publish {
    background-size: 70%;
    background-position: center 190px;
    padding: 60px 0 60px 0;
  }
  .content_creators {
    padding: 50px 0 50px 0;
    background-position: right 90px;
    background-size: 180px;
  }
  .leaves img {
    border: none;
    max-width: 100%;
    width: 180px;
  }
  .love_to_work h2 {
    font-size: 56px;
    line-height: 66px;
  }
  .icon-content {
    left: 22%;
    top: 113px;
    width: 50px;
  }
  .work_services .icons img {
    width: 90px;
  }
  .work_services {
    padding: 30px 30px;
  }
  .work_services h3 {
    font-size: 32px;
    line-height: 38px;
  }
  .growthsandbox-icon img {
    border: none;
    max-width: 100%;
    width: 120px;
  }
  .content_growth_sand_box {
    padding: 0 0 0 30px;
  }
  .fuel_growth p {
    width: 100%;
  }
  .fuel_growth ul.games_type li {
    padding: 8px 24px;
  }

  .tools h3 {
    padding: 0 5%;
    font-size: 36px;
  }
  .pixls_inner {
    padding: 292px 0 45px 34px;
  }
  .mission .mission_icom img {
    width: 120px;
  }
  .vision .vision_icom img {
    width: 120px;
  }
  .mission h2 {
    font-size: 72px;
    margin: 90px 0 0 0;
  }
  .mission h2 span {
    font-size: 72px;
  }
  .mission_inner {
    padding: 40px 40px 40px 40px;
  }
  .vision_inner {
    padding: 40px 40px;
  }
  .vision h2 {
    font-size: 72px;
    margin: 90px 0 0 0;
  }
  .vision h2 span {
    font-size: 72px;
  }
  .message p {
    width: 100%;
    margin: 0 0 20px 0;
  }
  .message h2 img {
    width: 120px;
  }
  .message h2 {
    font-size: 62px;
    margin: 0 0 20px 0;
  }
  .message h6 {
    margin: 0 0 20px 0;
  }
  .ceo_pic {
    background-size: 100%;
  }
  .blog_area h2 span {
    font-size: 72px;
    line-height: 78px;
  }
  .blog_area h2 img.icon_left {
    width: 64px;
  }
  .blog_area h2 img.icon_right {
    width: 72px;
  }
  .blog_area p {
    padding: 0 25%;
  }
  .meet_the_studios {
    padding: 40px 0;
  }
  .logo_studios .studios_logo img {
    height: 100px;
  }
  .logo_studios .studios_logo {
    margin: 25px 0;
  }
  .home_blog_post p {
    padding: 0 0;
  }
  .meet_the_studios h2 {
    font-size: 72px;
    line-height: 78px;
  }
  .meet_the_studios p {
    padding: 0 25%;
  }
  .meet_the_studios h2 img {
    width: 120px;
  }
  .game_changers h2 img {
    width: 120px;
  }
  .game_changers h2 {
    font-size: 72px;
    line-height: 78px;
  }
  .game_changers p {
    padding: 0 25%;
  }
  .game_changer img {
    width: 250px;
  }
  .game_changer .info {
    width: 50%;
    margin: 8px 0 0 30px;
  }
  .game_changer .info p {
    margin: 0 0 15px 0px;
  }
  .navmenu li {
    height: 406px;
    border-radius: 30px;
  }
  .contact_us h1 {
    font-size: 48px;
  }
  .slider_img_content .active {
    overflow: hidden;
  }
  .slider_img_content .info {
    margin: 30px 0 0 0;
  }
  .slider_img_content .info p {
    font-size: 18px;
    margin: 0 0 20px 0px;
    width: 90%;
  }

  /* ABout Page */
  .banner_about h2 {
    font-size: 72px;
  }
  .banner_about h1 {
    font-size: 72px;
    line-height: 72px;
  }
  .banner_about h2 span.star {
    top: -25px;
  }
  .banner_about h1 span.star {
    margin-left: 40px;
  }
  .company_philosphy .box {
    padding: 20px;
  }
  .company_philosphy .box .icon {
    margin: 0 0 30px 0;
  }
  .company_philosphy .box h4 {
    font-size: 31px;
    padding: 0 0px 0 0;
  }
  .about_us_col p {
    padding: 0 5%;
  }
  .empowering_area h2 {
    font-size: 72px;
    padding: 0 5%;
  }
  .empowering_area p {
    padding: 0 5%;
  }
  .empowering_area h2.our_values {
    font-size: 84px;
    line-height: 84px;
    padding: 80px 0% 0 0;
  }
  .our_values_box {
    padding: 20px;
  }
  .our_values_box h3 {
    font-size: 27px;
  }

  /* Games Page */
  .games_info .about_games {
    margin: 0 0 0 -152px;
    padding: 30px 0 30px 165px;
    width: 98%;
  }
  .about_games .game_title_icon .icon_game {
    width: 80px;
  }
  .about_games .game_title_icon .icon_game img {
    width: 70px;
  }
  .about_games .game_title_icon h5 {
    font-size: 18px;
    width: 65%;
  }
  .about_games .game_title_icon h3 {
    font-size: 21px;
    width: 60%;
    line-height: 24px;
  }
  .about_games ul.games_type1 li {
    margin: 2px;
  }
  .about_games ul.games_type1 li a {
    padding: 5px 10px;
    font-size: 11px;
  }
  .about_games p {
    font-size: 17px;
  }
  .about_games .store_links a {
    padding: 10px 15px;
    font-size: 12px;
    line-height: 19px;
    margin: 0 10px 0 0;
  }
  .about_games .store_links a img {
    margin: 0 5px 0 0;
    width: 16px;
  }
  .games_info .about_games_right {
    margin: 0 -200px 0 0;
    padding: 30px 125px 30px 20px;
  }
  .about_games_right .game_title_icon .icon_game {
    width: 80px;
  }
  .about_games_right .game_title_icon .icon_game img {
    width: 70px;
  }
  .about_games_right .game_title_icon h5 {
    font-size: 18px;
    width: 65%;
  }
  .about_games_right .game_title_icon h3 {
    font-size: 21px;
    width: 60%;
    line-height: 24px;
  }
  .about_games_right ul.games_type1 li {
    margin: 2px;
  }
  .about_games_right ul.games_type1 li a {
    padding: 5px 10px;
    font-size: 11px;
  }
  .about_games_right p {
    font-size: 17px;
  }
  .about_games_right .store_links a {
    padding: 10px 15px;
    font-size: 12px;
    line-height: 19px;
    margin: 0 10px 0 0;
  }
  .about_games_right .store_links a img {
    margin: 0 5px 0 0;
    width: 16px;
  }
  .games_info {
    margin: 90px 0 30px 0;
  }
  .our_favorite_games {
    padding: 80px 0 40px 0;
  }
  .our_awesome_studios {
    padding: 80px 0 80px 0;
  }
  .our_awesome_studios h1 {
    font-size: 90px;
    margin: -25px 0 0 0;
  }
  .sections .our_studios_logo {
    padding: 44px 0 0 0;
    width: 220px;
    height: 220px;
  }
  .flex-center {
    height: 240px;
  }
  .our_awesome_studios img.studios_icon_center {
    width: 150px;
    height: 150px;
    left: 42%;
    top: 166px;
  }
  ul.accordion-list li {
    padding: 40px 50px;
  }
  .games_icons_row img {
    margin: 10px 0 0px 10px;
    width: 85px;
  }
  .answer .studios_info .store_links a {
    margin: 0 10px;
    padding: 16px 28px;
  }

  /* gdhub Page */
  .gd_hub_empowering_studios_info {
    margin: -80px 0 0 0;
  }
  .gd_hub_empowering_studios_info h3 {
    padding: 0 20% 0 0;
  }
  .gd_hub_info_area p {
    padding: 0 10%;
  }
  .gd_hub_info_area p.inner {
    padding: 0 10%;
  }
  .gd_hub_values_box {
    padding: 20px;
    border-radius: 30px;
  }
  .gd_hub_values_box h3 {
    font-size: 28px;
  }
  .gd_hub_info_area .gd_hub_values_box p {
    padding: 0 0;
    font-size: 19px;
  }
  .gd_hub_values_box_area h2.our_values {
    font-size: 45px;
    line-height: 50px;
    padding: 60px 0 0 0;
  }
  .games_are_played h2 {
    font-size: 72px;
    line-height: 72px;
    padding: 0 0 0 0;
  }
  .games_are_played h2 img.games_are_played_icon {
    right: 1%;
    top: 55px;
    width: 80px;
  }

  /* IP Page */
  .banner_ip {
    background-size: 180px;
    padding: 50px 0 60px 0;
  }
  .banner_ip_top {
    background-position: 0px 80px;
    background-size: 140px;
  }
  .craft_worlds h3 {
    font-size: 48px;
    margin: 0px 0 10px 0;
  }
  .craft_worlds p {
    font-size: 18px;
    line-height: 24px;
  }
  .craft_worlds_img img.parallax-image {
    margin: 0px 5% 0 0;
  }
  .gameverse_monetize_img img.parallax-image {
    margin: 0px 10% 0 0;
  }
  .gameverse_monetize h3 {
    font-size: 48px;
  }
  .gameverse_monetize p {
    font-size: 18px;
    line-height: 24px;
  }
  .level_up_game_img img.parallax-image {
    margin: 0px 2% 0 0;
  }
  .monetize_game_img img.parallax-image {
    margin: 0px 2% 0 0;
  }
  .monetize_game_img {
    background-position: center center;
  }
  .roadmap h2 {
    font-size: 72px;
    padding: 0 0 0 0px;
    line-height: 72px;
  }
  .roadmap .roadmap_info {
    padding: 140px 0 0 0;
  }

  .roadmap_services {
    padding: 20px;
  }
  .roadmap_services h3 {
    margin: 20px 0 10px 0;
    font-size: 24px;
  }
  .roadmap_services p {
    font-size: 15px;
    margin: 90px 0 0 0;
  }
  .roadmap_services ul li {
    font-size: 15px;
    margin: 20px 0 0px 0;
  }
  .roadmap_services .icon_number {
    font-size: 24px;
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
  .ip_content_image img.parallax-image {
    margin: 40px 8% 0 0;
  }

  /* Tools Page */
  .brandanimationcolor .marquee--inner {
    width: 400%;
  }
  .applifier_system_info .icon_system_info img {
    width: 60px;
  }
  .applifier_system_info h3 {
    font-size: 24px;
    width: 100%;
  }
  .we_help {
    padding: 40px 40px 40px 20px;
  }
  .we_help h4 {
    font-size: 56px;
    margin: 15px 0 0px 0;
    line-height: 60px;
  }
  .we_help h3 {
    font-size: 33px;
    margin: 10px 0 10px 0;
  }
  .monetization_mastermind {
    padding: 40px 20px 40px 20px;
  }
  .monetization_mastermind h3 {
    font-size: 31px;
  }
  .monetization_mastermind p {
    width: 100%;
  }
  .benchmark {
    padding: 40px 20px 40px 20px;
  }
  .benchmark h3 {
    font-size: 31px;
  }
  .benchmark p {
    width: 100%;
  }
  .benchmark img {
    max-width: 44%;
  }
  .gdi_system_info .icon_system_info img {
    width: 60px;
  }
  .gdi_system_info h3 {
    font-size: 24px;
    width: 100%;
  }
  .gdi_system_info p {
    font-size: 17px;
    line-height: 22px;
  }

  .we_help_gdi {
    padding: 40px 40px 40px 20px;
  }
  .we_help_gdi h4 {
    font-size: 56px;
    margin: 15px 0 0px 0;
    line-height: 60px;
  }
  .we_help_gdi h3 {
    font-size: 33px;
    margin: 10px 0 10px 0;
  }

  /* Pixls Page */
  .banner_programs_top img.banner_top_1 {
    width: 150px;
  }
  .banner_programs_top img.banner_top_title {
    margin: -10px 0 0 9%;
    width: 820px;
  }
  .banner_programs_top img.banner_top_logo {
    margin: 70px 0 0 12%;
  }
  .banner_programs_top img.banner_top_3 {
    margin: -143px 0 0 0%;
    width: 160px;
  }
  .banner_programs_top img.banner_top_4 {
    width: 150px;
  }
  .banner_programs_top img.banner_top_5 {
    margin: -192px 22% 0 0;
  }
  .banner_programs_top img.banner_top_2 {
    margin: -70px 0 0 0;
    width: 100px;
  }
  .just_play2 {
    margin: 50px 0 50px 0;
  }
  .right_text {
    top: 130px;
  }
  .social_icons_banner {
    top: 130px;
  }

  .banner_programs_row img.top_line_ing_1 {
    margin: 0 0 0 5px;
  }
  .banner_programs_row img.top_line_ing_2 {
    margin: 25px 0 0 20px;
    width: 380px;
  }
  .banner_programs_row img.top_line_ing_3 {
    margin: 15px 0 0 20px;
    width: 200px;
  }
  .banner_programs_row img.top_line_ing_4 {
    margin: -30px 0 0 -120px;
  }
  .banner_programs_row img.top_line_ing_5 {
    margin: 15px 0 0 20px;
    width: 70px;
  }
  .banner_programs_row img.top_line_ing_6 {
    margin: -50px 0 0 20px;
    width: 150px;
  }
  .banner_programs_row_center img.center_line_ing_1 {
    width: 140px;
  }
  .banner_programs_row_center img.center_line_ing_2 {
    margin: 1px 0 0 20px;
    width: 280px;
  }
  .banner_programs_row_center img.center_line_ing_4 {
    margin: 0 0 0 -66px;
    width: 244px;
  }
  .banner_programs_row_center img.center_line_ing_5 {
    margin: 12px 0 0 20px;
  }
  .banner_programs_row_bottom img.bottom_line_ing_1 {
    width: 105px;
  }
  .banner_programs_row_bottom img.bottom_line_ing_2 {
    margin: 42px 0 0 25px;
    width: 650px;
  }
  .banner_programs_row_bottom img.bottom_line_ing_3 {
    margin: 88px 0 0 -45%;
    width: 110px;
  }
  .banner_programs_row_bottom img.bottom_line_ing_4 {
    width: 80px;
  }
  .banner_programs_row_center {
    margin: 0 0 0 0;
  }
  .pixls_program_content_icon .pixls_icon1 {
    width: 80%;
  }
  .pixls_program_content_icon .pixls_icon2 {
    left: 10%;
    width: 80%;
  }
  .pixls_program_content img.pixls_program_img {
    margin: 80px 0 0 0;
    max-width: 96%;
  }
  .program_vision_area {
    padding: 90px 0px;
  }

  .program_vision_title h1 {
    font-size: 96px;
  }
  .program_vision_title h1 img {
    margin: -84px 0 0 222px;
    width: 40px;
  }
  .program_vision_info {
    padding: 0px 0px 0 30px;
  }
  .program_vision_info p {
    font-size: 21px;
    margin: 15px 0 15px 0;
  }
  .program_plan_title h1 {
    font-size: 100px;
    line-height: 100px;
  }
  .program_plan_title h1 img.program_plan_title_icon {
    width: 44px;
    top: -10px;
    left: -67px;
  }

  .program_plan_info .program_plan_details p {
    font-size: 15px;
  }
  .program_plan_info .program_plan_details h3 {
    font-size: 16px;
  }
  .program_plan_info img.star_participation_criteria {
    width: 50px;
    bottom: 0;
    right: -30px;
  }
  .our_partners {
    padding: 80px 0px;
  }
  .achievements h2 {
    font-size: 96px;
    line-height: 96px;
  }

  .achievements h2 span.margin_left {
    margin-left: 40px !important;
  }
  .achievements h2 span.margin_left2 {
    margin-left: 110px !important;
  }
  .achievements img.achievements_star {
    margin: -30px 0 0 40px;
    width: 100px;
  }
  .future_plans_info img.fadein_bottom_23 {
    max-width: 80%;
  }

  .future_plans_info img.future_plans_icon_right {
    top: -50px;
    width: 100px;
  }
  .future_plans_info img.future_plans_icon_left {
    bottom: 40px;
    width: 100px;
  }

  .future_plans {
    padding: 60px 0 0px 0;
  }
  .achievements_img {
    margin: 70px 0 0 0;
  }
  .program_plan {
    padding: 80px 0 50px 0;
  }

  .flip span {
    font-size: 100px;
    line-height: 100px;
  }
  .contact_us_programs h2 {
    font-size: 100px;
    line-height: 72px;
  }
  .flip {
    height: 90px;
    width: 80%;
  }
  .contact_us_programs h1 span.righttext {
    padding: 30px 0 0 0;
  }
  .contact_us_programs h2 img {
    width: 90px;
    margin: 10px 0 0 0;
  }
  .contact_us_programs h3 {
    font-size: 100px;
    line-height: 70px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1022px) {
  .main_menu ul li a {
    font-size: 13px;
  }
  .main_menu ul li {
    margin: 0 5px;
  }
  .banner h2 {
    font-size: 56px;
  }
  .banner h2 img.growing {
    width: 62px;
  }
  .banner h2 span.star img {
    width: 30px;
  }
  .banner h1 {
    font-size: 56px;
  }
  .banner h1 span img.box {
    width: 50px;
  }
  .banner h1 img.menap {
    width: 56px;
  }

  .banner .downloads {
    width: 216px;
    padding: 180px 0 20px 0;
    height: auto;
  }
  .banner .downloads img {
    width: 140px;
  }
  .banner .downloads h2 {
    font-size: 42px;
  }
  .banner .downloads p {
    font-size: 21px;
  }
  .banner .downloads .beating {
    top: 0;
    left: -179px;
    background-size: 200px;
    padding: 11px 20px;
    width: 200px;
    font-size: 16px;
    height: auto;
    min-height: 56px;
  }

  .banner .employees {
    margin: 160px 1% 0 0%;
    width: 115px;
    min-height: 140px;
    height: auto;
    padding: 42px 15px 0 15px;
    border-radius: 25px;
  }
  .banner .employees h3 {
    font-size: 36px;
    line-height: 42px;
  }
  .banner .employees p {
    font-size: 16px;
  }
  .banner .advertising_partners {
    margin: 160px 0 0 0;
    width: 115px;
    min-height: 140px;
    height: auto;
    padding: 42px 15px 0 15px;
    border-radius: 25px;
  }
  .banner .advertising_partners h3 {
    font-size: 36px;
    line-height: 42px;
  }
  .banner .advertising_partners p {
    font-size: 16px;
  }
  .banner .games_published {
    margin: 160px 1% 0 0;
    width: 115px;
    min-height: 140px;
    height: auto;
    padding: 42px 15px 0 15px;
    border-radius: 25px;
  }
  .banner .games_published h3 {
    font-size: 36px;
    line-height: 42px;
  }
  .banner .games_published p {
    font-size: 16px;
  }
  .banner .dau {
    margin: 160px 0 0 0;
    width: 115px;
    min-height: 140px;
    height: auto;
    padding: 42px 15px 0 15px;
    border-radius: 25px;
  }
  .banner .dau h3 {
    font-size: 36px;
    line-height: 42px;
  }
  .banner .dau p {
    font-size: 16px;
  }
  .publish {
    background-size: 72%;
    background-position: center 175px;
    padding: 60px 0 60px 0;
  }

  .left_area .games_icon_row img {
    margin: 0 8px 10px 0;
    height: 50px;
  }
  .right_area .games_icon_row_right img {
    margin: 0 0 10px 8px;
    height: 50px;
  }

  .our_games .center_area h1 {
    font-size: 48px;
    line-height: 56px;
  }
  .our_games .icons a img {
    width: 36px;
  }
  .our_games .center_area h2 {
    font-size: 48px;
    line-height: 48px;
  }
  .our_games .center_area h3 {
    font-size: 48px;
    line-height: 48px;
  }
  .our_games .center_area h3 img {
    width: 42px;
  }
  .our_games .center_area p {
    margin: 15px 0 15px 0;
    padding: 0 0%;
    font-size: 15px;
    line-height: 20px;
  }
  .our_games .center_area ul.games_type li a {
    padding: 6px 12px;
    font-size: 10px;
  }
  .our_games .center_area {
    padding: 0;
  }
  .our_games {
    padding: 60px 0 60px 0;
  }
  .leaves img {
    border: none;
    max-width: 100%;
    width: 140px;
  }
  .love_to_work {
    padding: 30px 28px 140px 28px;
    margin: -40px 0 0 0;
    border-radius: 30px;
  }
  .love_to_work h2 {
    font-size: 44px;
    line-height: 48px;
  }
  .icon-content {
    left: 21%;
    top: 81px;
    width: 30px;
  }
  .love_to_work p {
    width: 100%;
    font-size: 17px;
  }
  .content_creators {
    background-size: 140px;
    background-position: right 50px;
    padding: 30px 0 50px 0;
  }
  .work_services .icons:after {
    width: 58px;
    height: 58px;
  }
  .growth_sand_box {
    padding: 40px 0 40px 0;
  }
  img.content_creators_icon {
    margin: -140px 0 0 0;
  }
  .content_growth_sand_box {
    padding: 0 0 0 20px;
  }
  .fuel_growth {
    padding: 30px 0 0 20px;
  }
  .fuel_growth p {
    font-size: 18px;
    line-height: 24px;
    margin: 0px 0 15px 0;
  }
  .fuel_growth ul.games_type li {
    padding: 6px 12px;
    font-size: 11px;
  }
  .work_services {
    padding: 15px;
    border-radius: 30px;
  }
  .work_services h3 {
    font-size: 25px;
    line-height: 30px;
  }
  .work_services p {
    font-size: 16px;
  }
  .work_services .icons img {
    width: 60px;
  }
  .content_growth_sand_box h3 {
    font-size: 72px;
    line-height: 72px;
  }
  .content_growth_sand_box img {
    width: 200px;
  }
  .growthsandbox-icon img {
    border: none;
    max-width: 100%;
    width: 90px;
  }
  .tools h3 {
    font-size: 24px;
    line-height: 32px;
  }
  .tools p {
    margin: 15px 0 30px 0;
    line-height: 24px;
    font-size: 15px;
  }
  .tools {
    border-radius: 30px;
  }
  .tools_inner {
    background-size: cover;
    border-radius: 30px;
  }
  .pixls h3 {
    font-size: 32px;
    line-height: 32px;
  }
  .pixls {
    border-radius: 30px;
  }
  .pixls_inner {
    border-radius: 30px;
    padding: 190px 0 45px 34px;
  }
  .gd_created h2 {
    font-size: 48px;
  }
  .gd_created {
    padding: 60px 0;
  }
  .gd_created h4 {
    margin: 0 0 30px 0;
  }
  .mission .mission_icom img {
    width: 80px;
  }
  .vision .vision_icom img {
    width: 80px;
  }
  .mission h2 span {
    font-size: 56px;
  }
  .vision h2 span {
    font-size: 56px;
  }
  .message h2 img {
    width: 80px;
  }
  .message h2 {
    font-size: 48px;
    margin: 0 0 20px 0;
  }
  .logo_studios .studios_logo img {
    height: 80px;
  }
  .logo_studios .studios_logo {
    margin: 20px 0;
  }
  .game_changers h2 {
    font-size: 56px;
    line-height: 60px;
  }
  .game_changers h2 img {
    width: 90px;
  }
  .contact_us h1 {
    font-size: 36px;
  }
  .submit_project {
    margin: 20px 0 0 0;
  }
  .navmenu li {
    height: 325px;
    border-radius: 20px;
  }
  .game_changer img {
    width: 200px;
  }
  .game_changer .info {
    width: 50%;
    margin: 0 0 0 20px;
  }
  .footer_area {
    padding: 30px 15px;
    border-radius: 30px 30px 0 0;
  }
  .footer_area p {
    font-size: 16px;
  }
  .footer_area ul.footer_menu li {
    margin: 0 0px 15px 0;
  }
  .footer_area ul.footer_menu li a {
    font-size: 16px;
  }
  .footer_area h3 {
    font-size: 18px;
  }
  .footer_area ul.menufooter li a {
    font-size: 16px;
  }
  .footer_area ul.menufooter li {
    margin: 0 0px 10px 15px;
  }
  .footer_area .copy_right {
    font-size: 16px;
    margin: 200px 0 0 0;
  }
  .footer_area .address {
    font-size: 16px;
  }
  .footer_area .phone {
    font-size: 16px;
  }
  .footer_area .social_icons a {
    padding: 6px;
    width: 30px;
    height: 30px;
    margin: 0 5px 0 0;
  }

  .gd_hub_content {
    padding: 20px 0 0;
  }
  .gd_hub_content img {
    border: none;
    max-width: 100%;
    width: 180px;
  }
  .gd_hub_content h3 {
    font-size: 24px;
    line-height: 26px;
    margin: 10px 0 10px 0;
  }
  .gd_hub_content p {
    padding: 0 0%;
    font-size: 15px;
    line-height: 20px;
    padding: 0 0%;
  }
  .gd_hub_content .learn_more {
    margin: 25px 0 0 0;
  }
  .mission_line_full {
    top: -180px;
  }
  .mission_inner {
    padding: 20px 20px 20px 20px;
  }
  .mission_icom span {
    font-size: 18px;
    width: 68%;
  }
  .mission h2 {
    margin: 100px 0 0 0;
  }
  .mission p {
    font-size: 17px;
    line-height: 24px;
    width: 100%;
  }

  .vision_inner {
    padding: 20px;
  }
  .vision_icom span {
    font-size: 18px;
    width: 68%;
  }
  .vision h2 {
    margin: 100px 0 0 0;
  }
  .vision p {
    font-size: 17px;
    line-height: 24px;
    width: 100%;
    margin: 0 0 12px 0;
  }
  .ceo_pic {
    background-size: 100%;
  }

  .message p {
    font-size: 17px;
    margin: 0 0 15px 0;
    width: 100%;
  }
  .message {
    padding: 70px 0 0 0;
  }
  .blog_area {
    margin: 70px 0 0 0;
    padding: 60px 0 0 0;
  }
  .blog_area p {
    padding: 0 21%;
  }
  .blog_area ul.post_type {
    margin: 10px 0 40px 0;
  }
  .blog_area h2 span {
    font-size: 78px;
  }
  .blog_area h2 img.icon_left {
    width: 70px;
  }
  .blog_area h2 img.icon_right {
    margin: -20px 0 0 0px;
    width: 74px;
  }
  .meet_the_studios h2 {
    font-size: 78px;
    line-height: 80px;
  }
  .meet_the_studios {
    padding: 0 0 30px 0;
  }
  .meet_the_studios p {
    padding: 0 20%;
  }
  .slider_img_content .active {
    overflow: hidden;
  }
  .slider_img_content .info p {
    font-size: 16px;
    margin: 0 0 15px 0px;
    width: 90%;
  }

  /* ABout Page */
  .banner_about h2 {
    font-size: 48px;
    letter-spacing: -1px;
    margin: 0 0 5px 0;
  }
  .banner_about h2 img.growing {
    margin: 0 0 0 5px;
    width: 45px;
  }
  .banner_about h2 span.star {
    top: -18px;
    margin-left: -7px;
  }
  .banner_about h2 span.star img {
    border: none;
    max-width: 36px;
  }
  .banner_about h1 {
    font-size: 48px;
    letter-spacing: -2px;
    line-height: 48px;
  }
  .banner_about h1 span.star {
    position: absolute;
    top: -30px;
    margin-left: 5px;
  }
  .banner_about h1 span.star img {
    width: 60px;
  }
  .banner_about h1 span.icon_about_title {
    position: absolute;
    top: 30px;
    margin-left: -15px;
  }
  .banner_about h1 span.icon_about_title img {
    width: 45px;
  }
  .about_banner img {
    max-width: 90%;
  }
  .banner_about {
    padding: 30px 0 0px 0;
  }
  .about_us {
    padding: 0 1%;
  }
  .about_us_col {
    padding: 20px 0 20px 0;
  }
  .about_us_col h2 img.about_us_icon_right {
    right: 5px;
    width: 60px;
  }
  .about_us_col h2 img.about_us_title_icon_left {
    position: relative;
    left: 10px;
    width: 70px;
    top: -9px;
  }
  .about_us_col h2 span {
    font-family: "FivoSansModern-Heavy";
    font-size: 48px;
  }
  .about_us_col h2 img.about_us_title_icon_right {
    position: relative;
    left: -15px;
    top: -20px;
    width: 90px;
  }
  .about_us_col h2 {
    font-size: 48px;
    height: 140px;
  }
  .about_us_col p {
    font-size: 17px;
    margin: 0px 0 30px 0;
    padding: 0 5%;
  }
  .about_us_col h3 {
    font-size: 42px;
    margin: 0 0 30px 0;
  }
  .company_philosphy .box {
    margin: 10px 1%;
    padding: 20px;
    width: 100%;
    border-radius: 30px;
    min-height: auto;
    max-width: 31%;
  }
  .company_philosphy .bg2 {
    margin-top: 60px;
  }
  .company_philosphy .box .icon {
    margin: 0 0 20px 0;
  }
  .company_philosphy .box .icon img {
    max-width: 60px;
  }
  .company_philosphy .box h4 {
    font-size: 24px;
  }
  .company_philosphy .box p {
    font-size: 15px;
  }
  .empowering_area {
    padding: 30px 0 0 0;
  }
  .empowering {
    padding: 0 1%;
  }
  .empowering_area h2 {
    font-size: 48px;
    margin: 0 0 30px 0;
  }
  .mission_line_full2 {
    top: -190px;
  }
  .empowering_area p {
    font-size: 17px;
    margin: 0px 0 20px 0;
    padding: 0 5%;
  }
  .empowering_area .gap {
    height: 0;
  }
  .empowering_area h2.our_values {
    font-size: 72px;
    line-height: 72px;
    margin: 0 0 20px 0;
    padding: 70px 0 0 0;
  }
  .our_values_box {
    margin: 0 1% 25px 1%;
    padding: 20px 20px 15px 20px;
    width: 98%;
    border-radius: 30px;
    min-height: 305px;
  }
  .our_values_box .icon_number img {
    width: 60px;
  }
  .our_values_box h3 {
    font-size: 24px;
    margin: 0 0 10px 0;
  }
  .our_values_box p {
    font-size: 17px;
    padding: 0 !important;
  }
  .our_values_box:hover {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    -webkit-transition: all 0.3s ease-in-out;
  }
  .senior_leadership {
    margin: 30px 1% 0 1%;
    padding: 20px;
    border-radius: 20px 20px;
  }
  .senior_leadership h2 img {
    right: 0px;
    width: 60px;
  }
  .senior_leadership h2 {
    font-size: 48px;
    line-height: 48px;
    padding: 25px 0 0 0;
  }
  .senior_leadership p {
    font-size: 17px;
    margin: 15px 0 20px 0;
    width: 100%;
    line-height: 22px;
    padding: 0;
  }
  .col-19 {
    width: 50%;
    padding: 0 5px;
  }
  .col-20 {
    width: 100%;
    padding: 0;
  }
  .leadership_info_1st {
    margin: 50px 0 0 0;
  }
  .leadership_info_1st .leadership_info_1st_back p {
    font-size: 20px !important;
  }
  .leadership_info_2nd {
    margin: 25px 0 0 0;
  }
  .leadership_info_2nd_back p {
    font-size: 20px !important;
  }
  .our_journey h2 {
    font-size: 31px;
    line-height: 34px;
    margin: 0 0 0px 0;
    padding: 20px 0 20px 0;
  }
  .game_changers {
    padding: 30px 0;
  }

  /* Games Page */
  .banner_games h2 {
    font-size: 72px;
  }
  .banner_games h2 img.games_icon_love {
    margin: 14px 0 0 10px;
    width: 60px;
  }
  .banner_games h3 {
    margin: -10px 0 0 0;
    font-size: 72px;
  }
  .banner_games h3 img.games_icon_title_left {
    width: 98px;
  }
  .banner_games h3 img.games_icon_title_right {
    margin: 5px 0 0 -20px;
    width: 100px;
  }
  .slide {
    width: 110px;
    height: 120px;
    margin: 0 5px;
  }
  .logos_games {
    margin: 0 0 20px 0;
  }
  .our_favorite_games {
    padding: 80px 0 80px 0;
  }

  .our_favorite_games h1 {
    font-size: 78px;
    line-height: 78px;
  }
  .our_favorite_games h1 img.favoritegame-title-icon {
    width: 60px;
  }
  .our_favorite_games h1 a img {
    width: 48px;
  }
  .our_favorite_games .content_area {
    width: 90%;
  }
  .games_info .games_icon_left {
    margin: -40px 0 0 15px;
  }
  .games_info .about_games {
    margin: 0 0 0 -122px;
    padding: 20px 0 40px 133px;
    width: 100%;
  }
  .about_games .game_title_icon .icon_game {
    width: 28%;
    margin: 0 2% 0 0;
  }
  .about_games .game_title_icon h5 {
    font-size: 15px;
  }
  .about_games .game_title_icon h3 {
    font-size: 21px;
    line-height: 21px;
  }

  .games_info {
    max-height: 100%;
    margin: 120px 0 0 0;
  }
  .games_info .games_icon_left {
    margin: 0px 1%;
    padding: 0 0 0 0px;
    width: 98%;
    text-align: center;
    background-color: #bef3ff;
    border-radius: 30px 30px 0 0;
  }
  .games_info .games_icon_left img {
    width: 120px;
    margin: -70px 0 0 0;
  }
  .games_info .about_games {
    margin: 0 1%;
    padding: 20px;
    width: 98%;
    border-radius: 0 0 30px 30px;
  }
  .about_games .game_title_icon .icon_game {
    width: 70px;
    margin: 0 10px 0 0;
  }
  .about_games .game_title_icon h5 {
    font-size: 18px;
  }
  .about_games .game_title_icon h3 {
    font-size: 24px;
    line-height: 24px;
  }
  .about_games ul.games_type1 li a {
    padding: 5px 11px;
    font-size: 11px;
  }
  .about_games p {
    font-size: 16px;
    width: 100%;
  }
  .about_games .store_links a {
    margin: 0 7px 0 0;
    padding: 10px 15px;
    font-size: 12px;
  }
  .about_games .store_links a img {
    margin: 3px 5px 0 0;
    width: 16px;
  }

  .games_info .games_icon_right {
    margin: 0px 1%;
    padding: 0 0 0 0px;
    width: 98%;
    text-align: center;
    background-color: #bef3ff;
    border-radius: 30px 30px 0 0;
    position: absolute;
    top: 0;
  }
  .games_info .games_icon_right img {
    width: 120px;
    margin: -70px 0 0 0;
  }
  .games_info .about_games_right {
    margin: 240px 1% 0 1%;
    padding: 20px;
    width: 98%;
    border-radius: 0 0 30px 30px;
  }
  .about_games_right .game_title_icon .icon_game {
    width: 70px;
    margin: 0 10px 0 0;
  }
  .about_games_right .game_title_icon h5 {
    font-size: 18px;
  }
  .about_games_right .game_title_icon h3 {
    font-size: 24px;
    line-height: 24px;
  }
  .about_games_right ul.games_type1 li a {
    padding: 5px 11px;
    font-size: 11px;
  }
  .about_games_right p {
    font-size: 16px;
    width: 100%;
  }
  .about_games_right .store_links a {
    margin: 0 7px 0 0;
    padding: 10px 15px;
    font-size: 12px;
  }
  .about_games_right .store_links a img {
    margin: 3px 5px 0 0;
    width: 16px;
  }
  .our_awesome_studios {
    padding: 80px 0 80px 0;
  }

  .our_favorite_games img.icon_our_favorite_games {
    width: 90px;
  }
  .our_awesome_studios img.icon_our_awesome_studios {
    width: 90px;
  }
  .our_awesome_studios h1 {
    font-size: 78px;
    margin: -25px 0 0 2%;
    line-height: 78px;
  }
  .our_awesome_studios h1 img.awesome_studios_title-icon {
    margin: -10px 0 0 10px;
    width: 80px;
  }
  .our_awesome_studios .content_area {
    width: 90%;
  }
  .our_awesome_studios .content_area p {
    font-size: 18px;
    margin: 0px 0 20px 0;
  }

  .sections .our_studios_logo {
    width: 340px;
    height: 330px;
  }
  .our_awesome_studios img.studios_icon_center {
    left: 34%;
    top: 240px;
  }
  ul.accordion-list li {
    padding: 40px 30px;
    border-radius: 40px 40px 0 0;
  }
  ul.accordion-list li.active h3:after {
    left: -15px;
  }
  ul.accordion-list li h3:after {
    left: -20px;
  }
  .answer .studios_info .store_links a {
    margin: 0 5px 15px 0;
    padding: 12px 21px;
    font-size: 12px;
  }
  .games_icons_row img {
    margin: 0 0 10px 10px;
    width: 64px;
  }
  .answer .studios_info p {
    margin: 20px 0 20px 0;
  }
  .answer .studios_info .store_links a img {
    margin: 0 5px 0 0;
  }
  /* gdhub Page */

  .banner_gd_hub h2 {
    font-size: 72px;
  }
  .banner_gd_hub h1 {
    font-size: 72px;
  }
  .gd_hub_empowering_studios_info {
    margin: -80px 0 0 0;
  }
  .gd_hub_empowering_studios_info h3 {
    font-size: 56px;
    letter-spacing: -4px;
    padding: 0 17% 0 0;
  }
  .gd_hub_empowering_studios_details {
    margin: 100px 0 0 0;
  }

  .gd_hub_info_area p {
    padding: 0 10%;
  }
  .gd_hub_info_area p.inner {
    padding: 0 10%;
  }
  .gd_hub_values_box:hover {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  .gd_hub_values_box {
    padding: 20px 20px 20px 20px;
    border-radius: 30px;
    min-height: auto;
  }
  .gd_hub_values_box .icon_number img {
    width: 60px;
  }
  .gd_hub_values_box h3 {
    font-size: 22px;
  }
  .gd_hub_info_area .gd_hub_values_box p {
    padding: 0 0;
    font-size: 17px;
  }
  .gd_hub_values_box_area h2.our_values {
    font-size: 32px;
    line-height: 36px;
    padding: 60px 0% 0 0;
  }
  .games_are_played h2 {
    font-size: 56px;
    line-height: 56px;
    padding: 0 0% 0 0;
  }
  .games_are_played h2 img.games_are_played_icon {
    right: -3%;
    top: 46px;
    width: 70px;
  }
  .games_are_played_info {
    padding: 200px 0 0 0;
  }

  /* IP Page */

  .banner_ip h2 {
    font-size: 72px;
  }
  .banner_ip h2 img.star_ip {
    margin: -7px 0 0 -5px;
  }
  .banner_ip h3 {
    font-size: 48px;
  }
  .banner_ip_top {
    background-position: 0 40px;
  }
  .banner_ip {
    background-position: right 270px;
    background-size: 160px;
    padding: 50px 0 50px 0;
  }
  .ip_banner {
    margin: 40px 0 0 0;
    padding: 0 0 40px 0;
    background-position: center 0;
    background-size: 100%;
  }
  .pixels_code h2 {
    font-size: 54px;
    padding: 0 10%;
    line-height: 60px;
    margin: 30px 0 0 0;
  }

  .craft_worlds h3 {
    font-size: 38px;
    letter-spacing: -2px;
  }
  .craft_worlds_img img.parallax-image {
    margin: 0px 5% 0 0;
  }
  .gameverse_monetize img.forge_your_gameverse_icon {
    position: absolute;
    right: 10px;
    width: 90px;
  }
  .gameverse_monetize_img img.parallax-image {
    margin: 70px 10% 0 0;
  }

  .gameverse_monetize img.level_up_icon {
    position: absolute;
    left: 10px;
    width: 90px;
  }
  .level_up_game_img img.parallax-image {
    margin: 70px 2% 0 0;
  }
  .gameverse_monetize h3 {
    font-size: 48px;
  }
  .monetize_game_img img.parallax-image {
    margin: 70px 2% 0 0;
  }

  .roadmap img.roadmap_icon {
    width: 90px;
    position: absolute;
    right: 10px;
  }
  .roadmap {
    padding: 50px 0 50px 0;
  }
  .roadmap h2 {
    font-size: 56px;
    padding: 0 0 0 0px;
    line-height: 60px;
  }
  .roadmap .roadmap_info {
    padding: 150px 0 0 0;
  }
  .roadmap_services {
    padding: 10px;
  }

  .roadmap_services .icon_number {
    font-size: 20px;
    width: 48px;
    height: 48px;
    line-height: 48px;
  }
  .roadmap_services h3 {
    margin: 20px 0 10px 0;
    font-size: 21px;
  }
  .roadmap_services h3 span {
    font-size: 17px;
  }
  .roadmap_services p {
    font-size: 17px;
  }
  .roadmap_services ul li {
    font-size: 17px;
    margin: 20px 0 10px 10px;
  }
  .trusted_partner p {
    padding: 0 5%;
  }
  .ip_content_image img.parallax-image {
    margin: 40px 8% 0 0;
  }

  /* Tools Page */

  .banner_tool h2 {
    font-size: 72px;
  }
  .banner_tool h2 img.tool_growing {
    margin: -6px 0 0 94px;
    width: 60px;
  }
  .banner_tool h2 span.star {
    top: -30px;
    margin: 0 0 0 -9px;
  }
  .brandanimationcolor .marquee--inner {
    width: 530%;
  }
  .brandanimationcolor span {
    font-size: 20px;
    line-height: 32px;
  }
  .brandanimation {
    transform: rotate(3deg);
  }
  .brandanimation span {
    font-size: 20px;
    line-height: 32px;
  }
  .brandanimation .marquee--inner {
    width: 580%;
  }
  .insights_impact h2 {
    font-size: 72px;
  }
  .insights_impact h2 img {
    width: 72px;
  }
  .insights_impact h3 {
    font-size: 31px;
  }
  .insights_impact p {
    font-size: 17px;
  }
  .applifier h2 {
    font-size: 56px;
  }
  #applifier p {
    font-size: 17px;
    line-height: 24px;
    margin: 0px 0 60px 0;
    padding: 0 5%;
  }
  .applifier_info h3 {
    font-size: 31px;
  }
  .applifier_info p {
    font-size: 17px;
    line-height: 24px;
  }
  .applifier img.applifier_left_img {
    max-width: 100%;
    margin: 50px 0 0 0;
  }
  .bidding_system img {
    position: absolute;
  }
  .bidding_system {
    padding: 30px;
  }
  .bidding_system p {
    width: 100%;
  }
  .advanced_analytics img {
    position: absolute;
    width: 35%;
  }
  .advanced_analytics p {
    width: 100%;
  }
  .advanced_analytics {
    padding: 30px;
  }
  .bidding_system:hover {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  .advanced_analytics:hover {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  .applifier_system_info:hover {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  .applifier_system_info {
    padding: 15px;
  }
  .applifier_system_info .icon_system_info img {
    width: 60px;
  }
  .applifier_system_info h3 {
    font-size: 20px;
    width: 100%;
  }
  .applifier_system_info p {
    font-size: 14px;
    line-height: 19px;
  }
  .go_beyond h3 {
    font-size: 36px;
  }
  .applifier .go_beyond h2 {
    font-size: 56px;
    letter-spacing: -3px;
  }
  .go_beyond p {
    font-size: 17px;
    line-height: 24px;
    padding: 0 10%;
  }
  .we_help {
    padding: 30px;
  }
  .we_help h3 {
    font-size: 32px;
    margin: 0;
    padding: 0px 0 0 0;
  }
  .we_help h4 {
    font-size: 48px;
    letter-spacing: -3px;
    margin: 0 0 0px 0;
    line-height: 60px;
  }
  .gdi_area {
    padding: 60px 0 0px 0;
  }
  .gdi_area h2 {
    font-size: 52px;
    letter-spacing: -3px;
    line-height: 56px;
  }
  .gdi_area h4 {
    font-size: 42px;
    letter-spacing: -2px;
    margin: 0px 0 10px 0;
    line-height: 56px;
  }
  .gdi_area p {
    font-size: 17px;
    line-height: 24px;
    margin: 0px 0 40px 0;
    padding: 0 10%;
  }
  .gdi_info h3 {
    font-size: 33px;
    letter-spacing: -2px;
    width: 100%;
  }
  .gdi_area .gdi_info p {
    font-size: 17px;
    line-height: 24px;
    margin: 0px 0 0px 0;
    padding: 0 0%;
  }
  .gdi_info img {
    width: 220px;
  }
  .gdi_area img.gdi_left_img {
    margin: 70px 0 0 0;
  }

  .monetization_mastermind:hover {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  .benchmark:hover {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  .gdi_system_info:hover {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  .monetization_mastermind {
    padding: 30px;
  }
  .benchmark {
    padding: 30px;
  }
  .monetization_mastermind img {
    max-width: 100%;
  }
  .monetization_mastermind h3 {
    margin: 0px 0 10px 0;
    width: 100%;
  }
  .gdi_area .monetization_mastermind p {
    font-size: 17px;
    line-height: 24px;
    margin: 0;
    padding: 0 0%;
    width: 100%;
  }
  .benchmark img {
    max-width: 90%;
  }
  .benchmark h3 {
    margin: 0px 0 10px 0;
    width: 100%;
  }
  .gdi_area .benchmark p {
    font-size: 17px;
    line-height: 24px;
    margin: 0;
    padding: 0 0%;
    width: 100%;
  }

  .gdi_system_info {
    padding: 15px;
  }
  .gdi_system_info .icon_system_info img {
    width: 60px;
  }
  .gdi_system_info h3 {
    font-size: 20px;
    width: 100%;
  }
  .gdi_system_info p {
    font-size: 14px;
    line-height: 19px;
    padding: 0;
  }

  .we_help_gdi {
    padding: 30px 0;
  }
  .we_help_gdi h3 {
    font-size: 28px;
    margin: 0;
    padding: 0px 0 0 0;
  }
  .we_help_gdi h4 {
    font-size: 48px;
    letter-spacing: -3px;
    margin: 0 0 0px 0;
    line-height: 60px;
  }
  .gdi_area .we_help_gdi p {
    margin: 0;
  }

  /* Pixls Page */

  .banner_programs_top img.banner_top_1 {
    width: 140px;
  }
  .banner_programs_top img.banner_top_logo {
    margin: 60px 0 0 10%;
    width: 400px;
  }
  .banner_programs_top img.banner_top_title {
    margin: -18px 0 0 13%;
    width: 550px;
  }
  .banner_programs_top img.banner_top_3 {
    margin: -108px 0 0 4%;
    width: 120px;
  }
  .banner_programs_top img.banner_top_4 {
    margin: -53px 0 0 43%;
    width: 100px;
  }
  .banner_programs_top img.banner_top_5 {
    margin: -126px 27% 0 0;
    width: 48px;
  }
  .banner_programs_top img.banner_top_2 {
    margin: -65px 0 0 0;
    width: 100px;
  }
  .just_play2 {
    margin: 40px 0 40px 0;
  }
  .right_text {
    top: 110px;
  }
  .social_icons_banner {
    top: 110px;
  }

  .banner_programs_row {
    margin: 30px 0 0 0;
  }
  .banner_programs_bottom .banner_programs_row,
  .banner_programs_bottom .banner_programs_row_center,
  .banner_programs_bottom .banner_programs_row_bottom {
    display: none;
  }
  .banner_programs_bottom img.banner_programs_bottom_img {
    width: 96%;
    margin: 0 2%;
    display: block;
  }
  .pixls_program_content_icon {
    padding: 0px 10%;
  }
  .pixls_program_content_icon .pixls_icon2 {
    left: 8%;
    width: 280px;
  }
  .pixls_program_content {
    padding: 60px 0px 70px 0;
  }
  .pixls_program_info p {
    font-size: 21px;
    line-height: 29px;
    margin: 20px 0 0 0;
  }
  .pixls_program_content img.pixls_program_img {
    margin: 90px 0 0 0;
  }

  .program_vision_area {
    padding: 60px 0px;
  }
  .program_vision_title h1 {
    font-size: 72px;
    line-height: 88px;
  }
  .program_vision_title h1 img {
    margin: -61px 0 0 166px;
    width: 30px;
  }
  .program_vision_info {
    padding: 0px 0px 0 30px;
  }
  .program_vision_info p {
    font-size: 21px;
    line-height: 29px;
    margin: 20px 0 0 0;
  }
  .program_vision_info p .program_vision_info_img_1 img {
    width: 100px;
    height: auto;
    top: 8px;
  }
  .program_vision_info p .program_vision_info_img_2 img {
    width: 170px;
    height: auto;
    top: 8px;
  }
  .program_vision_info p .program_vision_info_img_3 img {
    width: 180px;
    height: auto;
    top: 8px;
  }
  .program_vision_info p .program_vision_info_img_4 img {
    width: 178px;
    height: auto;
    top: 17px;
    left: -190px;
  }
  .program_vision_info p .program_vision_info_img_5 img {
    width: 140px;
    height: auto;
  }
  .program_vision_info p .program_vision_info_img_6 img {
    width: 70px;
    height: auto;
  }
  .program_plan {
    margin: 30px 1% 0px 1%;
    padding: 60px 0 60px 0;
  }

  .program_plan_title h1 {
    font-size: 66px;
    line-height: 74px;
  }
  .program_plan_title h1 img.program_plan_title_icon {
    width: 32px;
    top: -3px;
    left: -46px;
  }
  .program_plan_title h1 img.program_plan_title_icon2 {
    width: 60px;
    left: -32px;
    top: 34px;
  }
  .program_plan_title img.program_plan_img {
    margin: -28px 0 0 33px;
    width: 220px;
  }

  .program_plan_info .program_plan_details h3 {
    font-size: 13px;
    margin: 0 0% 15px 0%;
    padding: 10px 10px;
    border-radius: 30px;
  }
  .program_plan_info .program_plan_details p {
    font-size: 13px;
    margin: 0 0 10px 0;
  }
  .program_plan_info img.star_participation_criteria {
    width: 40px;
    bottom: 0;
    right: 0;
  }
  .our_partners {
    margin: 30px 1% 0 1%;
    padding: 40px 0px;
  }
  .our_partners h1 {
    font-size: 56px;
    margin: 0 0 30px 0;
  }
  .our_partners h1 img.our_partners_icon {
    width: 50px;
    top: 6px;
    position: relative;
  }
  .achievements {
    padding: 80px 0px 0 0;
  }
  .achievements h2 {
    font-size: 72px;
    margin: 20px 0 0px 0;
    line-height: 72px;
  }
  .achievements h2 span.margin_left {
    margin-left: 30px !important;
  }
  .achievements h2 span.margin_left2 {
    margin-left: 80px !important;
  }
  .achievements img.achievements_star {
    margin: -30px 0 0 30px;
    width: 80px;
  }
  .future_plans img.future_plans_img {
    max-width: 660px;
  }
  .future_plans p {
    margin: 40px 0 40px 0;
    padding: 0 9%;
  }

  .future_plans_info img.fadein_bottom_23 {
    width: 70%;
  }
  .future_plans_info img.future_plans_icon_right {
    top: -50px;
    width: 100px;
  }
  .future_plans_info img.future_plans_icon_left {
    bottom: 15px;
    width: 110px;
  }
  .achievements_img {
    margin: 70px 0 0px 0;
  }
  .contact_us_programs {
    margin: 0;
    padding: 20px 0 20px 0;
  }
  .contact_us_programs h1 {
    font-size: 84px;
    line-height: 66px;
  }
  .flip {
    width: 80%;
    height: 84px;
  }
  .flip span {
    font-size: 84px;
    line-height: 66px;
  }
  .contact_us_programs h2 {
    font-size: 84px;
    line-height: 66px;
  }
  .contact_us_programs h2 img {
    width: 52px;
    margin: 20px 0 0 0;
  }
  .contact_us_programs h3 {
    font-size: 84px;
    line-height: 66px;
  }
  .contact_us_programs h1 span.righttext {
    padding: 5px 0 0 0;
  }
  .just_make_games {
    margin: -120px 0 0 0;
  }
  .footer_area_programs .address {
    margin: 20px 0 30px 0;
    padding: 0 20% 0 15%;
  }

  /* Hackathon Page */
  .together_towards_tomorrow {
    padding: 0px 0 50px 0;
  }
  .together_towards_tomorrow_top {
    padding: 20px 0 0 0;
  }
  .together_towards_tomorrow_top img.be_bold_make_history {
    width: 200px;
  }
  .together_towards_tomorrow_top img.together_towards_tomorrow_icon_right_top {
    margin: 10px 4px 0 0;
  }
  .together_towards_tomorrow_top img.together_towards_tomorrow2 {
    margin: -40px 0 0 15%;
    width: 520px;
  }
  .together_towards_tomorrow_top
    img.together_towards_tomorrow_icon_right_bottom {
    margin: 140px 0 0 0;
    width: 90px;
  }
  .just_play_together_towards_tomorrow {
    margin: 40px 0 70px 0;
  }
  .together_towards_tomorrow h2 {
    font-size: 36px;
  }
  .together_towards_tomorrow h2 img {
    left: 5px;
    width: 80px;
  }
  .together_towards_tomorrow p {
    font-size: 27px;
    width: 90%;
  }
  .together_towards_tomorrow img.why_hackathon_right_icon_bg {
    width: 200px;
  }
  .together_towards_tomorrow img.why_hackathon_right_icon {
    right: 70px;
    top: -18px;
    width: 240px;
  }
  .together_towards_tomorrow_border_bottom .marquee--inner {
    width: 350%;
  }
  .hackathon_program h2 {
    font-size: 27px;
  }
  .hackathon_program h2 img {
    top: 2px;
    left: 10px;
    width: 140px;
  }
  .hackathon_program p {
    font-size: 20px;
  }
  .phase_area h3 {
    font-size: 21px;
    padding: 24px 20px;
    border-radius: 30px;
  }

  .phase_area_days {
    margin: 0 2%;
    padding: 25px 15px;
    width: 96%;
    top: 60px;
  }
  .phase_area_days h3 {
    padding: 15px 25px;
  }

  .hackathon_program .phase_area_days p {
    font-size: 17px;
  }
  .phase_area_days2 {
    margin: 75px 2% 0 2%;
    padding: 25px 15px;
    width: 96%;
  }
  .phase_area_days2 h3 {
    font-size: 18px;
    padding: 15px 20px;
  }
  .hackathon_program .phase_area_days2 p {
    font-size: 17px;
  }
  .hackathon_program {
    padding: 80px 0 80px 0;
  }
  .together_towards_tomorrow_border_bottom img {
    max-width: 160%;
    margin: 0 0 0 -30%;
  }

  .Journey_1 img.one {
    width: 33px;
  }
  .Journey_1 h3 {
    font-size: 19px;
    padding: 24px 0 24px 0;
  }
  .Journey_1 .Journey_1_info {
    border-top: 1px solid #0e7a62;
    border-bottom: 1px solid #0e7a62;
    border-right: 1px solid #0e7a62;
  }
  .Journey_1 .Journey-Icon-1 {
    width: 72px;
    padding: 5px;
  }

  .Journey_2 img.two {
    width: 51px;
  }
  .Journey_2 .Journey_2_info {
    border-top: 1px solid #0e7a62;
    border-bottom: 1px solid #0e7a62;
    border-right: 1px solid #0e7a62;
  }
  .Journey_2 h3 {
    font-size: 19px;
    padding: 11px 0 12px 0;
  }
  .Journey_2 .Journey-Icon-2 {
    width: 72px;
    padding: 5px;
  }

  .Journey_3 img.three {
    width: 47px;
  }
  .Journey_3 .Journey_3_info {
    border-top: 1px solid #0e7a62;
    border-bottom: 1px solid #0e7a62;
    border-right: 1px solid #0e7a62;
  }
  .Journey_3 h3 {
    font-size: 19px;
    padding: 11px 0 12px 0;
  }
  .Journey_3 .Journey-Icon-3 {
    width: 72px;
    padding: 5px;
  }

  .Journey_arrow {
    padding: 29px 1%;
  }
  .Journey_arrow img {
    width: 38px;
  }

  .Journey_arrow2 {
    padding: 10px 1%;
  }
  .Journey_arrow2 img {
    width: 38px;
  }

  .Journey_4 img.four {
    width: 57px;
  }
  .Journey_4 .Journey_4_info {
    border-top: 1px solid #0e7a62;
    border-bottom: 1px solid #0e7a62;
    border-right: 1px solid #0e7a62;
  }
  .Journey_4_info h3 {
    font-size: 19px;
    padding: 11px 0 11px 0;
  }
  .Journey_4 .Journey-Icon-4 {
    width: 72px;
    padding: 5px;
  }

  .Journey_5 img.five {
    width: 60px;
  }
  .Journey_5 .Journey_5_info {
    border-top: 1px solid #0e7a62;
    border-bottom: 1px solid #0e7a62;
    border-right: 1px solid #0e7a62;
  }
  .Journey_5 h3 {
    font-size: 19px;
    padding: 24px 0 12px 0;
  }
  .Journey_5 .Journey-Icon-5 {
    width: 72px;
    padding: 5px;
  }
  .journey_of_game_district {
    padding: 60px 0 60px 0;
  }
  .journey_of_game_district h2 span {
    font-size: 48px;
    margin: 0 0;
  }

  .game_scaled_globally:hover {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  .game_scaled_globally2 .eight_games:hover {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  .game_scaled_globally2 .eight_games_icon:hover {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  .retention_marked:hover {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  .million_game_downloads:hover {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  .prototypes:hover {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  .gap_img:hover {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  .hit_games:hover {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .achievements_programs {
    padding: 60px 0 60px 0;
  }
  .achievements_programs h2 {
    font-size: 36px;
  }
  .achievements_programs h2 img {
    margin: 17px 0px 0 0;
    max-width: 450px;
  }

  .game_scaled_globally {
    padding: 10px;
  }
  .game_scaled_globally h3 {
    font-size: 72px;
    margin: 0 0 10px 0;
    line-height: 66px;
  }
  .game_scaled_globally h3 img {
    width: 60px;
  }
  .game_scaled_globally h4 {
    font-size: 19px;
  }

  .game_scaled_globally2 .eight_games {
    padding: 0 10px;
    border-radius: 30px;
  }
  .game_scaled_globally2 .eight_games h3 {
    font-size: 42px;
    line-height: 64px;
  }
  .game_scaled_globally2 .eight_games p {
    font-size: 17px;
    padding: 21px 0 0 0;
  }
  .game_scaled_globally2 .eight_games_icon {
    width: 48px;
    margin: 9px 0 0 0;
  }

  .retention_marked {
    padding: 10px 15px 15px 15px;
  }
  .retention_marked h3 {
    font-size: 48px;
    padding: 10px 0;
    line-height: 48px;
  }

  .million_game_downloads h3 {
    font-size: 84px;
    padding: 30px 0 0 0;
    line-height: 88px;
  }
  .million_game_downloads p {
    font-size: 21px;
  }
  .million_game_downloads {
    min-height: 400px;
  }
  .prototypes {
    padding: 15px;
    width: 72%;
  }
  .prototypes h3 {
    font-size: 72px;
    line-height: 96px;
    padding: 0 0;
  }
  .prototypes p {
    font-size: 17px;
  }
  .prototypes p img {
    width: 30px;
    margin: 5px 0 0 0;
  }
  .prototypes_img {
    margin: 54px 0 0 0;
    width: 25%;
    float: right;
  }
  .hit_games h3 {
    font-size: 72px;
    padding: 15px 0;
    line-height: 78px;
    width: 100%;
  }
  .hit_games p {
    font-size: 21px;
    width: 72%;
  }
  .hit_games img.hit_games_icons {
    width: 28%;
    margin: 3px 0 0 0;
  }
  .successful_games {
    padding: 60px 0 60px 0;
  }
  .successful_games h2 img {
    width: 200px;
  }
  .successful_games h2 span {
    font-size: 48px;
    margin: 0 20px;
  }
  .successful_games p {
    margin: 0px 0 40px 0;
    padding: 0 5%;
  }
  .successful_games_info .game_info h5 {
    font-size: 17px;
  }

  .gallery_gd_area {
    width: 100%;
  }

  .gallery_gd_row img.gallery_1 {
    height: auto;
    width: 59%;
  }
  .gallery_gd_row img.gallery_2 {
    height: auto;
    width: 35%;
  }
  .gallery_gd_row img.gallery_3 {
    height: auto;
    width: 35%;
  }
  .gallery_gd_row img.gallery_4 {
    height: auto;
    width: 59%;
  }
  .gallery_gd_row2 img {
    height: auto;
  }
}

/*
@media screen and (min-width: 320px) and (max-width:480px) {
    
iPhone 6-8 Plump landscape · width: 736px
iPhone eXpensive landscape · width: 734px

iPhone 6-8 portrait · width: 375px
iPhone 6-8 Plump portrait · width: 414px
iPhone eXpensive portrait · width: 375px
Android (Pixel 2) portrait · width: 412px
width: 480px

Android (Pixel 2) landscape · width: 684px
iPhone 6-8 landscape · width: 667px
iPad portrait · width: 768px*/
.prev_next_button {
  float: none; 
  margin: 0 auto;
  padding: 0;
  width: 260px;
  text-align: center;
  position: relative;
  z-index: 99;
  bottom: -30px;
  left: auto;
  right: auto;
}
.prev_next_button .swiper-button-prev {
  background-image: url("../images/prev.png");
  background-repeat: no-repeat;
  float: none;
  margin: 0 10px;
  padding: 0;
  width: 84px;
  height: 84px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 110px;
  background-position: center center;
}
.prev_next_button .swiper-button-next {
  background-image: url("../images/next.png");
  background-repeat: no-repeat;
  float: none;
  margin: 0 10px;
  padding: 0;
  width: 84px;
  height: 84px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 110px;
  background-position: center center;
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: '';
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: '';
}